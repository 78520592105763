@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap"rel="stylesheet");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Figtree", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Figtree", monospace;
}
#dash::-webkit-scrollbar {
  width: 0;
}

/* Sign In page ========================================================= */

.rt-sticky {
  z-index: 999 !important;
}

.topbar-right .header-right-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ant-carousel .slick-dots li button {
  background: #dc3545;
  opacity: 0.4;
}

.ant-carousel .slick-dots li.slick-active button {
  opacity: 1;
  background: #dc3545;
}
.topbar-right .header-right-button .header-btn.s-up {
  background-image: linear-gradient(
    30deg,
    #0c333b,
    #000000,
    #0c333b
  ) !important;
}

.topbar-right .header-right-button .header-btn:hover {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#da251e),
    to(#ff413a)
  ) !important;
  background-image: linear-gradient(#da251e, #ff413a) !important;
  opacity: 85% !important;
}

.header-menu .lgo-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 162px;
  height: 57px;
}

.header-menu.rt-sticky .lgo-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 168px;
  height: 59px;
}

.lgo-img-t {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 180px;
  height: 64px;
}

.topbar-right .header-right-button .header-btn {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#da251e),
    to(#ff413a)
  ) !important;
  background-image: linear-gradient(#da251e, #ff413a) !important;
  background-color: transparent !important;
}

/* #preloader {
  background-image: url(../img/my-img/lgo-red.png) !important;
} */

.financo-activities-box1:hover .item-button .item-btn:after {
  background-color: #da251e !important;
}

.financo-activities-box1 .item-button .item-btn:after {
  background-color: #0c333b !important;
}

.slider-area .slider-content .item-btn li a:after {
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#da251e),
    to(#ff413a)
  ) !important;
  background-image: linear-gradient(#da251e, #ff413a) !important;
}

.grd-info {
  display: -ms-grid;
  display: grid;
  grid-gap: 1px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.grd-info.grd-info-tw {
  -ms-grid-columns: 1fr 2fr;
  grid-template-columns: 1fr 2fr;
}

.grd-info.grd-info-th {
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

.consulting-service3 .media:hover .media-body .item-button .item-btn {
  background-color: #da251e !important;
  border-color: #da251e !important;
}

.progress-bar-box1 .progress-box .circle-progress {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.progress-bar-box1 .progress-box .circle-progress span {
  -webkit-transform: translateX(-27px) !important;
  transform: translateX(-27px) !important;
  top: 33% !important;
}

.progress-bar-box1 .progress-box .circle-progress label {
  bottom: 32px !important;
  left: 41% !important;
  margin-bottom: 10px !important;
}

.progress-bar-box1 {
  text-align: center;
}

.rgt-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 800px;
  height: 550px;
}

.banner-box1 {
  background-color: #da251e !important;
}

.banner-box1:after {
  background: rgba(218, 37, 30, 0.2) !important;
}

.portfolio-box1 .item-content .item-button .item-btn:after {
  background-color: #da251e !important;
}

.portfolio-box1 .item-content .heading-subtitle:after {
  background-color: #da251e !important;
}

.portfolio-box1 .item-content .heading-subtitle:before {
  background-color: #ed9895;
}

.portfolio-box1 .item-img:before {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#0c333b),
    to(transparent)
  ) !important;
  background-image: linear-gradient(to top, #0c333b, transparent) !important;
}

.company-profit-box1 .item-button .item-btn:after {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#da251e),
    to(#f73831)
  ) !important;
  background: linear-gradient(to right, #da251e, #f73831) !important;
}

.slider-area .nivo-directionNav a.nivo-prevNav {
  left: 3% !important;
}

.slider-area .nivo-directionNav a.nivo-nextNav {
  left: 3% !important;
}

.build-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 80%;
  height: 470px;
  margin: auto;
}

.about-box2 {
  padding-left: 0 !important;
}

.banner-box1 .item-img .btm-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 580px;
  height: 200px;
  -webkit-clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 102%);
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 102%);
}

.blg-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 380px;
  height: 433px;
}

.offcanvas-logo .lgo-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 162px;
  height: 57px;
}

.logo-mobile .lgo-img-t {
  width: 125px;
  height: 44px;
}

.abt-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 500px;
  height: 600px;
  border-radius: 0px 50px 0px 50px;
  margin-top: 10px;
}

.comp-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 235px;
  height: 250px;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.company-value-box .media:hover .item-img .comp-img {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

section.login.signup .lft-pan {
  border-radius: 0 8px 8px 0 !important;
}

section.login.signup .rgt-pan {
  border-radius: 8px 0 0 8px !important;
}

section.login {
  padding: 80px 0 50px;
}

section.login button {
  border-radius: 20px;
  border: 1px solid #da251e;
  background-color: #da251e;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  width: 100%;
  text-transform: uppercase;
  -webkit-transition: -webkit-transform 80ms ease-in;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
}

section.login .sign-grd {
  display: -ms-grid !important;
  display: grid !important;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
}

section.login .sign-grd .lft-pan {
  border-radius: 8px 0 0 8px;
  padding: 50px 0;
  background-color: #fbfbfb;
}

section.login .sign-grd .lft-pan button {
  border-radius: 100px;
  padding: 10px;
  font-size: 14px;
  margin-top: 20px;
}

section.login .sign-grd .lft-pan button:hover {
  background-color: #0c333b;
  border-color: #ffffff;
}

section.login .sign-grd .rgt-pan {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-image: url(../img/my-img/signin.jpg); */
  border-radius: 0 8px 8px 0;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 30px;
  z-index: 1;
  overflow: hidden;
  position: relative;
  color: #ffffff;
}

section.login .sign-grd .rgt-pan h1 {
  color: #ffffff;
}

section.login .sign-grd .rgt-pan a.ghost {
  background-color: transparent;
  border-color: #ffffff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: auto;
  padding: 5px 30px;
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 100px;
  text-decoration: none;
}

section.login .sign-grd .rgt-pan a.ghost:hover {
  background-color: #ffffff;
  color: #da251e;
  border-color: #da251e;
}

section.login .sign-grd .rgt-pan::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#da251e),
    to(#ff416c)
  );
  background: linear-gradient(to right, #da251e, #ff416c);
}

section.login button:active {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

section.login button:focus {
  outline: none;
}

section.login .social-container a {
  color: #0c333b;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

section.login .social-container a:hover {
  background-color: #da251e;
  color: #ffffff;
  border-color: #da251e;
}

section.login .form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

section.login form:focus {
  outline: none !important;
  border: 1px solid #0c333b !important;
}

section.login .overlay-panel h1 {
  color: #ffffff;
  font-weight: bold;
}

section.login input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}

section.login a.forgot {
  margin: 20px 0;
  color: #da251e;
  text-decoration: none;
}

section.login .container {
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative;
  overflow: hidden;
  width: 1000px;
  max-width: 100%;
  min-height: 600px;
}

section.login .form-container {
  position: absolute;
  top: 0;
  height: 100%;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

section.login .sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

section.login .container.right-panel-active .sign-in-container {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

section.login .sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

section.login .container.right-panel-active .sign-up-container {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  -webkit-animation: show 0.6s;
  animation: show 0.6s;
}

@-webkit-keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

section.login .overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  z-index: 1;
}

section.login .container.right-panel-active .overlay-container {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

section.login .overlay {
  background: #da251e;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

section.login .container.right-panel-active .overlay {
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

section.login .overlay-panel {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

section.login .overlay-left {
  -webkit-transform: translateX(-20%);
  transform: translateX(-20%);
}

section.login .container.right-panel-active .overlay-left {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

section.login .overlay-right {
  right: 0;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

section.login .container.right-panel-active .overlay-right {
  -webkit-transform: translateX(20%);
  transform: translateX(20%);
}

section.login .social-container {
  margin: 20px 0;
}

section.login .social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

@media only screen and (max-width: 575px) {
  section.login.signup .lft-pan {
    border-radius: 0 0 8px 8px !important;
  }

  section.login.signup .rgt-pan {
    border-radius: 8px 8px 0 0 !important;
  }

  section.login .sign-grd {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  section.login .sign-grd .lft-pan {
    border-radius: 8px 8px 0 0;
  }

  section.login .sign-grd .rgt-pan {
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 0 0 8px 8px;
  }

  .comp-img {
    margin: auto;
  }

  .abt-img {
    width: 390px;
    height: 280px;
  }

  .slider-area .nivo-directionNav a.nivo-prevNav,
  .slider-area .nivo-directionNav a.nivo-nextNav {
    width: 25px !important;
    height: 25px !important;
    left: auto !important;
    right: 3% !important;
  }

  .slider-area .nivo-directionNav a.nivo-nextNav {
    top: 50% !important;
  }

  .grd-info {
    -ms-grid-columns: 1fr !important;
    grid-template-columns: 1fr !important;
  }

  .grd-info .media {
    margin-bottom: 3px !important;
  }

  .grd-info.grd-info-tw {
    padding-left: 18px;
  }

  .banner-box1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .banner-box1 .item-img .btm-img {
    width: 388px;
    -webkit-clip-path: none;
    clip-path: none;
  }

  .rgt-img {
    width: 430px;
    height: 430px;
  }

  .blg-img {
    width: 100%;
  }
}

html {
  height: 100%;
  width: 100%;
}

body {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  height: 100%;
  line-height: 28px;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  color: #444444;
  background-color: #ffffff;
}

p {
  margin: 0 0 20px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-family: "Figtree", sans-serif;
  margin: 0 0 20px 0;
  color: #000000;
}

h1,
h2 {
  line-height: 40px;
}

h3,
h4,
h5,
h6 {
  line-height: 30px;
}

h1 {
  font-size: 46px;
  line-height: 50px;
}

h2 {
  font-size: 36px;
  line-height: 44px;
}

h3 {
  font-size: 28px;
  line-height: 36px;
}

h4 {
  font-size: 22px;
  line-height: 32px;
}

h5 {
  font-size: 18px;
  line-height: 28px;
}

h6 {
  font-size: 14px;
  line-height: 24px;
}

/*=======================================================================
2. Bootstrap Overwrite
=========================================================================*/
/*------------------- 2.1 Grid -------------------*/
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.row.gutters-1 {
  padding-left: 14.5px;
  padding-right: 14.5px;
}

.row.gutters-1 > [class^="col-"] {
  padding-left: 0.5px;
  padding-right: 0.5px;
}

.row.gutters-2 {
  padding-left: 14px;
  padding-right: 14px;
}

.row.gutters-2 > [class^="col-"] {
  padding-left: 1px;
  padding-right: 1px;
}

.row.gutters-3 {
  padding-left: 13.5px;
  padding-right: 13.5px;
}

.row.gutters-3 > [class^="col-"] {
  padding-left: 1.5px;
  padding-right: 1.5px;
}

.row.gutters-4 {
  padding-left: 13px;
  padding-right: 13px;
}

.row.gutters-4 > [class^="col-"] {
  padding-left: 2px;
  padding-right: 2px;
}

.row.gutters-5 {
  padding-left: 12.5px;
  padding-right: 12.5px;
}

.row.gutters-5 > [class^="col-"] {
  padding-left: 2.5px;
  padding-right: 2.5px;
}

.row.gutters-6 {
  padding-left: 12px;
  padding-right: 12px;
}

.row.gutters-6 > [class^="col-"] {
  padding-left: 3px;
  padding-right: 3px;
}

.row.gutters-7 {
  padding-left: 11.5px;
  padding-right: 11.5px;
}

.row.gutters-7 > [class^="col-"] {
  padding-left: 3.5px;
  padding-right: 3.5px;
}

.row.gutters-8 {
  padding-left: 11px;
  padding-right: 11px;
}

.row.gutters-8 > [class^="col-"] {
  padding-left: 4px;
  padding-right: 4px;
}

.row.gutters-9 {
  padding-left: 10.5px;
  padding-right: 10.5px;
}

.row.gutters-9 > [class^="col-"] {
  padding-left: 4.5px;
  padding-right: 4.5px;
}

.row.gutters-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.row.gutters-10 > [class^="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.row.gutters-11 {
  padding-left: 9.5px;
  padding-right: 9.5px;
}

.row.gutters-11 > [class^="col-"] {
  padding-left: 5.5px;
  padding-right: 5.5px;
}

.row.gutters-12 {
  padding-left: 9px;
  padding-right: 9px;
}

.row.gutters-12 > [class^="col-"] {
  padding-left: 6px;
  padding-right: 6px;
}

.row.gutters-13 {
  padding-left: 8.5px;
  padding-right: 8.5px;
}

.row.gutters-13 > [class^="col-"] {
  padding-left: 6.5px;
  padding-right: 6.5px;
}

.row.gutters-14 {
  padding-left: 8px;
  padding-right: 8px;
}

.row.gutters-14 > [class^="col-"] {
  padding-left: 7px;
  padding-right: 7px;
}

.row.gutters-15 {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.row.gutters-15 > [class^="col-"] {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.row.gutters-20 {
  padding-left: 5px;
  padding-right: 5px;
}

.row.gutters-20 > [class^="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 1199px) {
  .row.gutters-40 {
    margin-left: -20px;
    margin-right: -20px;
  }

  .row.gutters-40 > [class^="col-"] {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 1199px) {
  .row.gutters-50 {
    margin-left: -25px;
    margin-right: -25px;
  }

  .row.gutters-50 > [class^="col-"] {
    padding-left: 25px;
    padding-right: 25px;
  }
}

/*------------------- 2.2 Media Object -------------------*/
.media-body.mg-right {
  margin-left: 0;
  margin-right: 48px;
}

.media-body.space-xl {
  margin-left: 50px;
}

.media-body.space-lg {
  margin-left: 40px;
}

.media-body.space-md {
  margin-left: 20px;
}

.media-body.space-sm {
  margin-left: 15px;
}

.media-body.space-xs {
  margin-left: 10px;
}

.media-body.media-body-box {
  padding: 20px 20px 20px 48px;
  margin-left: 0;
}

.media-body.media-body-box p {
  margin-bottom: 0;
}

@media only screen and (max-width: 1199px) {
  .media-none--lg {
    display: block;
  }

  .media-none--lg > div {
    width: 100%;
  }

  .media-none--lg img {
    width: 100%;
  }

  .media-none--lg img.media-img-auto {
    width: auto;
  }

  .media-none--lg .media-body,
  .media-none--lg .media-body.space-xl,
  .media-none--lg .media-body.space-lg,
  .media-none--lg .media-body.space-md,
  .media-none--lg .media-body.space-sm,
  .media-none--lg .media-body.space-xs {
    margin-top: 20px;
    margin-left: 0;
  }

  .media-none--lg .media-body.media-body-box {
    margin-top: 0;
    margin-left: 0;
    padding: 48px;
  }
}

@media only screen and (max-width: 991px) {
  .media-none--md {
    display: block;
  }

  .media-none--md > div {
    width: 100%;
  }

  .media-none--md img {
    width: 100%;
  }

  .media-none--md img.media-img-auto {
    width: auto;
  }

  .media-none--md .media-body,
  .media-none--md .media-body.space-xl,
  .media-none--md .media-body.space-lg,
  .media-none--md .media-body.space-md,
  .media-none--md .media-body.space-sm,
  .media-none--md .media-body.space-xs {
    margin-top: 20px;
    margin-left: 0;
  }

  .media-none--md .media-body.media-body-box {
    margin-top: 0;
    margin-left: 0;
    padding: 48px;
  }
}

@media only screen and (max-width: 767px) {
  .media-none--sm {
    display: block;
  }

  .media-none--sm > div {
    width: 100%;
  }

  .media-none--sm img {
    width: 100%;
  }

  .media-none--sm img.media-img-auto {
    width: auto;
  }

  .media-none--sm .media-body,
  .media-none--sm .media-body.space-xl,
  .media-none--sm .media-body.space-lg,
  .media-none--sm .media-body.space-md,
  .media-none--sm .media-body.space-sm,
  .media-none--sm .media-body.space-xs {
    margin-top: 20px;
    margin-left: 0;
  }

  .media-none--sm .media-body.media-body-box {
    margin-top: 0;
    margin-left: 0;
    padding: 48px;
  }
}

@media only screen and (max-width: 575px) {
  .media-none--xs {
    display: block;
  }

  .media-none--xs > div {
    width: 100%;
  }

  .media-none--xs img {
    width: 100%;
  }

  .media-none--xs img.media-img-auto {
    width: auto;
  }

  .media-none--xs .media-body,
  .media-none--xs .media-body.space-xl,
  .media-none--xs .media-body.space-lg,
  .media-none--xs .media-body.space-md,
  .media-none--xs .media-body.space-sm,
  .media-none--xs .media-body.space-xs {
    margin-top: 20px;
    margin-left: 0;
  }

  .media-none--xs .media-body.media-body-box {
    margin-top: 0;
    margin-left: 0;
    padding: 48px;
  }
}

@media only screen and (max-width: 479px) {
  .media-none-mb {
    display: block;
  }

  .media-none-mb > div {
    width: 100%;
  }

  .media-none-mb img {
    width: 100%;
  }

  .media-none-mb img.media-img-auto {
    width: auto;
  }

  .media-none-mb .media-body,
  .media-none-mb .media-body.space-xl,
  .media-none-mb .media-body.space-lg,
  .media-none-mb .media-body.space-md,
  .media-none-mb .media-body.space-sm,
  .media-none-mb .media-body.space-xs {
    margin-top: 20px;
    margin-left: 0;
  }

  .media-none-mb .media-body.media-body-box {
    margin-top: 0;
    margin-left: 0;
    padding: 48px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .media-none-lg {
    display: block;
  }

  .media-none-lg > div {
    width: 100%;
  }

  .media-none-lg img {
    width: 100%;
  }

  .media-none-lg img.media-img-auto {
    width: auto;
  }

  .media-none-lg .media-body,
  .media-none-lg .media-body.space-xl,
  .media-none-lg .media-body.space-lg,
  .media-none-lg .media-body.space-md,
  .media-none-lg .media-body.space-sm,
  .media-none-lg .media-body.space-xs {
    margin-top: 20px;
    margin-left: 0;
  }

  .media-none-lg .media-body.media-body-box {
    margin-top: 0;
    margin-left: 0;
    padding: 48px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .media-none-md {
    display: block;
  }

  .media-none-md > div {
    width: 100%;
  }

  .media-none-md img {
    width: 100%;
  }

  .media-none-md img.media-img-auto {
    width: auto;
  }

  .media-none-md .media-body,
  .media-none-md .media-body.space-xl,
  .media-none-md .media-body.space-lg,
  .media-none-md .media-body.space-md,
  .media-none-md .media-body.space-sm,
  .media-none-md .media-body.space-xs {
    margin-top: 20px;
    margin-left: 0;
  }

  .media-none-md .media-body.media-body-box {
    margin-top: 0;
    margin-left: 0;
    padding: 48px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .media-none-sm {
    display: block;
  }

  .media-none-sm > div {
    width: 100%;
  }

  .media-none-sm img {
    width: 100%;
  }

  .media-none-sm img.media-img-auto {
    width: auto;
  }

  .media-none-sm .media-body,
  .media-none-sm .media-body.space-xl,
  .media-none-sm .media-body.space-lg,
  .media-none-sm .media-body.space-md,
  .media-none-sm .media-body.space-sm,
  .media-none-sm .media-body.space-xs {
    margin-top: 20px;
    margin-left: 0;
  }

  .media-none-sm .media-body.media-body-box {
    margin-top: 0;
    margin-left: 0;
    padding: 48px;
  }
}

@media (min-width: 480px) and (max-width: 575px) {
  .media-none-xs {
    display: block;
  }

  .media-none-xs > div {
    width: 100%;
  }

  .media-none-xs img {
    width: 100%;
  }

  .media-none-xs img.media-img-auto {
    width: auto;
  }

  .media-none-xs .media-body,
  .media-none-xs .media-body.space-xl,
  .media-none-xs .media-body.space-lg,
  .media-none-xs .media-body.space-md,
  .media-none-xs .media-body.space-sm,
  .media-none-xs .media-body.space-xs {
    margin-top: 20px;
    margin-left: 0;
  }

  .media-none-xs .media-body.media-body-box {
    margin-top: 0;
    margin-left: 0;
    padding: 48px;
  }
}

/*=======================================================================
3.Vendor Custom Style
=========================================================================*/
/*------------------- 3.1 Nivo Slider -------------------*/
.slider-area {
  position: relative;
}

.slider-area .slider-bg-img1 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 6;
}

@media only screen and (max-width: 1439px) {
  .slider-area .slider-bg-img1 {
    display: none;
  }
}

.slider-area .slider-bg-img2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;
}

@media only screen and (max-width: 1439px) {
  .slider-area .slider-bg-img2 {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .s-tb-c {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .s-tb-c {
    padding-top: 20px !important;
  }
}

@media only screen and (max-width: 575px) {
  .s-tb-c {
    padding-top: 10px !important;
  }
}

.slider-area .nivo-directionNav a {
  z-index: 6;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (max-width: 1440px) {
  .slider-area .nivo-directionNav a {
    top: 42%;
  }
}

@media only screen and (max-width: 1199px) {
  .slider-area .nivo-directionNav a {
    top: 40%;
  }
}

.slider-area .nivo-directionNav a.nivo-prevNav {
  top: 50%;
  left: 13%;
  width: 64px;
  height: 64px;
  box-shadow: 0 11px 24px 0 rgba(126, 126, 126, 0.16);
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  background-color: white;
  font-size: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 1440px) {
  .slider-area .nivo-directionNav a.nivo-prevNav {
    left: 90%;
    top: 40%;
  }
}

@media only screen and (max-width: 1199px) {
  .slider-area .nivo-directionNav a.nivo-prevNav {
    left: 90%;
    top: 58%;
  }
}

@media only screen and (max-width: 991px) {
  .slider-area .nivo-directionNav a.nivo-prevNav {
    left: 90%;
    top: 67%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-area .nivo-directionNav a.nivo-prevNav {
    left: 90%;
  }
}

@media only screen and (max-width: 479px) {
  .slider-area .nivo-directionNav a.nivo-prevNav {
    left: 85%;
    top: 70%;
  }
}

@media only screen and (max-width: 320px) {
  .slider-area .nivo-directionNav a.nivo-prevNav {
    left: 85%;
    top: 73%;
  }
}

.slider-area .nivo-directionNav a.nivo-prevNav:before {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9;
  left: 0;
  right: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

@media only screen and (max-width: 1199px) {
  .slider-area .nivo-directionNav a.nivo-prevNav:before {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .slider-area .nivo-directionNav a.nivo-prevNav {
    width: 60px;
    height: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .slider-area .nivo-directionNav a.nivo-prevNav {
    width: 55px;
    height: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-area .nivo-directionNav a.nivo-prevNav {
    width: 50px;
    height: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-area .nivo-directionNav a.nivo-prevNav {
    width: 45px;
    height: 45px;
  }
}

.slider-area .nivo-directionNav a.nivo-prevNav:hover {
  background-color: #da251e !important;
}

.slider-area .nivo-directionNav a.nivo-prevNav:hover:before {
  color: #fff !important;
}

.slider-area .nivo-directionNav a.nivo-nextNav {
  left: 13%;
  top: 38%;
  width: 64px;
  height: 64px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  background-color: white;
  font-size: 0;
  box-shadow: 0 11px 24px 0 rgba(126, 126, 126, 0.16);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

@media only screen and (max-width: 1440px) {
  .slider-area .nivo-directionNav a.nivo-nextNav {
    left: 90%;
    top: 25%;
  }
}

@media only screen and (max-width: 1199px) {
  .slider-area .nivo-directionNav a.nivo-nextNav {
    left: 90%;
    top: 40%;
  }
}

@media only screen and (max-width: 991px) {
  .slider-area .nivo-directionNav a.nivo-nextNav {
    left: 90%;
    top: 47%;
  }
}

@media only screen and (max-width: 767px) {
  .slider-area .nivo-directionNav a.nivo-nextNav {
    left: 90%;
    top: 40%;
  }
}

@media only screen and (max-width: 479px) {
  .slider-area .nivo-directionNav a.nivo-nextNav {
    left: 85%;
    top: 30%;
  }
}

@media only screen and (max-width: 320px) {
  .slider-area .nivo-directionNav a.nivo-nextNav {
    left: 85%;
    top: 28%;
  }
}

.slider-area .nivo-directionNav a.nivo-nextNav:before {
  content: "\f106";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9;
  left: 0;
  right: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

@media only screen and (max-width: 1199px) {
  .slider-area .nivo-directionNav a.nivo-nextNav:before {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .slider-area .nivo-directionNav a.nivo-nextNav {
    width: 60px;
    height: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .slider-area .nivo-directionNav a.nivo-nextNav {
    width: 55px;
    height: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-area .nivo-directionNav a.nivo-nextNav {
    width: 50px;
    height: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-area .nivo-directionNav a.nivo-nextNav {
    width: 45px;
    height: 45px;
  }
}

.slider-area .nivo-directionNav a.nivo-nextNav:hover {
  background-color: #da251e !important;
}

.slider-area .nivo-directionNav a.nivo-nextNav:hover:before {
  color: #ffffff !important;
}

.slider-area .slider-content .item-subtitle {
  font-size: 15px;
  font-weight: 700;
  color: #da251e;
  width: 40%;
  padding-left: 20px;
  font-family: "Barlow", sans-serif;
}

@media only screen and (max-width: 991px) {
  .slider-area .slider-content .item-subtitle {
    padding-left: 0;
  }
}

@media only screen and (max-width: 479px) {
  .slider-area .slider-content .item-subtitle {
    width: 100%;
  }
}

.slider-area .slider-content .item-title {
  font-size: 46px;
  font-weight: 700;
  color: #000;
  width: 50%;
  line-height: 50px;
  position: relative;
  padding-left: 20px;
}

@media only screen and (max-width: 991px) {
  .slider-area .slider-content .item-title {
    padding-left: 0;
  }
}

.slider-area .slider-content .item-title:before {
  content: "";
  height: 91px;
  width: 5px;
  background-color: #da251e;
  position: absolute;
  bottom: 8px;
  left: 0;
}

@media only screen and (max-width: 991px) {
  .slider-area .slider-content .item-title:before {
    display: none;
  }
}

.slider-area .slider-content p {
  font-size: 16px;
  color: #444444;
  width: 40%;
}

@media only screen and (max-width: 1199px) {
  .slider-area .slider-content .item-title {
    width: 60%;
    font-size: 44px;
  }
}

@media only screen and (max-width: 991px) {
  .slider-area .slider-content .item-title {
    font-size: 36px;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 767px) {
  .slider-area .slider-content .item-title {
    width: 100%;
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-area .slider-content .item-title {
    font-size: 30px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 479px) {
  .slider-area .slider-content .item-title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 320px) {
  .slider-area .slider-content .item-title {
    font-size: 22px;
  }
}

.slider-area .slider-content .item-paragraph {
  width: 40%;
  margin-bottom: 32px;
  color: #444444;
}

@media only screen and (max-width: 991px) {
  .slider-area .slider-content .item-paragraph {
    width: 80%;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .slider-area .slider-content .item-paragraph {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .slider-area .slider-content .item-btn {
    display: none;
  }
}

.slider-area .slider-content .item-btn li {
  display: inline-block;
  margin-right: 10px;
}

.slider-area {
  position: relative;
}

.slider-area .slider-content .item-btn li {
  display: inline-block;
}

.slider-area .slider-content .item-btn li:last-child {
  margin-right: 0;
}

.slider-area .slider-content .item-btn li:hover a {
  color: #fff;
}

.slider-area .slider-content .item-btn li:hover a:after {
  visibility: visible;
  opacity: 1;
  width: 100%;
  left: 0;
}

.slider-area .slider-content .item-btn li:hover a i {
  color: #fff;
}

.slider-area .slider-content .item-btn li:hover a span i {
  color: #fff;
}

.slider-area .slider-content .item-btn li a {
  display: inline-block;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  background-color: #fff;
  padding: 15px 50px 15px 30px;
  border: none;
  border-radius: 50px;
  box-shadow: 0 6px 27px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 991px) {
  .slider-area .slider-content .item-btn li a {
    display: none;
  }
}

.slider-area .slider-content .item-btn li a:after {
  content: "";
  height: 100%;
  width: 0;
  background: linear-gradient(to right, #fa4e0a, #f8360a);
  position: absolute;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  border: none;
  border-radius: 50px;
  z-index: -5;
  transition: all 0.3s ease-in-out;
}

.slider-area .slider-content .item-btn li a i {
  margin-left: 7px;
  transition: all 0.3s ease-in-out;
  color: #da251e;
  position: absolute;
  top: 24px;
}

.slider-area .slider-content .item-btn li a .circle-icon i {
  color: #da251e;
  font-size: 16px;
  margin-right: 7px;
  transition: all 0.3s ease-in-out;
  position: inherit;
}

.slider-area .slider-content .item-btn li .circle-btn {
  text-decoration: none;
  padding: 15px 30px 15px 23px;
}

.slider-area .social-site {
  position: absolute;
  bottom: 42%;
  left: -65px;
  transform: rotate(270deg);
}

@media only screen and (max-width: 1440px) {
  .slider-area .social-site {
    display: none;
  }
}

.slider-area .social-site ul li {
  display: inline-block;
  margin-right: 7px;
}

.slider-area .social-site ul li span {
  color: #da251e;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  margin-right: 10px;
}

.slider-area .social-site ul li a {
  color: #373636;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.slider-area .social-site ul li a:hover {
  color: #da251e;
}

.slider-layout2 .nivo-caption {
  top: -20px;
}

@media only screen and (max-width: 1370px) {
  .slider-layout2 .nivo-caption {
    top: 0;
  }
}

.slider-layout2 .slider-content .item-title {
  -webkit-animation: fadeInUp 1400ms ease-in-out;
  animation: fadeInUp 1400ms ease-in-out;
}

.slider-layout2 .slider-content .item-paragraph {
  -webkit-animation: fadeInUp 1600ms ease-in-out;
  animation: fadeInUp 1600ms ease-in-out;
}

.slider-layout2 .slider-content .item-btn {
  -webkit-animation: fadeInUp 1800ms ease-in-out;
  animation: fadeInUp 1800ms ease-in-out;
}

.slider-layout1 .nivo-caption {
  top: -45px;
}

@media only screen and (max-width: 1370px) {
  .slider-layout1 .nivo-caption {
    top: 0;
  }
}

.slider-layout1 .slider-content .item-title {
  -webkit-animation: fadeInUp 1400ms ease-in-out;
  animation: fadeInUp 1400ms ease-in-out;
}

.slider-layout1 .slider-content .item-paragraph {
  -webkit-animation: fadeInUp 1600ms ease-in-out;
  animation: fadeInUp 1600ms ease-in-out;
}

.slider-layout1 .slider-content .item-btn {
  -webkit-animation: fadeInUp 1800ms ease-in-out;
  animation: fadeInUp 1800ms ease-in-out;
}

.slider-area2 {
  position: relative;
}

.slider-area2 .slider-bg-img1 {
  position: absolute;
  left: 290px;
  bottom: 0;
  z-index: 6;
}

@media only screen and (max-width: 1439px) {
  .slider-area2 .slider-bg-img1 {
    display: none;
  }
}

.slider-area2 .slider-bg-img2 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;
}

@media only screen and (max-width: 1439px) {
  .slider-area2 .slider-bg-img2 {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .s-tb-c {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .s-tb-c {
    padding-top: 20px !important;
  }
}

@media only screen and (max-width: 575px) {
  .s-tb-c {
    padding-top: 10px !important;
  }
}

.slider-area2 .nivo-directionNav a {
  z-index: 6;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (max-width: 1440px) {
  .slider-area2 .nivo-directionNav a {
    top: 42%;
  }
}

@media only screen and (max-width: 1199px) {
  .slider-area2 .nivo-directionNav a {
    top: 40%;
  }
}

.slider-area2 {
  position: relative;
}

.slider-area2 .nivo-controlNav {
  padding: 0;
  position: absolute;
  top: 50%;
  right: 70px;
  transform: translateY(-50%);
  z-index: 14;
}

@media only screen and (max-width: 767px) {
  .slider-area2 .nivo-controlNav {
    right: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-area2 .nivo-controlNav {
    right: 30px;
    top: 60%;
  }
}

@media only screen and (max-width: 320px) {
  .slider-area2 .nivo-controlNav {
    right: 20px;
  }
}

.slider-area2 .nivo-controlNav .nivo-control {
  font-size: 0;
  height: 10px;
  width: 10px;
  font-weight: 600;
  background-color: #767676;
  display: block;
  border-radius: 50%;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
}

.slider-area2 .nivo-controlNav .nivo-control:after {
  content: "";
  height: 26px;
  width: 26px;
  background-color: #fad3ca;
  position: absolute;
  top: -8px;
  left: -8px;
  border-radius: 50%;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.slider-area2 .nivo-controlNav .nivo-control:last-child {
  margin-bottom: 0;
}

.slider-area2 .nivo-controlNav .nivo-control.active {
  background-color: #da251e;
}

.slider-area2 .nivo-controlNav .nivo-control.active:after {
  visibility: visible;
  opacity: 1;
}

.slider-area2 .slider-content .item-subtitle {
  font-size: 15px;
  font-weight: 700;
  color: #da251e;
  width: 40%;
  padding-left: 20px;
  font-family: "Barlow", sans-serif;
}

@media only screen and (max-width: 991px) {
  .slider-area2 .slider-content .item-subtitle {
    padding-left: 0;
  }
}

@media only screen and (max-width: 479px) {
  .slider-area2 .slider-content .item-subtitle {
    width: 100%;
  }
}

.slider-area2 .slider-content .item-title {
  font-size: 46px;
  font-weight: 700;
  color: #000;
  width: 50%;
  line-height: 50px;
  position: relative;
  padding-left: 20px;
}

@media only screen and (max-width: 991px) {
  .slider-area2 .slider-content .item-title {
    padding-left: 0;
  }
}

.slider-area2 .slider-content .item-title:before {
  content: "";
  height: 91px;
  width: 5px;
  background-color: #da251e;
  position: absolute;
  bottom: 8px;
  left: 0;
}

@media only screen and (max-width: 991px) {
  .slider-area2 .slider-content .item-title:before {
    display: none;
  }
}

.slider-area2 .slider-content p {
  font-size: 16px;
  color: #444444;
  width: 40%;
}

@media only screen and (max-width: 1199px) {
  .slider-area2 .slider-content .item-title {
    width: 60%;
    font-size: 44px;
  }
}

@media only screen and (max-width: 991px) {
  .slider-area2 .slider-content .item-title {
    font-size: 36px;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 767px) {
  .slider-area2 .slider-content .item-title {
    width: 100%;
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-area2 .slider-content .item-title {
    font-size: 30px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 479px) {
  .slider-area2 .slider-content .item-title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 320px) {
  .slider-area2 .slider-content .item-title {
    font-size: 22px;
  }
}

.slider-area2 .slider-content .item-paragraph {
  width: 40%;
  margin-bottom: 32px;
  color: #444444;
}

@media only screen and (max-width: 991px) {
  .slider-area2 .slider-content .item-paragraph {
    width: 80%;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .slider-area2 .slider-content .item-paragraph {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .slider-area .slider-content .item-btn {
    display: none;
  }
}

.slider-area2 .slider-content .item-btn li {
  display: inline-block;
  margin-right: 10px;
}

.slider-area2 {
  position: relative;
}

.slider-area2 .slider-content .slider-button {
  display: inline-block;
}

.slider-area2 .slider-content .slider-button .slider-btn {
  display: inline-block;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  background-color: #ffffff;
  padding: 16px 50px 16px 30px;
  border: none;
  border-radius: 50px;
  box-shadow: 0 10px 27px 0 rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 991px) {
  .slider-area2 .slider-content .slider-button .slider-btn {
    display: none;
  }
}

.slider-area2 .slider-content .slider-button .slider-btn:after {
  content: "";
  height: 100%;
  width: 0;
  background: linear-gradient(to right, #fa4e0a, #f8360a);
  position: absolute;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  border: none;
  border-radius: 50px;
  z-index: -5;
  transition: all 0.3s ease-in-out;
}

.slider-area2 .slider-content .slider-button .slider-btn i {
  margin-left: 7px;
  transition: all 0.3s ease-in-out;
  color: #da251e;
  position: absolute;
  top: 24px;
}

.slider-area2 .slider-content .slider-button .slider-btn span i {
  color: #da251e;
  font-size: 16px;
  margin-right: 7px;
  transition: all 0.3s ease-in-out;
}

.slider-area2 .slider-content .slider-button:hover .slider-btn {
  color: #fff;
}

.slider-area2 .slider-content .slider-button:hover .slider-btn:after {
  visibility: visible;
  opacity: 1;
  width: 100%;
  left: 0;
}

.slider-area2 .slider-content .slider-button:hover .slider-btn i {
  color: #fff;
}

.slider-area2 .slider-content .slider-button:hover .slider-btn span i {
  color: #fff;
}

.slider-area2 .social-site {
  position: absolute;
  bottom: 42%;
  left: -65px;
  transform: rotate(270deg);
}

@media only screen and (max-width: 1440px) {
  .slider-area2 .social-site {
    display: none;
  }
}

.slider-area2 .social-site ul li {
  display: inline-block;
  margin-right: 7px;
}

.slider-area2 .social-site ul li span {
  color: #da251e;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  margin-right: 10px;
}

.slider-area2 .social-site ul li a {
  color: #373636;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.slider-area2 .social-site ul li a:hover {
  color: #da251e;
}

.slider-layout2 .nivo-caption {
  top: -20px;
}

@media only screen and (max-width: 1370px) {
  .slider-layout2 .nivo-caption {
    top: 0;
  }
}

.slider-layout2 .slider-content .item-title {
  -webkit-animation: fadeInUp 1400ms ease-in-out;
  animation: fadeInUp 1400ms ease-in-out;
}

.slider-layout2 .slider-content .item-paragraph {
  -webkit-animation: fadeInUp 1600ms ease-in-out;
  animation: fadeInUp 1600ms ease-in-out;
}

.slider-layout2 .slider-content .item-btn {
  -webkit-animation: fadeInUp 1800ms ease-in-out;
  animation: fadeInUp 1800ms ease-in-out;
}

.slider-layout1 .nivo-caption {
  top: -45px;
}

@media only screen and (max-width: 1370px) {
  .slider-layout1 .nivo-caption {
    top: 0;
  }
}

.slider-layout1 .slider-content .item-title {
  -webkit-animation: fadeInUp 1400ms ease-in-out;
  animation: fadeInUp 1400ms ease-in-out;
}

.slider-layout1 .slider-content .item-paragraph {
  -webkit-animation: fadeInUp 1600ms ease-in-out;
  animation: fadeInUp 1600ms ease-in-out;
}

.slider-layout1 .slider-content .item-btn {
  -webkit-animation: fadeInUp 1800ms ease-in-out;
  animation: fadeInUp 1800ms ease-in-out;
}

/*------------------- 3.2 Owl Carousel Nav -------------------*/
.dot-control-layout1 .owl-dots {
  text-align: center;
  margin-top: 35px;
}

.dot-control-layout1 .owl-dots .owl-dot:focus {
  outline: none;
}

.dot-control-layout1 .owl-dots .owl-dot span {
  display: inline-block;
  height: 6px;
  width: 18px;
  background-color: #cacaca;
  border-radius: 30px;
  margin-right: 5px;
  transition: all 0.3s ease-in-out;
}

.dot-control-layout1 .owl-dots .owl-dot.active span {
  height: 6px;
  width: 32px;
  border-radius: 20px;
  background: #da251e;
}

.nav-control-layout2 .owl-nav {
  margin-top: 0;
}

.nav-control-layout2 .owl-nav button {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fef22e !important;
  background: #fff !important;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 2px solid #fff !important;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.nav-control-layout2 .owl-nav button i {
  font-size: 24px;
  color: #000;
  font-weight: 900;
  transition: all 0.3s ease-in-out;
}

.nav-control-layout2 .owl-nav button:hover i {
  color: #fff;
}

.nav-control-layout2 .owl-nav button:hover {
  background: transparent !important;
  border: 2px solid #fff;
  color: #fff !important;
}

.nav-control-layout2 .owl-nav button i:before {
  font-size: 20px;
  font-weight: 600;
}

.nav-control-layout2 .owl-nav button:focus {
  outline: none;
}

@media only screen and (max-width: 767px) {
  .nav-control-layout2 .owl-nav button {
    visibility: hidden;
    opacity: 0;
  }
}

.nav-control-layout2 .owl-nav .owl-prev {
  left: 272px;
}

@media only screen and (max-width: 991px) {
  .nav-control-layout2 .owl-nav .owl-prev {
    left: 16px;
  }
}

.nav-control-layout2 .owl-nav .owl-next {
  right: 272px;
}

@media only screen and (max-width: 991px) {
  .nav-control-layout2 .owl-nav .owl-next {
    right: 16px;
  }
}

.nav-control-layout3 .owl-nav {
  margin-top: 0;
}

/*=======================================================================
4. Template Layout Style
=========================================================================*/
/*------------------- 4.1 Footer Menu -------------------*/
.footer-area1 {
  padding: 100px 0;
  background-color: #fff;
  background-repeat: no-repeat;
  position: relative;
}

@media only screen and (max-width: 1439px) {
  .footer-area1 {
    padding: 50px 0 50px 0;
  }
}

@media only screen and (max-width: 1199px) {
  .footer-area1 {
    padding: 50px 0 15px 0;
  }
}

.footer-area1 .footer-bottom-img {
  position: absolute;
  bottom: 0;
  left: 0;
}

@media only screen and (max-width: 1439px) {
  .footer-area1 .footer-bottom-img {
    display: none;
  }
}

.footer-area1 .footer-top-img {
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 1439px) {
  .footer-area1 .footer-top-img {
    display: none;
  }
}

.footer-left {
  position: relative;
}

@media only screen and (max-width: 575px) {
  .footer-left {
    margin-bottom: 30px;
  }
}

.footer-left:after {
  content: "";
  height: 229px;
  width: 1px;
  background-color: #e1e1e1;
  position: absolute;
  top: 0;
  right: 15px;
}

@media only screen and (max-width: 1199px) {
  .footer-left:after {
    display: none;
  }
}

.footer-left .footer-logo {
  margin-bottom: 25px;
}

.footer-left p {
  margin-bottom: 30px;
  padding-right: 30px;
}

@media only screen and (max-width: 991px) {
  .footer-left p {
    padding-right: 0;
    margin-bottom: 20px;
  }
}

.footer-left .footer-social ul li {
  display: inline-block;
  margin-left: 0;
}

.footer-left .footer-social ul li a {
  color: #646464;
  font-size: 20px;
  background-color: #efefef;
  border: none;
  border-radius: 4px;
  padding: 9px 14px;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 991px) {
  .footer-left .footer-social ul li a {
    font-size: 16px;
    padding: 7px 10px;
  }
}

.footer-left .footer-social ul li a:hover {
  background-color: #da251e;
  border-color: #da251e;
  color: #fff;
}

.footer-middle {
  position: relative;
}

@media only screen and (max-width: 575px) {
  .footer-middle {
    margin-bottom: 40px;
  }
}

.footer-middle:after {
  content: "";
  height: 229px;
  width: 1px;
  background-color: #e1e1e1;
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 1199px) {
  .footer-middle:after {
    display: none;
  }
}

.footer-middle .footer-title {
  position: relative;
  color: #000;
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 10px;
}

.footer-middle .footer-title:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  border: none;
}

.footer-middle .footer-title:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 0;
  left: 21px;
  border-radius: 10px;
  border: none;
}

.footer-middle .footer-list li {
  margin-bottom: 5px;
}

.footer-middle .footer-list li:last-child {
  margin-bottom: 0;
}

.footer-middle .footer-list li a {
  color: #444444;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.footer-middle .footer-list li a:hover {
  color: #da251e;
  padding-left: 5px;
}

.footer-middle .footer-list li i {
  margin-right: 7px;
  color: #696969;
}

.footer-middle .footer-list2 li {
  margin-bottom: 5px;
}

.footer-middle .footer-list2 li:last-child {
  margin-bottom: 0;
}

.footer-middle .footer-list2 li a {
  color: #444444;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.footer-middle .footer-list2 li a:hover {
  color: #da251e;
  padding-left: 5px;
}

.footer-middle .footer-list2 li i {
  margin-right: 7px;
  color: #696969;
}

@media only screen and (max-width: 1199px) {
  .footer-right {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 991px) {
  .footer-right {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-right {
    margin-top: -25px;
  }
}

.footer-right .footer-title {
  position: relative;
  color: #000;
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 10px;
}

.footer-right .footer-title:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  border: none;
}

.footer-right .footer-title:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 0;
  left: 21px;
  border-radius: 10px;
  border: none;
}

.footer-right .input-group .form-control {
  height: 54px;
  border: 1px solid #e8e8e8;
  color: #000;
}

.footer-right .input-group .form-control:focus {
  outline: none;
  box-shadow: none;
}

.footer-right .input-group .input-group-append button {
  color: #fff;
  background-color: #da251e;
  font-size: 16px;
  padding: 14px 20px 10px 25px;
  text-align: center;
  border: none;
  border-radius: 0 4px 4px 0;
  transition: all 0.3s ease-in-out;
}

.footer-right .input-group .input-group-append button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.footer-right .input-group .input-group-append button:hover {
  background-color: #ffa996;
  color: #da251e;
}

.footer-bottom {
  padding: 75px 0 0 0;
}

@media only screen and (max-width: 1199px) {
  .footer-bottom {
    padding: 45px 0 0 0;
  }
}

.footer-bottom .copyright-area {
  position: relative;
}

.footer-bottom .copyright-area:before {
  content: "";
  height: 1px;
  width: 347px;
  background-color: #e1e1e1;
  position: absolute;
  bottom: 15px;
  left: 0;
}

@media only screen and (max-width: 1199px) {
  .footer-bottom .copyright-area:before {
    width: 247px;
  }
}

@media only screen and (max-width: 991px) {
  .footer-bottom .copyright-area:before {
    width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-bottom .copyright-area:before {
    width: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-bottom .copyright-area:before {
    display: none;
  }
}

.footer-bottom .copyright-area:after {
  content: "";
  height: 1px;
  width: 347px;
  background-color: #e1e1e1;
  position: absolute;
  bottom: 15px;
  right: 0;
}

@media only screen and (max-width: 1199px) {
  .footer-bottom .copyright-area:after {
    width: 247px;
  }
}

@media only screen and (max-width: 991px) {
  .footer-bottom .copyright-area:after {
    width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-bottom .copyright-area:after {
    width: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-bottom .copyright-area:after {
    display: none;
  }
}

.footer-bottom .copyright-area p {
  text-align: center;
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .footer-bottom .copyright-area p {
    text-align: left;
  }
}

.footer-bottom .copyright-area .copyright-img1 {
  position: absolute;
  bottom: 1px;
  left: 340px;
}

@media only screen and (max-width: 1199px) {
  .footer-bottom .copyright-area .copyright-img1 {
    left: 240px;
  }
}

@media only screen and (max-width: 991px) {
  .footer-bottom .copyright-area .copyright-img1 {
    left: 115px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-bottom .copyright-area .copyright-img1 {
    left: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-bottom .copyright-area .copyright-img1 {
    display: none;
  }
}

.footer-bottom .copyright-area .copyright-img2 {
  position: absolute;
  bottom: 1px;
  right: 340px;
}

@media only screen and (max-width: 1199px) {
  .footer-bottom .copyright-area .copyright-img2 {
    right: 240px;
  }
}

@media only screen and (max-width: 991px) {
  .footer-bottom .copyright-area .copyright-img2 {
    right: 115px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-bottom .copyright-area .copyright-img2 {
    right: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-bottom .copyright-area .copyright-img2 {
    display: none;
  }
}

.footer-area2 {
  position: relative;
}

@media only screen and (max-width: 1439px) {
  .footer-area2 {
    padding: 40px 0 44px 0;
  }
}

.background-shape68 {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 35%;
  z-index: -10;
}

@media only screen and (max-width: 1199px) {
  .background-shape68 {
    display: none;
  }
}

.background-footer {
  background-color: #fff;
  padding: 60px 0 55px;
  box-shadow: 0 25px 27px 0 rgba(154, 154, 154, 0.34);
  margin-top: -185px;
  margin-left: calc((100% - 1140px) / 2 - 85px);
  margin-right: calc((100% - 1140px) / 2 - 85px);
}

@media only screen and (max-width: 1439px) {
  .background-footer {
    padding: 0;
    box-shadow: none;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 50px 0px 50px 0px;
  }
}

.background-footer .footer-left {
  position: relative;
}

.background-footer .footer-left:after {
  content: "";
  height: 229px;
  width: 1px;
  background-color: #e1e1e1;
  position: absolute;
  top: 0;
  right: 0px;
}

@media only screen and (max-width: 1199px) {
  .background-footer .footer-left:after {
    display: none;
  }
}

.background-footer .footer-left .footer-logo {
  margin-bottom: 25px;
}

@media only screen and (max-width: 1199px) {
  .background-footer .footer-left .footer-logo {
    margin-top: 10px;
  }
}

.background-footer .footer-left p {
  margin-bottom: 30px;
  padding-right: 30px;
}

.background-footer .footer-left .footer-social ul li {
  display: inline-block;
  margin-left: 0;
}

.background-footer .footer-left .footer-social ul li a {
  color: #646464;
  font-size: 20px;
  background-color: #efefef;
  border: none;
  border-radius: 4px;
  padding: 9px 14px;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 1199px) {
  .background-footer .footer-left .footer-social ul li a {
    font-size: 16px;
    padding: 7px 10px;
  }
}

.background-footer .footer-left .footer-social ul li a:hover {
  background-color: #da251e;
  border-color: #da251e;
  color: #fff;
}

.background-footer .footer-middle {
  position: relative;
  padding-left: 10px;
}

@media only screen and (max-width: 1199px) {
  .background-footer .footer-middle {
    padding-left: 0;
  }
}

@media only screen and (max-width: 991px) {
  .background-footer .footer-middle {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .background-footer .footer-middle {
    padding-left: 0;
    margin-bottom: 20px;
  }
}

.background-footer .footer-middle:after {
  content: "";
  height: 229px;
  width: 1px;
  background-color: #e1e1e1;
  position: absolute;
  top: 0;
  right: 0px;
}

@media only screen and (max-width: 1199px) {
  .background-footer .footer-middle:after {
    display: none;
  }
}

.background-footer .footer-middle .footer-title {
  position: relative;
  color: #000;
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 10px;
}

.background-footer .footer-middle .footer-title:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  border: none;
}

.background-footer .footer-middle .footer-title:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 0;
  left: 21px;
  border-radius: 10px;
  border: none;
}

.background-footer .footer-middle .footer-list li {
  margin-bottom: 5px;
}

.background-footer .footer-middle .footer-list li a {
  color: #444444;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.background-footer .footer-middle .footer-list li a:hover {
  color: #da251e;
  padding-left: 3px;
}

.background-footer .footer-middle .footer-list li i {
  margin-right: 7px;
  color: #696969;
}

.background-footer .footer-middle .footer-list2 {
  padding-right: 30px;
}

@media only screen and (max-width: 1199px) {
  .background-footer .footer-middle .footer-list2 {
    padding-right: 0;
  }
}

.background-footer .footer-middle .footer-list2 li {
  margin-bottom: 5px;
}

.background-footer .footer-middle .footer-list2 li a {
  color: #444444;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.background-footer .footer-middle .footer-list2 li a:hover {
  color: #da251e;
  padding-left: 5px;
}

.background-footer .footer-middle .footer-list2 li i {
  margin-right: 7px;
  color: #696969;
}

.background-footer .footer-right2 {
  padding-left: 10px;
}

@media only screen and (max-width: 1199px) {
  .background-footer .footer-right2 {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .background-footer .footer-right2 {
    margin-top: -6px;
  }
}

.background-footer .footer-right2 .footer-title {
  position: relative;
  color: #000;
  font-size: 22px;
  font-weight: 700;
  padding-bottom: 10px;
}

.background-footer .footer-right2 .footer-title:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  border: none;
}

.background-footer .footer-right2 .footer-title:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 0;
  left: 21px;
  border-radius: 10px;
  border: none;
}

.background-footer .footer-right2 .input-group .form-control {
  height: 54px;
  border: 1px solid #e8e8e8;
  color: #000;
}

.background-footer .footer-right2 .input-group .form-control:focus {
  outline: none;
  box-shadow: none;
}

.background-footer .footer-right2 .input-group .input-group-append button {
  color: #fff;
  background-color: #da251e;
  font-size: 16px;
  padding: 14px 20px 10px 25px;
  text-align: center;
  border: none;
  border-radius: 0 4px 4px 0;
  transition: all 0.3s ease-in-out;
}

.background-footer
  .footer-right2
  .input-group
  .input-group-append
  button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.background-footer
  .footer-right2
  .input-group
  .input-group-append
  button:hover {
  background-color: #ffa996;
  color: #da251e;
}

.background-footer .footer-bottom {
  padding: 85px 0 0 0;
}

@media only screen and (max-width: 1199px) {
  .background-footer .footer-bottom {
    padding: 50px 0 0 0;
  }
}

@media only screen and (max-width: 991px) {
  .background-footer .footer-bottom {
    padding: 20px 0 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .background-footer .footer-bottom {
    padding: 40px 0 0 0;
  }
}

.background-footer .footer-bottom .copyright-area {
  position: relative;
}

.background-footer .footer-bottom .copyright-area:before {
  content: "";
  height: 1px;
  width: 347px;
  background-color: #e1e1e1;
  position: absolute;
  bottom: 15px;
  left: -15px;
}

@media only screen and (max-width: 1199px) {
  .background-footer .footer-bottom .copyright-area:before {
    width: 247px;
  }
}

@media only screen and (max-width: 991px) {
  .background-footer .footer-bottom .copyright-area:before {
    width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .background-footer .footer-bottom .copyright-area:before {
    width: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .background-footer .footer-bottom .copyright-area:before {
    display: none;
  }
}

.background-footer .footer-bottom .copyright-area:after {
  content: "";
  height: 1px;
  width: 347px;
  background-color: #e1e1e1;
  position: absolute;
  bottom: 15px;
  right: -15px;
}

@media only screen and (max-width: 1199px) {
  .background-footer .footer-bottom .copyright-area:after {
    width: 247px;
  }
}

@media only screen and (max-width: 991px) {
  .background-footer .footer-bottom .copyright-area:after {
    width: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .background-footer .footer-bottom .copyright-area:after {
    width: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .background-footer .footer-bottom .copyright-area:after {
    display: none;
  }
}

.background-footer .footer-bottom .copyright-area p {
  text-align: center;
  margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
  .background-footer .footer-bottom .copyright-area p {
    text-align: left;
  }
}

.background-footer .footer-bottom .copyright-area .copyright-img1 {
  position: absolute;
  bottom: 1px;
  left: 328px;
}

@media only screen and (max-width: 1199px) {
  .background-footer .footer-bottom .copyright-area .copyright-img1 {
    left: 222px;
  }
}

@media only screen and (max-width: 991px) {
  .background-footer .footer-bottom .copyright-area .copyright-img1 {
    left: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .background-footer .footer-bottom .copyright-area .copyright-img1 {
    left: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .background-footer .footer-bottom .copyright-area .copyright-img1 {
    display: none;
  }
}

.background-footer .footer-bottom .copyright-area .copyright-img2 {
  position: absolute;
  bottom: 1px;
  right: 328px;
}

@media only screen and (max-width: 1199px) {
  .background-footer .footer-bottom .copyright-area .copyright-img2 {
    right: 222px;
  }
}

@media only screen and (max-width: 991px) {
  .background-footer .footer-bottom .copyright-area .copyright-img2 {
    right: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .background-footer .footer-bottom .copyright-area .copyright-img2 {
    right: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .background-footer .footer-bottom .copyright-area .copyright-img2 {
    display: none;
  }
}

.footer-area3 {
  padding: 193px 0 100px 0;
  background-color: #fff;
  background-repeat: no-repeat;
  position: relative;
}

.footer-area3 .footer-bottom-img {
  position: absolute;
  bottom: 0;
  left: 0;
}

.footer-area3 .footer-top-img {
  position: absolute;
  top: 0;
  right: 0;
}

/*------------------- 4.2 Global -------------------*/
a {
  text-decoration: none;
  cursor: pointer;
}

a:active,
a:hover,
a:focus {
  text-decoration: none;
}

a:active,
a:hover,
a:focus {
  outline: 0 none;
}

img {
  /* max-width: 100%; */
  height: auto;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.form-response .alert-danger,
.form-response .alert.alert-info,
.form-response .alert.alert-success {
  margin-top: 10px;
  margin-bottom: 0;
}

.form-response .alert-danger p,
.form-response .alert.alert-info p,
.form-response .alert.alert-success p {
  margin-bottom: 0;
}

/*------------------- 4.3 Header Menu -------------------*/
header.header {
  position: relative;
  z-index: 15;
}

@media only screen and (max-width: 991px) {
  header.header {
    display: block;
  }
}

.logo-area a {
  display: block;
}

.logo-area2 {
  position: relative;
}

.logo-area2:before {
  content: "";
  height: 105px;
  width: 1px;
  background-color: #e2e2e2;
  position: absolute;
  bottom: -29px;
  right: -77px;
}

@media only screen and (max-width: 1199px) {
  .logo-area2:before {
    display: none;
  }
}

.header-menu {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.header-menu:after {
  clear: both;
  content: "";
  display: block;
}

.header-menu nav.template-main-menu > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.header-menu nav.template-main-menu > ul > li > a {
  transition: all 0.3s ease-out;
}

.header-menu nav.template-main-menu > ul > li > a:hover {
  color: #da251e;
}

nav.template-main-menu > ul > li {
  position: relative;
}

nav.template-main-menu > ul > li > a {
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  display: block;
  color: #000000;
  text-transform: uppercase;
  font-family: "Barlow", sans-serif;
  position: relative;
}

nav.template-main-menu > ul > li > a:after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  color: #646464;
  position: absolute;
  right: 0;
}

nav.template-main-menu > ul > li > a:last-child:after {
  display: none;
}

nav.template-main-menu > ul > li > a.active:after {
  color: #da251e;
}

nav.template-main-menu2 > ul > li > a {
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  display: block;
  color: #000000;
  text-transform: uppercase;
  font-family: "Barlow", sans-serif;
  position: relative;
}

nav.template-main-menu2 > ul > li > a:before {
  content: "";
  height: 4px;
  width: 82px;
  background-color: #da251e;
  border-radius: 10px;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

nav.template-main-menu2 > ul > li > a.active {
  color: #da251e;
}

nav.template-main-menu2 > ul > li > a.active:before {
  bottom: 5px;
  visibility: visible;
  opacity: 1;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 {
  border-top: 1px solid #da251e;
  background-color: #ffffff;
  width: 250px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 0 0 4px 4px;
  -webkit-transform: translateY(15px);
  -moz-transform: translateY(15px);
  -ms-transform: translateY(15px);
  -o-transform: translateY(15px);
  transform: translateY(15px);
  -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li {
  position: relative;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li a {
  position: relative;
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  color: #000;
  padding: 12px 20px;
  display: block;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li a:before {
  content: "\f30b";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  font-size: 15px;
  color: #da251e;
  position: absolute;
  top: 12px;
  left: 20px;
  transform: scale(0);
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -ms-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li a:hover {
  color: #da251e;
  padding-left: 40px;
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li a:hover:before {
  transform: scale(1);
}

@media only screen and (max-width: 1199px) {
  nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li a {
    padding: 10px 15px;
  }
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li:after {
  content: "";
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  height: 100%;
  border-bottom: 1px solid #e9e9e9;
  z-index: -1;
}

@media only screen and (max-width: 1199px) {
  nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li:after {
    left: 15px;
    right: 15px;
  }
}

nav.template-main-menu > ul > li ul.dropdown-menu-col-1 li:last-child:after {
  border-bottom: none;
}

nav.template-main-menu > ul > li .template-mega-menu {
  background-color: #ffffff;
  border-top: 1px solid #da251e;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  top: 100%;
  padding: 20px 0 0;
  transform: translateY(15px);
  -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

nav.template-main-menu > ul > li .template-mega-menu .menu-ctg-title {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 15px;
}

nav.template-main-menu > ul > li .template-mega-menu ul.sub-menu {
  margin-bottom: 20px;
}

nav.template-main-menu > ul > li .template-mega-menu ul.sub-menu li {
  margin-bottom: 5px;
  padding-bottom: 7px;
  border-bottom: 1px solid #ebebeb;
}

nav.template-main-menu > ul > li .template-mega-menu ul.sub-menu li:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

nav.template-main-menu > ul > li .template-mega-menu ul.sub-menu li a {
  font-size: 15px;
  text-transform: capitalize;
  color: #000000;
  padding: 5px 15px 5px 30px;
  display: block;
  -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.15, 1.88);
  -moz-transition: all 0.5s cubic-bezier(0, 0, 0.15, 1.88);
  -ms-transition: all 0.5s cubic-bezier(0, 0, 0.15, 1.88);
  -o-transition: all 0.5s cubic-bezier(0, 0, 0.15, 1.88);
  transition: all 0.5s cubic-bezier(0, 0, 0.15, 1.88);
  position: relative;
}

nav.template-main-menu > ul > li .template-mega-menu ul.sub-menu li a i {
  color: #000000;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

nav.template-main-menu > ul > li .template-mega-menu ul.sub-menu li a:hover {
  color: #da251e;
  padding: 5px 15px 5px 35px;
}

nav.template-main-menu > ul > li .template-mega-menu ul.sub-menu li a:hover i {
  color: #da251e;
  animation: toRightFromLeft10 0.5s;
}

nav.template-main-menu > ul > li:hover ul.dropdown-menu-col-1 {
  opacity: 1;
  visibility: visible;
  transform: translatey(0);
}

nav.template-main-menu > ul > li:hover ul.dropdown-menu-col-2 {
  opacity: 1;
  visibility: visible;
  transform: translatey(0);
}

nav.template-main-menu > ul > li:hover .template-mega-menu {
  opacity: 1;
  visibility: visible;
  transform: translatey(0);
}

.header-menu.menu-layout1 {
  background-color: #ffffff;
  box-shadow: 0px 8px 18px 0px rgba(154, 154, 154, 0.16);
}

.header-menu.menu-layout1 nav.template-main-menu > ul > li > a {
  padding: 40px 18px;
}

.header-menu.menu-layout1 nav.template-main-menu > ul > li .no-padding {
  padding-left: 0;
}

@media only screen and (max-width: 1199px) {
  .header-menu.menu-layout1 nav.template-main-menu > ul > li > a {
    padding: 40px 15px;
  }
}

.rt-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  left: 0;
  right: 0;
  background-color: #ffffff;
}

.rt-sticky nav.template-main-menu > ul > li > a {
  padding: 28px 18px;
}

@media only screen and (max-width: 1199px) {
  .rt-sticky nav.template-main-menu > ul > li > a {
    padding: 28px 15px;
  }
}

.header-menu.menu-layout1.rt-sticky {
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
}

.header-menu.menu-layout1.rt-sticky nav.template-main-menu > ul > li > a {
  padding: 28px 18px;
}

@media only screen and (max-width: 1199px) {
  .header-menu.menu-layout1.rt-sticky nav.template-main-menu > ul > li > a {
    padding: 28px 15px;
  }
}

.header-menu.menu-layout2.rt-sticky {
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
}

.header-menu.menu-layout3.rt-sticky {
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.5);
  background-color: #14287b;
}

@media only screen and (min-width: 992px) {
  .hide-on-desktop-menu {
    display: none !important;
  }
}

@media only screen and (max-width: 991px) {
  .hide-on-mobile-menu {
    display: none;
  }
}

.header-action-layout1 ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-action-layout1 ul li {
  display: inline-block;
  margin-right: 37px;
  position: relative;
}

.header-action-layout1 ul li:after {
  content: "";
  width: 1px;
  height: 33px;
  background-color: #cbcbcb;
  position: absolute;
  bottom: 3px;
  right: -16px;
}

.header-action-layout1 ul li:last-child:after {
  display: none;
}

.header-action-layout1 ul li:last-child {
  margin-right: 0;
}

.header-action-layout1 ul .search-btn i:before {
  color: #666666;
  font-size: 24px;
}

.header-action-layout1 ul .header-number {
  position: relative;
}

.header-action-layout1 ul .header-number:after {
  content: "";
  width: 1px;
  height: 33px;
  background-color: #cbcbcb;
  position: absolute;
  bottom: 12px;
  right: -16px;
}

@media only screen and (max-width: 1199px) {
  .header-action-layout1 ul .header-number {
    display: none;
  }
}

.header-action-layout1 ul .header-number .media {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-action-layout1 ul .header-number .media .item-icon {
  margin-right: 10px;
  font-size: 32px;
  color: #da251e;
}

.header-action-layout1 ul .header-number .media .media-body {
  margin-left: 5px;
}

.header-action-layout1 ul .header-number .media .media-body .item-label {
  font-size: 14px;
  color: #646464;
}

.header-action-layout1 ul .header-number .media .media-body .item-number {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  margin-top: -7px;
  font-family: "Barlow", sans-serif;
}

@media only screen and (max-width: 1199px) {
  .header-action-layout1 ul .header-action-number-2 .item-content .item-number {
    font-size: 20px;
  }
}

.header-search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translate(0px, -100%) scale(0, 0);
  -ms-transform: translate(0px, -100%) scale(0, 0);
  transform: translate(0px, -100%) scale(0, 0);
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
}

.header-search .header-search-form {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.header-search .header-search-form input[type="search"] {
  width: 60%;
  color: white;
  font-size: 24px;
  text-align: left;
  border: none;
  margin: 0 auto;
  padding: 25px 10px 25px 30px;
  outline: none;
  background: -webkit-linear-gradient(
    left,
    transparent,
    rgba(200, 200, 200, 0.5),
    transparent
  );
  background: linear-gradient(
    to right,
    transparent,
    rgba(200, 200, 200, 0.5),
    transparent
  );
}

@media only screen and (max-width: 575px) {
  .header-search .header-search-form input[type="search"] {
    width: 75%;
  }
}

.search-btn {
  background-color: transparent;
  box-shadow: none;
  border: none;
  cursor: pointer;
}

.search-btn i {
  margin-top: 5px;
  color: #666666;
  font-size: 24px;
}

.header-search .header-search-form .search-btn i:before {
  font-size: 32px;
  font-weight: 700;
  color: #ffffff;
  margin-left: 0;
}

.header-search .header-search-form .search-btn:focus {
  box-shadow: none;
  outline: none;
}

.header-search .close {
  position: fixed;
  top: 15px;
  right: 15px;
  color: #da251e;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  opacity: 1;
  visibility: visible;
  padding: 3px 15px 5px;
  font-size: 36px;
  font-weight: 300;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0 1px 2px 0px rgba(255, 255, 255, 0.75);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.header-search .close:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

.header-search .close:hover {
  background-color: white;
}

.header-search.open {
  -webkit-transform: translate(0px, 0px) scale(1, 1);
  -ms-transform: translate(0px, 0px) scale(1, 1);
  transform: translate(0px, 0px) scale(1, 1);
  opacity: 1;
  visibility: visible;
}

.offcanvas-menu-trigger-wrap .offcanvas-menu-btn {
  border: none;
  background-color: transparent;
  padding-right: 0;
}

.offcanvas-menu-trigger-wrap .offcanvas-menu-btn:focus {
  outline: none;
}

.offcanvas-menu-trigger-wrap .btn-icon-wrap span {
  display: block;
  height: 3px;
  width: 19px;
  background-color: #4d4d4d;
  margin: 0 auto 5px;
  text-align: center;
  position: relative;
}

.offcanvas-menu-trigger-wrap .btn-icon-wrap span:before {
  content: "";
  height: 3px;
  width: 9px;
  background-color: #4d4d4d;
  position: absolute;
  bottom: 0;
  left: -12px;
}

.offcanvas-menu-trigger-wrap .btn-icon-wrap span:last-child {
  margin-bottom: 0;
}

.header-topbar-layout1 {
  background-color: #000;
}

.header-topbar-layout2 {
  background-color: #fff;
  padding: 25px 0px 21px 0px;
  border-bottom: 1px solid #e2e2e2;
}

.topbar-left {
  display: flex;
}

.topbar-left .item-paragraph {
  color: #b2b2b2;
  font-size: 15px;
  margin-bottom: 0;
}

.topbar-left .item-paragraph2 {
  color: #646464;
}

.topbar-left .header-button {
  margin-left: 10px;
}

.topbar-left .header-button a {
  color: #fff;
  display: inline-block;
  font-size: 15px;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.topbar-left .header-button a:hover i {
  margin-left: 7px;
}

.topbar-left .header-button a i {
  color: #da251e;
  margin-left: 5px;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 8px;
}

.topbar-left .header-button2 a {
  color: #000;
  font-weight: 600;
}

.topbar-left2 {
  position: relative;
}

.topbar-left2:before {
  content: "";
  height: 105px;
  width: 1px;
  background-color: #e2e2e2;
  position: absolute;
  bottom: -35px;
  right: -70px;
}

@media only screen and (max-width: 1199px) {
  .topbar-left2:before {
    display: none;
  }
}

.topbar-right {
  display: flex;
}

.topbar-right ul .topbar-social {
  display: inline-block;
  padding: 10px;
}

.topbar-right ul .topbar-social a {
  color: #636363;
  font-size: 16px;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
}

.topbar-right ul .topbar-social a:last-child {
  margin-right: 0;
}

.topbar-right ul .topbar-social a:hover {
  color: #da251e;
}

.topbar-right .header-right-button {
  padding-left: 25px;
}

.topbar-right .header-right-button .header-btn {
  display: block;
  color: #fff;
  background-image: linear-gradient(to right, #ff5e17, #da251e);
  font-size: 16px;
  padding: 10px 30px;
  border: none;
  position: relative;
  z-index: 5;
  transition: all 0.3s ease-in-out;
}

.topbar-right .header-right-button .header-btn:hover {
  opacity: 90%;
}

.topbar-right2 {
  display: flex;
  align-items: baseline;
}

.topbar-right2 ul {
  display: flex;
}

.topbar-right2 ul li {
  margin-right: 29px;
}

@media only screen and (max-width: 1199px) {
  .topbar-right2 ul li {
    display: block;
    margin-right: 0;
  }
}

.topbar-right2 ul li:last-child {
  margin-right: 0;
}

.topbar-right2 ul li .media {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 1199px) {
  .topbar-right2 ul li .media {
    display: none;
  }
}

.topbar-right2 ul li .media .item-icon {
  color: #b9b9b9;
  font-size: 32px;
}

.topbar-right2 ul li .media .media-body {
  margin-left: 20px;
}

.topbar-right2 ul li .media .media-body .item-label {
  font-size: 14px;
  color: #646464;
  margin-bottom: -6px;
}

.topbar-right2 ul li .media .media-body .item-number {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
}

.topbar-right2 ul .header-right-button .header-btn {
  display: block;
  color: #fff;
  background-image: linear-gradient(to right, #ff5e17, #da251e);
  font-size: 16px;
  font-weight: 500;
  padding: 13px 25px 10px 25px;
  border: none;
  border-radius: 30px;
  position: relative;
  z-index: 5;
  transition: all 0.3s ease-in-out;
}

.topbar-right2 ul .header-right-button .header-btn:hover {
  opacity: 90%;
}

.offcanvas-menu-wrap {
  width: 300px;
  position: fixed;
  transform: translateX(-100%);
  top: 0;
  left: 0;
  background-color: #fff;
  -webkit-box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.5);
  z-index: 200;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.offcanvas-menu-wrap[data-position="right"] {
  left: auto;
  right: 0;
  transform: translateX(105%);
}

.offcanvas-menu-wrap .close-btn {
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 25px;
  font-size: 24px;
  font-weight: 500;
  color: #da251e;
  padding: 10px;
}

.offcanvas-menu-wrap .offcanvas-content {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 96px 0 88px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-logo a {
  display: block;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-menu {
  text-align: center;
}

/* .offcanvas-menu-wrap .offcanvas-content .offcanvas-menu .nav-item {
  margin-bottom: 20px;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-menu .nav-item a {
  font-size: 15px;
  font-weight: 500;
  color: #111111;
  padding: 10px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
} */

/* .offcanvas-menu-wrap .offcanvas-content .offcanvas-menu .nav-item a:hover {
  color: #da251e;
} */

.offcanvas-menu-wrap .offcanvas-content .offcanvas-footer {
  text-align: center;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-footer .item-title {
  font-size: 15px;
  color: #acacac;
  margin-bottom: 14px;
}

.offcanvas-menu-wrap .offcanvas-content .offcanvas-footer .offcanvas-social li {
  display: inline-block;
  margin-right: 6px;
}

.offcanvas-menu-wrap
  .offcanvas-content
  .offcanvas-footer
  .offcanvas-social
  li:last-child {
  margin-right: 0;
}

.offcanvas-menu-wrap
  .offcanvas-content
  .offcanvas-footer
  .offcanvas-social
  li
  a {
  color: #444444;
  padding: 5px;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.offcanvas-menu-wrap
  .offcanvas-content
  .offcanvas-footer
  .offcanvas-social
  li
  a:hover {
  color: #da251e;
}

div#wrapper.open > .offcanvas-mask {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 150;
  animation: slideInRight 500ms ease-in-out;
  transition: all 0.5s ease-in-out;
}

.mean-container .mean-bar {
  background: #ffffff !important;
  float: none;
  padding: 0 !important;
  position: fixed !important;
  top: 0;
  z-index: 20;
}

.mean-container .mean-bar:after {
  content: "";
  clear: both;
  display: block;
}

.mean-container .mean-bar a.logo-mobile-menu {
  width: 100%;
  background-color: rgba(222, 222, 222, 0.95);
  box-sizing: border-box;
}

.mean-container .mean-bar .mobile-menu-nav-back {
  padding-left: 15px;
  border-bottom: 1px solid #b2b2b2;
  position: relative;
  text-align: center;
  height: 50px;
  display: flex;
  align-items: center;
}

.mean-container .mean-bar .mobile-menu-nav-back:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(248, 248, 248, 0.95);
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
}

.mean-container .mean-nav {
  float: none !important;
  background: transparent !important;
}

.mean-container .mean-nav > ul {
  overflow-y: auto;
  height: calc(100vh - 51px);
}

.mean-container .mean-nav > ul:after {
  content: "";
  display: block;
  clear: both;
}

.mean-container .mean-nav > ul > li > a {
  font-size: 14px;
  color: #111111;
  font-weight: 700;
  background-color: #ebebeb;
  transition: all 0.5s ease-out;
}

.mean-container .mean-nav > ul > li > a:hover {
  color: #da251e;
}

.mean-container .mean-nav > ul > li:hover > a {
  color: #da251e;
  background-color: #ffffff;
}

.mean-container .mean-nav > ul > li li a {
  color: #111111;
  opacity: 1;
  font-weight: 500;
  padding: 0.5em 10%;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}

.mean-container .mean-nav > ul > li li a:hover {
  background-color: #ffa996;
}

.mean-container .mean-nav > ul > li a.mean-expand {
  background-color: transparent;
  margin-top: 0;
  height: 20px;
  line-height: 27px;
  width: 100%;
  text-align: right;
  font-size: 14px;
  padding: 12px 20px 12px 12px !important;
}

.mean-container .mean-nav > ul > li a.mean-expand:hover {
  background-color: transparent;
}

.mean-container a.meanmenu-reveal {
  float: none !important;
  position: absolute;
  top: 0;
  transition: unset;
  padding: 16px 14px 12px;
}

.mean-container #wrapper {
  padding-top: 50px;
}

/*------------------- 4.4 Pagination -------------------*/
.item-pagination {
  text-align: center;
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .item-pagination {
    text-align: left;
    margin-top: 0;
  }
}

.item-pagination a {
  display: inline-block;
  position: relative;
  color: #000;
  font-size: 18px;
  font-weight: 600;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 7px 17px;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  margin-right: -5px;
}

.item-pagination a:hover {
  color: #fff;
  background-color: #da251e;
  border-color: #da251e;
}

.item-pagination .active {
  color: #fff;
  background-color: #da251e;
  border-color: #da251e;
}

.item-pagination2 {
  margin-top: 0;
}

.pagination-layout {
  padding: 50px 0px;
}

@media only screen and (max-width: 1199px) {
  .pagination-layout .prev-btn .media .media-body {
    margin-left: 0;
  }
}

.pagination-layout .prev-btn .media .media-body .item-title {
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 8px;
  padding-right: 30px;
}

.pagination-layout .prev-btn .media .media-body .item-title a {
  color: #000;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}

.pagination-layout .prev-btn .media .media-body .item-title a:hover {
  color: #da251e;
}

@media only screen and (max-width: 1199px) {
  .pagination-layout .prev-btn .media .media-body .item-title {
    display: none;
  }
}

.pagination-layout .prev-btn .media .media-body a {
  color: #929292;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}

.pagination-layout .prev-btn .media .media-body a:hover {
  color: #da251e;
}

.pagination-layout .next-btn {
  padding-right: 30px;
}

.pagination-layout .next-btn .media {
  flex-direction: row-reverse;
}

.pagination-layout .next-btn .media .media-body .item-title {
  color: #000;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 8px;
}

.pagination-layout .next-btn .media .media-body .item-title a {
  color: #000;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}

.pagination-layout .next-btn .media .media-body .item-title a:hover {
  color: #da251e;
}

@media only screen and (max-width: 1199px) {
  .pagination-layout .next-btn .media .media-body .item-title {
    display: none;
  }
}

.pagination-layout .next-btn .media .media-body a {
  color: #929292;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  display: block;
  text-align: right;
  margin-right: 20px;
}

.pagination-layout .next-btn .media .media-body a:hover {
  color: #da251e;
}

/*------------------- 4.5 Preloader -------------------*/
#preloader {
  /* background: #ffffff url('../img/preloader.gif') no-repeat scroll center center; */
  height: 100%;
  left: 0;
  overflow: visible;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999999;
}

/*------------------- 4.6 Scroll-up -------------------*/
.scrollup {
  overflow: hidden;
  position: fixed;
  height: 44px;
  width: 44px;
  line-height: 40px;
  border-radius: 45px;
  bottom: -100px;
  right: 20px;
  text-align: center;
  z-index: 9999;
  background-image: linear-gradient(to top, #fa4e0a, #da251e);
  border: none;
  color: #ffffff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.8s cubic-bezier(0, 0, 0.15, 1.88);
  transition: all 0.8s cubic-bezier(0, 0, 0.15, 1.88);
}

.scrollup:after {
  content: "";
  height: 100%;
  width: 100%;
  background: linear-gradient(to top, #da251e, #ff5e17);
  position: absolute;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  z-index: -10;
  transition: all 0.3s ease-in-out;
}

.scrollup:hover:after {
  visibility: visible;
  opacity: 1;
}

.scrollup:hover i {
  color: #fff;
}

.scrollup i {
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.scrollup:hover {
  background-color: #f85c70;
  color: #ffffff;
}

.scrollup.back-top {
  bottom: 25px;
}

/*------------------- 4.7 Widget -------------------*/
.sidebar-widget .widget {
  margin-bottom: 40px;
}

.sidebar-widget .widget:last-child {
  margin-bottom: 0 !important;
}

.widget-heading .item-subtitle {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  padding-bottom: 14px;
  margin-bottom: 30px;
}

.widget-heading .item-subtitle:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 0px;
  left: 0;
  border-radius: 10px;
  border: none;
}

.widget-heading .item-subtitle:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 0;
  left: 21px;
  border-radius: 10px;
  border: none;
}

@media only screen and (max-width: 991px) {
  .widget-heading .item-subtitle {
    font-size: 20px;
  }
}

.widget-heading .item-subtitle a {
  color: #000;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.widget-heading .widget-subtitle {
  margin-bottom: 10px;
}

.widget-heading .widget-subtitle2 {
  margin-bottom: 35px;
}

@media only screen and (max-width: 991px) {
  .widget-break-lg {
    margin-top: 50px;
  }
}

.widget-financial-service {
  margin-top: 10px;
}

@media only screen and (max-width: 991px) {
  .widget-financial-service {
    margin-top: 0;
  }
}

.widget-financial-service .financial-list ul li {
  display: block;
  margin-bottom: 10px;
}

.widget-financial-service .financial-list ul li:last-child {
  margin-bottom: 0;
}

.widget-financial-service .financial-list ul li a {
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 700;
  padding: 14px 32px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  position: relative;
  transition: all 0.5s ease-in-out;
}

.widget-financial-service .financial-list ul li a:hover {
  color: #fff;
  border-color: #da251e;
}

.widget-financial-service .financial-list ul li a:hover i {
  color: #fff;
}

.widget-financial-service .financial-list ul li a:hover:after {
  width: 100%;
  left: 0;
  border-color: #da251e;
}

.widget-financial-service .financial-list ul li a:before {
  content: "";
  height: calc(100% + 2px);
  width: 4%;
  background-color: transparent;
  position: absolute;
  bottom: -1px;
  left: -1px;
  border: 1px solid #da251e;
  border-radius: 4px 0px 0px 4px;
  border-right: none;
  transition: all 0.5s ease-in-out;
}

.widget-financial-service .financial-list ul li a:after {
  content: "";
  height: 100%;
  width: 0;
  background-color: #da251e;
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0px;
  z-index: -1;
  transition: all 0.5s ease-out;
}

.widget-financial-service .financial-list ul li a i {
  color: #c0c0c0;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}

.widget-contact-box .contact-list1 ul li {
  font-size: 17px;
  color: #444;
  font-weight: 500;
  margin-bottom: 8px;
}

.widget-contact-box .contact-list1 ul li:last-child {
  margin-bottom: 0;
}

.widget-contact-box .contact-list1 ul li i {
  font-size: 18px;
  color: #a4a4a4;
  margin-right: 12px;
}

@media only screen and (max-width: 991px) {
  .widget-question-box {
    text-align: center;
  }
}

.widget-question-box .item-img {
  position: relative;
  display: inline-block;
}

.widget-question-box .item-img:after {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #000;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 50%;
}

.widget-question-box .item-img img {
  filter: grayscale(100%);
}

.widget-question-box .item-title {
  position: absolute;
  top: 30px;
  left: 0;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  padding-right: 70px;
  padding-left: 70px;
  text-align: center;
  margin-bottom: 0;
  z-index: 5;
}

@media only screen and (max-width: 1199px) {
  .widget-question-box .item-title {
    font-size: 19px;
    top: 20px;
    text-align: left;
    padding-right: 0;
    padding-left: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .widget-question-box .item-title {
    font-size: 22px;
    padding-left: 70px;
    text-align: center;
    padding-right: 70px;
    top: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .widget-question-box .item-title {
    padding-left: 30px;
    padding-right: 0;
    text-align: left;
    font-size: 20px;
    top: 20px;
  }
}

.widget-question-box .phone-number-layout {
  position: absolute;
  bottom: 154px;
  left: 60px;
  border: 2px dashed #707070;
  padding: 18px 35px 14px 35px;
  z-index: 5;
}

@media only screen and (max-width: 1199px) {
  .widget-question-box .phone-number-layout {
    left: 40px;
    padding: 15px 30px 10px 30px;
  }
}

@media only screen and (max-width: 991px) {
  .widget-question-box .phone-number-layout {
    left: 70px;
  }
}

@media only screen and (max-width: 479px) {
  .widget-question-box .phone-number-layout {
    left: 30px;
    bottom: 144px;
  }
}

.widget-question-box .phone-number-layout .item-icon {
  transform: translateY(-43px) translateX(50px);
}

.widget-question-box .phone-number-layout .item-icon i {
  color: #fff;
  height: 42px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #da251e;
  border-radius: 50%;
  border: none;
}

@media only screen and (max-width: 1199px) {
  .widget-question-box .phone-number-layout .item-icon i {
    height: 40px;
    width: 40px;
  }
}

.widget-question-box .phone-number-layout .item-number {
  font-size: 22px;
  color: #fff;
  margin-top: -35px;
  font-family: "Barlow", sans-serif;
}

@media only screen and (max-width: 1199px) {
  .widget-question-box .phone-number-layout .item-number {
    font-size: 20px;
  }
}

.widget-question-box .phone-number-layout2 {
  bottom: 40px;
  left: 60px;
}

@media only screen and (max-width: 1199px) {
  .widget-question-box .phone-number-layout2 {
    left: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .widget-question-box .phone-number-layout2 {
    left: 70px;
  }
}

@media only screen and (max-width: 479px) {
  .widget-question-box .phone-number-layout2 {
    left: 30px;
  }
}

.widget-brochures-box .brochures-list ul li {
  display: block;
  margin-bottom: 10px;
}

.widget-brochures-box .brochures-list ul li:last-child {
  margin-bottom: 0;
}

.widget-brochures-box .brochures-list ul li a {
  color: #000;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  padding: 15px 32px;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  transition: all 0.5s ease-in-out;
  position: relative;
}

.widget-brochures-box .brochures-list ul li a:after {
  content: "";
  height: 100%;
  width: 0;
  background-color: #da251e;
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transition: all 0.5s ease-out;
}

.widget-brochures-box .brochures-list ul li a:hover {
  color: #fff;
}

.widget-brochures-box .brochures-list ul li a:hover:before {
  opacity: 0;
}

.widget-brochures-box .brochures-list ul li a:hover:after {
  width: 100%;
  left: 0;
  border-color: #da251e;
}

.widget-brochures-box .brochures-list ul li a:hover i {
  color: #fff;
}

.widget-brochures-box .brochures-list ul li a i {
  color: #da251e;
  font-size: 20px;
  margin-right: 15px;
  transition: all 0.5s ease-in-out;
}

.widget-details {
  transform: translateY(-108px);
  padding-left: 50px;
}

@media only screen and (max-width: 1199px) {
  .widget-details {
    transform: translateY(8px);
    padding-left: 0px;
  }
}

.widget-details .item-title {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  background-color: #da251e;
  padding: 18px 20px 22px 33px;
  border-radius: 4px 4px 0px 0px;
}

.widget-details .project-list {
  background-color: #f6f6f6;
  padding: 10px 30px 10px 30px;
  border-radius: 0px 0px 4px 4px;
}

.widget-details .project-list li {
  color: #646464;
  font-size: 14px;
  line-height: 48px;
}

.widget-details .project-list .project-details-list {
  color: #000;
  font-size: 16px;
  line-height: 48px;
  font-weight: 500;
  border-bottom: 1px solid #d3d3d3;
  margin-top: -18px;
}

.widget-details .project-social {
  margin-top: -18px;
}

.widget-details .project-social ul li {
  display: inline-block;
  margin-right: 30px;
}

@media only screen and (max-width: 320px) {
  .widget-details .project-social ul li {
    margin-right: 20px;
  }
}

.widget-details .project-social ul li:last-child {
  margin-right: 0;
}

.widget-details .project-social ul li a {
  color: #646464;
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}

.widget-details .project-social ul li a:hover {
  color: #da251e;
}

.widget.widget-search-box {
  margin-bottom: 32px;
}

.widget.widget-search-box .input-group {
  height: 60px;
  position: relative;
}

.widget.widget-search-box .input-group .form-control {
  height: 60px;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  font-size: 16px;
}

.widget.widget-search-box .input-group .form-control ::placeholder {
  color: #000;
}

.widget.widget-search-box .input-group .form-control:focus {
  box-shadow: none;
}

.widget.widget-search-box .input-group .input-group-append button {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  color: #da251e;
  border-radius: 4px;
  border: none;
  height: 60px;
  line-height: 30px;
  text-align: center;
  box-shadow: none;
  border-left: 1px solid #dfdfdf;
  padding: 10px 23px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 5;
  transition: all 0.5s ease-in-out;
}

.widget.widget-search-box .input-group .input-group-append button:hover {
  background: transparent;
}

.widget.widget-search-box .input-group .input-group-append button:focus,
.widget.widget-search-box .input-group .input-group-append button:active {
  box-shadow: none !important;
}

.widget.widget-categoery-box {
  margin-bottom: 32px;
}

.widget.widget-categoery-box .item-categoery li {
  display: block;
  margin-bottom: 5px;
  border-bottom: 1px solid #dfdfdf;
  line-height: 50px;
}

.widget.widget-categoery-box .item-categoery li:hover a {
  color: #da251e;
}

.widget.widget-categoery-box .item-categoery li:hover a span {
  color: #ffa996;
}

.widget.widget-categoery-box .item-categoery li:hover a i {
  margin-right: 10px;
  color: #000;
}

.widget.widget-categoery-box .item-categoery li a {
  color: #444;
  font-size: 16px;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
}

.widget.widget-categoery-box .item-categoery li a span {
  color: #000;
  transition: all 0.3s ease-in-out;
}

.widget.widget-categoery-box .item-categoery li a i {
  color: #646464;
  margin-right: 7px;
  transition: all 0.3s ease-in-out;
}

.widget.widget-recent-box {
  margin-bottom: 32px;
}

.widget.widget-recent-box .media {
  margin-bottom: 25px;
}

.widget.widget-recent-box .media:last-child {
  margin-bottom: 0;
}

.widget.widget-recent-box .media:hover .item-img img {
  transform: scale(1.1);
}

.widget.widget-recent-box .media .item-img {
  overflow: hidden;
}

.widget.widget-recent-box .media .item-img img {
  border-radius: 4px;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}

.widget.widget-recent-box .media .media-body {
  margin-left: 20px;
}

.widget.widget-recent-box .media .media-body .heading-title {
  margin-bottom: 0;
  line-height: 24px;
}

.widget.widget-recent-box .media .media-body .heading-title a {
  color: #444;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 479px) {
  .widget.widget-recent-box .media .media-body .heading-title a {
    font-size: 15px;
  }
}

.widget.widget-recent-box .media .media-body .heading-title a:hover {
  color: #da251e;
}

.widget.widget-recent-box .media .media-body .item-time {
  color: #da251e;
  font-size: 14px;
  font-weight: 600;
  line-height: 36px;
}

.widget.widget-archives-box {
  margin-bottom: 32px;
}

.widget.widget-archives-box .archives-list li {
  display: block;
  border-bottom: 1px solid #dfdfdf;
  line-height: 50px;
}

.widget.widget-archives-box .archives-list li a {
  color: #828282;
  transition: all 0.3s ease-in-out;
}

.widget.widget-archives-box .archives-list li a:hover {
  color: #da251e;
  padding-left: 3px;
}

.widget.widget-archives-box .archives-list li a:hover i {
  color: #ffa996;
}

.widget.widget-archives-box .archives-list li a i {
  color: #646464;
  margin-right: 7px;
  transition: all 0.3s ease-in-out;
}

.widget-tag-box .tag-list li {
  display: inline-block;
  margin-bottom: 9px;
}

.widget-tag-box .tag-list li a {
  color: #444444;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  background-color: #f2f2f2;
  border: none;
  border-radius: 4px;
  padding: 7px 14px;
}

.widget-tag-box .tag-list li a:hover {
  color: #fff;
  background-color: #da251e;
}

.widget.widget-gallery-box {
  margin-bottom: 22px;
}

.widget.widget-gallery-box .gallery-list li {
  display: inline-block;
  margin-top: -6px;
}

.widget.widget-gallery-box .gallery-list li .item-img {
  overflow: hidden;
}

.widget.widget-gallery-box .gallery-list li .item-img a img {
  transform: scale(1);
  transition: all 0.3s ease-in-out;
  border-radius: 4px;
}

.widget.widget-gallery-box .gallery-list li .item-img a img:hover {
  transform: scale(1.1);
}

/*=======================================================================
5. Template Page Style 
=========================================================================*/
/*------------------- 5.1 About -------------------*/
.about-wrap-layout1 {
  padding: 110px 0px;
}

@media only screen and (max-width: 1199px) {
  .about-wrap-layout1 {
    padding: 40px 0px 10px 0px;
  }
}

@media only screen and (max-width: 575px) {
  .about-wrap-layout1 {
    padding: 20px 0px 0px 0px;
  }
}

.about-box4 .heading-subtitle {
  font-size: 18px;
  font-weight: 700;
  color: #444;
  font-family: "Barlow", sans-serif;
  position: relative;
  margin-bottom: 10px;
}

.about-box4 .heading-subtitle:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 12px;
  left: 130px;
  border-radius: 10px;
  border: none;
}

.about-box4 .heading-subtitle:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 12px;
  left: 151px;
  border-radius: 10px;
  border: none;
}

.about-box4 .heading-title {
  font-size: 36px;
  font-weight: 700;
  color: #000;
  padding-right: 20px;
}

@media only screen and (max-width: 1199px) {
  .about-box4 .heading-title {
    font-size: 30px;
    padding-right: 0;
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 479px) {
  .about-box4 .heading-title {
    font-size: 24px;
    margin-bottom: 10px;
    line-height: 30px;
  }
}

.about-box4 p {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .about-box4 p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media only screen and (max-width: 991px) {
  .about-box4 p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .about-box4 p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.about-box4 .about-layout {
  color: #fff;
  padding: 40px 65px 30px 50px;
  box-shadow: 0px 6px 32px 0px rgba(126, 126, 126, 0.1);
  border: none;
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
  z-index: 5;
  transition: all 0.3s ease-in-out;
}

.about-box4 .about-layout:hover {
  box-shadow: 0px 25px 62px 0px rgba(126, 126, 126, 0.2);
}

.about-box4 .about-layout:hover:before {
  visibility: visible;
  opacity: 1;
}

.about-box4 .about-layout:hover .media .item-img:before {
  background-color: #ffece8;
}

.about-box4 .about-layout:before {
  content: "";
  height: 100%;
  width: 4px;
  background-color: #da251e;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border: none;
  z-index: -5;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 479px) {
  .about-box4 .about-layout:before {
    height: 100%;
  }
}

.about-box4 .about-layout:last-child {
  margin-bottom: 0;
}

.about-box4 .about-layout .media .item-img {
  position: relative;
  z-index: 5;
}

.about-box4 .about-layout .media .item-img:before {
  content: "";
  height: 72px;
  width: 72px;
  background-color: #f0f0f0;
  position: absolute;
  bottom: -13px;
  left: -15px;
  border: none;
  border-radius: 50%;
  z-index: -5;
  transition: all 0.3s ease-in-out;
}

.about-box4 .about-layout .media .about-img2:before {
  bottom: -15px;
  left: -15px;
}

.about-box4 .about-layout .media .media-body {
  margin-left: 40px;
}

.about-box4 .about-layout .media .media-body .item-content {
  margin-top: -10px;
}

.about-box4 .about-layout .media .media-body .item-content .item-title {
  color: #000;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 5px;
}

@media only screen and (max-width: 991px) {
  .about-box4 .about-layout .media .media-body .item-content .item-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .about-box4 .about-layout .media .media-body .item-content .item-title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 479px) {
  .about-box4 .about-layout .media .media-body .item-content .item-title {
    font-size: 18px;
    line-height: 30px;
  }
}

.about-box4 .about-layout .media .media-body .item-content p {
  color: #646464;
  margin-bottom: 0;
  font-weight: 500;
}

@media only screen and (max-width: 991px) {
  .about-box4 .about-layout .media .media-body .item-content p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.about-box5 {
  padding-left: 70px;
}

@media only screen and (max-width: 1199px) {
  .about-box5 {
    padding: 0;
  }
}

.about-box5 .item-img {
  position: relative;
  z-index: 5;
}

.about-box5 .item-img:after {
  content: "";
  height: 0px;
  width: 0px;
  position: absolute;
  top: -16px;
  left: -50px;
  transform: rotate(-385deg);
  opacity: 30%;
  border-left: 50px solid transparent;
  border-right: 56px solid transparent;
  border-bottom: 65px solid #f93609;
}

@media only screen and (max-width: 1439px) {
  .about-box5 .item-img:after {
    display: none;
  }
}

.about-box5 .item-img img {
  border-radius: 0px 50px 0px 50px;
  margin-top: 10px;
}

@media only screen and (max-width: 1199px) {
  .about-box5 .item-img img {
    height: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .about-box5 .item-img img {
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .about-box5 .item-img img {
    margin-top: 30px;
  }
}

.about-box5 .background-image1 {
  position: absolute;
  bottom: -50px;
  left: -50px;
  z-index: -5;
  opacity: 30%;
}

@media only screen and (max-width: 1439px) {
  .about-box5 .background-image1 {
    display: none;
  }
}

.about-box5 .background-image2 {
  position: absolute;
  top: -55px;
  right: -55px;
  z-index: -5;
}

@media only screen and (max-width: 1439px) {
  .about-box5 .background-image2 {
    display: none;
  }
}

.about-box5 .background-image3 {
  position: absolute;
  top: 90px;
  right: -70px;
  z-index: -5;
}

@media only screen and (max-width: 1439px) {
  .about-box5 .background-image3 {
    display: none;
  }
}

/*------------------- 5.2 About-finnaco -------------------*/
.about-finnaco-wrap1 {
  position: relative;
  background-color: #f8f8f8;
  padding: 400px 0px 0px 0px;
}

@media only screen and (max-width: 1199px) {
  .about-finnaco-wrap1 {
    padding: 40px 0px 0px 0px;
  }
}

.about-finnaco-wrap1 .background-shape8 {
  position: absolute;
  bottom: 100px;
  right: 240px;
}

@media only screen and (max-width: 1439px) {
  .about-finnaco-wrap1 .background-shape8 {
    display: none;
  }
}

.about-box1 .section-subtitle {
  color: #444444;
  font-size: 18px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  position: relative;
  margin-bottom: 10px;
}

.about-box1 .section-subtitle:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 12px;
  left: 148px;
  border-radius: 10px;
  border: none;
}

.about-box1 .section-subtitle:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 12px;
  left: 170px;
  border-radius: 10px;
  border: none;
}

.about-box1 .section-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1199px) {
  .about-box1 .section-title {
    font-size: 30px;
    line-height: 40px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media only screen and (max-width: 479px) {
  .about-box1 .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.about-box1 p {
  margin-bottom: 28px;
}

@media only screen and (max-width: 991px) {
  .about-box1 p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.about-box1 .media:hover .item-img img {
  transform: translateY(-5%);
}

.about-box1 .media .item-img img {
  transform: translateY(0%);
  transition: all 0.3s ease-in-out;
}

.about-box1 .media .media-body {
  margin-left: 30px;
}

.about-box1 .media .media-body .heading-title {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 7px;
}

@media only screen and (max-width: 479px) {
  .about-box1 .media .media-body .heading-title {
    font-size: 20px;
    line-height: 30px;
  }
}

.about-box1 .media .media-body .heading-title a {
  color: #000;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

.about-box1 .media .media-body .heading-title a:hover {
  color: #da251e;
}

.about-box1 .media .media-body p {
  font-weight: 500;
}

@media only screen and (max-width: 320px) {
  .about-box1 .media .media-body p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.about-box2 {
  padding-left: 160px;
}

@media only screen and (max-width: 1199px) {
  .about-box2 {
    padding: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .about-box2 {
    display: block;
  }
}

.about-finnaco-wrap2 {
  padding: 163px 0px 130px 0px;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .about-finnaco-wrap2 {
    padding: 50px 0px 53px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .about-finnaco-wrap2 {
    padding: 20px 0px 55px 0px;
  }
}

.about-finnaco-wrap2 .background-image {
  position: absolute;
  top: 130px;
  left: 320px;
}

@media only screen and (max-width: 991px) {
  .about-finnaco-wrap2 .background-image {
    display: none;
  }
}

.about-box3 .item-img {
  position: relative;
}

@media only screen and (max-width: 991px) {
  .about-box3 .item-img {
    margin-bottom: 30px;
  }
}

.about-box3 .item-img:after {
  content: "";
  height: 0px;
  width: 0px;
  position: absolute;
  top: 70px;
  right: -25px;
  transform: rotate(-30deg);
  filter: blur(4px);
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  border-bottom: 55px solid #da251e;
}

@media only screen and (max-width: 991px) {
  .about-box3 .item-img:after {
    display: none;
  }
}

.about-box3 .item-img:before {
  content: "";
  height: 60px;
  width: 60px;
  background-color: transparent;
  position: absolute;
  bottom: 120px;
  left: -36px;
  border-radius: 50%;
  border: 5px solid #da251e;
  filter: blur(4px);
}

@media only screen and (max-width: 991px) {
  .about-box3 .item-img:before {
    display: none;
  }
}

.about-box3 .item-img img {
  border-radius: 50px 0px 50px 0px;
}

.about-box3 .shape-box {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 479px) {
  .about-box3 .shape-box {
    display: none;
  }
}

.about-box3 .shape-box .item-shape {
  display: flex;
  position: relative;
  z-index: 5;
}

.about-box3 .shape-box .item-shape:after {
  content: "";
  width: 431px;
  height: 109px;
  background-color: #222222;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -5;
  border: none;
  border-radius: 50px 0px 50px 0px;
}

.about-box3 .shape-box .item-shape:before {
  content: "";
  width: 320px;
  height: 109px;
  background-color: #da251e;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 5;
  border: none;
  border-radius: 0px 0px 50px 0px;
}

.about-box3 .shape-box .item-shape .item-icon {
  font-size: 30px;
  color: #fff;
  padding: 20px 80px 0px 0px;
}

.about-box3 .shape-box .item-shape .item-estimate {
  padding: 0px 40px 25px 0px;
  z-index: 5;
}

.about-box3 .shape-box .item-shape .item-estimate li {
  color: #fff;
  font-size: 16px;
}

.about-box3 .shape-box .item-shape .item-estimate li span {
  font-size: 24px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
}

.about-box3 .about-box8 {
  margin-top: -10px;
  margin-left: 45px;
}

@media only screen and (max-width: 1199px) {
  .about-box3 .about-box8 {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .about-box3 .about-box8 {
    display: block;
    margin-left: 0;
  }
}

.about-box3 .about-box8 .heading-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  padding-right: 35px;
}

@media only screen and (max-width: 1199px) {
  .about-box3 .about-box8 .heading-title {
    font-size: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .about-box3 .about-box8 .heading-title {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 479px) {
  .about-box3 .about-box8 .heading-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.about-box3 .about-box8 p {
  margin-bottom: 30px;
  color: #646464;
  padding-right: 60px;
}

@media only screen and (max-width: 1199px) {
  .about-box3 .about-box8 p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-right: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .about-box3 .about-box8 p {
    margin-bottom: 10px;
  }
}

.about-box3 .about-box8 .media .about-list li {
  color: #646464;
  margin-bottom: 10px;
}

.about-box3 .about-box8 .media .about-list li:last-child {
  margin-bottom: 0;
}

.about-box3 .about-box8 .media .about-list li i {
  color: #da251e;
  font-size: 16px;
  margin-right: 15px;
}

.about-box3 .about-box8 .media .media-body {
  margin-left: 60px;
}

@media only screen and (max-width: 1440px) {
  .about-box3 .about-box8 .media .media-body .grubh-img {
    display: none;
  }
}

.about-box3 .about-box8 .item-paragraph {
  color: #646464;
  margin-top: 20px;
  margin-bottom: 43px;
}

@media only screen and (max-width: 991px) {
  .about-box3 .about-box8 .item-paragraph {
    margin-top: 10px;
    margin-bottom: 33px;
  }
}

@media only screen and (max-width: 1199px) {
  .about-box3 .about-box8 .item-paragraph {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media only screen and (max-width: 479px) {
  .about-box3 .about-box8 .media {
    display: block;
  }
}

.about-box3 .about-box8 .media .about-img {
  position: relative;
}

@media only screen and (max-width: 479px) {
  .about-box3 .about-box8 .media .about-img {
    margin-bottom: 20px;
  }
}

.about-box3 .about-box8 .media .about-img:after {
  content: "";
  width: 124px;
  height: 124px;
  background-color: transparent;
  position: absolute;
  bottom: -7px;
  left: -7px;
  right: 0;
  z-index: 5;
  border: 2px dashed #da251e;
  border-radius: 50%;
}

@media only screen and (max-width: 479px) {
  .about-box3 .about-box8 .media .about-img:after {
    display: none;
  }
}

.about-box3 .about-box8 .media .about-img img {
  border-radius: 50%;
}

.about-box3 .about-box8 .media .media-body2 {
  margin-left: 40px;
  margin-top: 20px;
}

@media only screen and (max-width: 1199px) {
  .about-box3 .about-box8 .media .media-body2 {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .about-box3 .about-box8 .media .media-body2 {
    margin-left: 0;
  }
}

.about-box3 .about-box8 .media .media-body2 .item-title {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
  font-family: Segoe Print;
}

@media only screen and (max-width: 1199px) {
  .about-box3 .about-box8 .media .media-body2 .item-title {
    font-size: 20px;
  }
}

.about-box3 .about-box8 .media .media-body2 .item-subtitle {
  color: #9c9b9b;
  position: relative;
  font-size: 16px;
  font-family: Roboto;
}

.about-box3 .about-box8 .media .media-body2 .item-subtitle:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 12px;
  left: 120px;
  border-radius: 10px;
  border: none;
}

.about-box3 .about-box8 .media .media-body2 .item-subtitle:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 12px;
  left: 140px;
  border-radius: 10px;
  border: none;
}

/*------------------- 5.3 Banner -------------------*/
.banner-wrap1 {
  padding: 50px 0px;
}

@media only screen and (max-width: 1439px) {
  .banner-wrap1 {
    padding-bottom: 44px;
  }
}

@media only screen and (max-width: 991px) {
  .banner-wrap1 {
    padding-top: 20px;
  }
}

.banner-box1 {
  background-color: #da251e;
  display: flex;
  position: relative;
}

.banner-box1:after {
  content: "";
  height: 100%;
  width: 240px;
  background: rgba(250, 84, 10, 0.2);
  position: absolute;
  bottom: 0;
  z-index: 5;
  left: 400px;
  transform: rotate(360deg);
  clip-path: polygon(17% 0, 29% 0, 100% 100%, 69% 100%);
}

@media only screen and (max-width: 1199px) {
  .banner-box1:after {
    display: none;
  }
}

.banner-box1:before {
  content: "";
  height: 100%;
  width: 240px;
  background: rgba(250, 84, 10, 0.11);
  position: absolute;
  bottom: 0;
  right: 684px;
  z-index: 5;
  transform: rotate(360deg);
  clip-path: polygon(17% 0, 29% 0, 100% 100%, 69% 100%);
}

@media only screen and (max-width: 1439px) {
  .banner-box1:before {
    display: none;
  }
}

.banner-box1 .item-img img {
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 102%);
}

@media only screen and (max-width: 991px) {
  .banner-box1 .item-img img {
    display: none;
  }
}

.banner-box1 .bannar-details {
  padding: 45px 50px 34px 50px;
}

@media only screen and (max-width: 1199px) {
  .banner-box1 .bannar-details {
    padding: 5px 10px;
  }
}

@media only screen and (max-width: 991px) {
  .banner-box1 .bannar-details {
    padding: 45px 50px 34px 130px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-box1 .bannar-details {
    padding: 45px 50px 12px 50px;
  }
}

@media only screen and (max-width: 575px) {
  .banner-box1 .bannar-details {
    padding: 45px 30px 12px 30px;
  }
}

.banner-box1 .bannar-details .heading-title {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .banner-box1 .bannar-details .heading-title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 991px) {
  .banner-box1 .bannar-details .heading-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .banner-box1 .bannar-details .heading-title {
    text-align: left;
    margin-bottom: 20px;
    font-size: 24px;
  }
}

@media only screen and (max-width: 479px) {
  .banner-box1 .bannar-details .heading-title {
    font-size: 20px;
    line-height: 30px;
  }
}

.banner-box1 .bannar-details .contact-box2 {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1199px) {
  .banner-box1 .bannar-details .contact-box2 {
    display: block;
  }
}

@media only screen and (max-width: 991px) {
  .banner-box1 .bannar-details .contact-box2 {
    display: flex;
  }
}

@media only screen and (max-width: 479px) {
  .banner-box1 .bannar-details .contact-box2 {
    display: block;
  }
}

.banner-box1 .bannar-details .contact-box2 .item-icon-box {
  display: flex;
  align-items: center;
}

.banner-box1 .bannar-details .contact-box2 .item-icon-box .item-icon {
  color: #fff;
  font-size: 30px;
}

.banner-box1 .bannar-details .contact-box2 .item-icon-box .banner-content {
  margin-left: 10px;
}

.banner-box1
  .bannar-details
  .contact-box2
  .item-icon-box
  .banner-content
  .item-hotline {
  color: #fff;
  font-size: 16px;
  margin-bottom: -10px;
}

.banner-box1
  .bannar-details
  .contact-box2
  .item-icon-box
  .banner-content
  .item-number {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
}

@media only screen and (max-width: 1199px) {
  .banner-box1
    .bannar-details
    .contact-box2
    .item-icon-box
    .banner-content
    .item-number {
    font-size: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .banner-box1
    .bannar-details
    .contact-box2
    .item-icon-box
    .banner-content
    .item-number {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-box1
    .bannar-details
    .contact-box2
    .item-icon-box
    .banner-content
    .item-number {
    font-size: 16px;
  }
}

.banner-box1 .bannar-details .contact-box2 .item-icon-box2 {
  padding-left: 57px;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .banner-box1 .bannar-details .contact-box2 .item-icon-box2 {
    padding-left: 0;
  }
}

@media only screen and (max-width: 991px) {
  .banner-box1 .bannar-details .contact-box2 .item-icon-box2 {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-box1 .bannar-details .contact-box2 .item-icon-box2 {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .banner-box1 .bannar-details .contact-box2 .item-icon-box2 {
    padding-left: 0;
    padding-top: 17px;
  }
}

.banner-box1 .bannar-details .contact-box2 .item-icon-box2:after {
  content: "";
  width: 2px;
  height: 46px;
  background-color: #fb5e3b;
  position: absolute;
  bottom: 3px;
  left: 28px;
  border: none;
  transform: rotate(20deg);
}

@media only screen and (max-width: 1199px) {
  .banner-box1 .bannar-details .contact-box2 .item-icon-box2:after {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .banner-box1 .bannar-details .contact-box2 .item-icon-box2:after {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .banner-box1 .bannar-details .contact-box2 .item-icon-box2:after {
    left: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .banner-box1 .bannar-details .contact-box2 .item-icon-box2:after {
    display: none;
  }
}

.banner-wrap2 {
  background-color: #000;
  padding: 60px 0px 50px 0px;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .banner-wrap2 {
    padding: 40px 0px 50px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .banner-wrap2 {
    padding: 40px 0px 50px 0px;
  }
}

.banner-wrap2 .background-image1 {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 20%;
}

.banner-wrap2 .background-image2 {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 20%;
}

@media only screen and (max-width: 1199px) {
  .banner-wrap2 .background-image2 {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .banner-box2 .media {
    display: block;
  }
}

.banner-box2 .media .section-title {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
}

@media only screen and (max-width: 1199px) {
  .banner-box2 .media .section-title {
    font-size: 30px;
    text-align: center;
  }
}

@media only screen and (max-width: 479px) {
  .banner-box2 .media .section-title {
    font-size: 24px;
    line-height: 36px;
  }
}

.banner-box2 .media .media-body {
  margin-left: 65px;
}

@media only screen and (max-width: 1199px) {
  .banner-box2 .media .media-body {
    margin-left: 0px;
    margin-top: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .banner-box2 .media .media-body .item-button {
    text-align: center;
  }
}

.banner-box2 .media .media-body .item-button .item-btn {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  background-image: linear-gradient(to right, #ff5e17, #da251e);
  padding: 14px 48px 14px 28px;
  border: none;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 5;
}

.banner-box2 .media .media-body .item-button .item-btn:hover {
  opacity: 90%;
}

.banner-box2 .media .media-body .item-button .item-btn:hover i {
  margin-left: 10px;
}

.banner-box2 .media .media-body .item-button .item-btn i {
  margin-left: 8px;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 20px;
}

@media only screen and (max-width: 991px) {
  .banner-box2 .media .media-body .item-button {
    display: block;
  }
}

.banner-wrap3 {
  /* background-image: url("../img/service/bannar1.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 62px 0px 70px 0px;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .banner-wrap3 {
    padding: 40px 0px 50px 0px;
  }
}

@media only screen and (max-width: 575px) {
  .banner-wrap3 {
    padding: 20px 0px 30px 0px;
  }
}

.banner-wrap3 .background-image1 {
  position: absolute;
  top: 0;
  left: 0;
}

.banner-wrap3 .background-image2 {
  position: absolute;
  top: 0;
  right: 0;
}

.banner-box3 .section-title {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  position: relative;
  padding-bottom: 17px;
}

@media only screen and (max-width: 479px) {
  .banner-box3 .section-title {
    font-size: 24px;
    line-height: 36px;
  }
}

.banner-box3 .section-title:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 10px;
  border: none;
}

.banner-box3 .section-title:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 0px;
  left: 20px;
  border-radius: 10px;
  border: none;
}

.banner-box3 .message-box {
  margin-top: 40px;
  margin-bottom: 5px;
}

.banner-box3 .message-box .form-group {
  position: relative;
}

.banner-box3 .message-box .form-group .form-control {
  border: 1px solid #7a7a7a;
  background-color: transparent;
  color: #fff;
}

.banner-box3 .message-box .form-group .form-control:focus {
  box-shadow: none;
  border-color: #da251e;
}

.banner-box3 .message-box .form-group input {
  height: 56px;
}

.banner-box3 .message-box .form-group label {
  position: absolute;
  top: -15px;
  left: 45px;
  padding-right: 7px;
  padding-left: 7px;
  background-color: #1e1e1e;
  opacity: 90%;
  display: inline-block;
  color: #fff;
}

.banner-box3 .message-box .item-btn {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  background-image: linear-gradient(to right, #da251e, #ff5e17);
  padding: 13px 50px 13px 30px;
  border: none;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 5;
}

.banner-box3 .message-box .item-btn:hover {
  opacity: 90%;
}

.banner-box3 .message-box .item-btn:hover i {
  margin-left: 10px;
}

.banner-box3 .message-box .item-btn i {
  margin-left: 7px;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 19px;
}

.banner-wrap4 {
  transform: translateY(136px);
  position: relative;
  z-index: 6;
  margin-top: -294px;
}

@media only screen and (max-width: 1439px) {
  .banner-wrap4 {
    transform: translateY(0);
    z-index: 0;
    margin-top: 0;
    padding: 50px 0px 50px 0px;
  }
}

.banner-wrap5 {
  padding: 40px 0px 100px 0px;
}

@media only screen and (max-width: 1439px) {
  .banner-wrap5 {
    padding: 20px 0px 50px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .banner-wrap5 {
    padding: 56px 0px 50px 0px;
  }
}

.banner-box4 {
  background-color: #da251e;
  display: flex;
  position: relative;
  border-radius: 0px 50px 0px 0px;
}

.banner-box4:after {
  content: "";
  height: 100%;
  width: 141px;
  background: rgba(250, 84, 10, 0.25);
  position: absolute;
  bottom: 0;
  z-index: 5;
  left: 291px;
  transform: rotate(360deg);
  clip-path: polygon(17% 0, 29% 0, 100% 100%, 69% 100%);
}

@media only screen and (max-width: 1199px) {
  .banner-box4:after {
    display: none;
  }
}

.banner-box4:before {
  content: "";
  height: 100%;
  width: 141px;
  background: rgba(250, 84, 10, 0.15);
  position: absolute;
  bottom: 0;
  right: 327px;
  z-index: 5;
  transform: rotate(360deg);
  clip-path: polygon(17% 0, 29% 0, 100% 100%, 69% 100%);
}

@media only screen and (max-width: 1439px) {
  .banner-box4:before {
    display: none;
  }
}

.banner-box4 .item-img img {
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
}

@media only screen and (max-width: 991px) {
  .banner-box4 .item-img img {
    display: none;
  }
}

.banner-box4 .bannar-details2 {
  padding: 44px 25px 0px 12px;
}

@media only screen and (max-width: 991px) {
  .banner-box4 .bannar-details2 {
    padding: 45px 50px 40px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .banner-box4 .bannar-details2 {
    padding: 25px 20px 20px 32px;
  }
}

.banner-box4 .bannar-details2 .heading-title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 40px;
}

@media only screen and (max-width: 1199px) {
  .banner-box4 .bannar-details2 .heading-title {
    font-size: 24px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media only screen and (max-width: 991px) {
  .banner-box4 .bannar-details2 .heading-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .banner-box4 .bannar-details2 .heading-title {
    text-align: left;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .banner-box4 .bannar-details2 .heading-title {
    font-size: 24px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 320px) {
  .banner-box4 .bannar-details2 .heading-title {
    font-size: 22px;
  }
}

.banner-box4 .bannar-details2 .media .item-icon {
  font-size: 32px;
  color: #fff;
}

.banner-box4 .bannar-details2 .media .media-body {
  margin-left: 12px;
  margin-top: -10px;
}

.banner-box4 .bannar-details2 .media .media-body .item-hotline {
  font-size: 16px;
  color: #fff;
  margin-bottom: -7px;
}

.banner-box4 .bannar-details2 .media .media-body .item-number {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  font-family: Lora, serif;
}

.banner-wrap6 {
  padding: 0px 0px 125px 0px;
}

@media only screen and (max-width: 1439px) {
  .banner-wrap6 {
    padding: 0px 0px 50px 0px;
  }
}

.banner-wrap7 {
  padding: 0px 0px 110px 0px;
}

@media only screen and (max-width: 1439px) {
  .banner-wrap7 {
    padding: 10px 0px 50px 0px;
  }
}

/*------------------- 5.4 Blog -------------------*/
.blog-wrap-layout1 {
  background-color: #fff;
  padding: 120px 0px;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .blog-wrap-layout1 {
    padding: 40px 0px 50px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-wrap-layout1 {
    padding: 40px 0px 0px 0px;
  }
}

.blog-wrap-layout1 .section-top-img {
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 991px) {
  .blog-wrap-layout1 .section-top-img {
    display: none;
  }
}

.blog-wrap-layout1 .section-subtitle {
  color: #da251e;
  font-size: 18px;
  font-weight: 700;
  position: relative;
  font-family: "Barlow", sans-serif;
  margin-bottom: 10px;
}

.blog-wrap-layout1 .section-subtitle:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 12px;
  left: 176px;
  border-radius: 10px;
  border: none;
}

.blog-wrap-layout1 .section-subtitle:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 12px;
  left: 196px;
  border-radius: 10px;
  border: none;
}

.blog-top-area {
  margin-bottom: 20px;
}

.blog-top-area .section-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  padding-right: 10px;
}

@media only screen and (max-width: 1199px) {
  .blog-top-area .section-title {
    font-size: 30px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 479px) {
  .blog-top-area .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.blog-wrap-layout2 {
  background-color: #fff;
  padding: 120px 0px 130px 0px;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .blog-wrap-layout2 {
    padding: 40px 0px 50px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .blog-wrap-layout2 {
    padding: 40px 0px 0px 0px;
  }
}

.blog-wrap-layout2 .section-top-img {
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 767px) {
  .blog-wrap-layout2 .section-top-img {
    display: none;
  }
}

.blog-wrap-layout2 .item-heading {
  text-align: center;
  margin-bottom: 40px;
}

.blog-wrap-layout2 .item-heading .section-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1199px) {
  .blog-wrap-layout2 .item-heading .section-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .blog-wrap-layout2 .item-heading .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.blog-wrap-layout2 .item-heading p {
  width: 42%;
  margin: 0 auto;
}

@media only screen and (max-width: 1199px) {
  .blog-wrap-layout2 .item-heading p {
    width: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .blog-wrap-layout2 .item-heading p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.blog-box1 {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .blog-box1 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 479px) {
  .blog-box1 {
    margin-bottom: 40px;
  }
}

.blog-box1:hover .item-img img {
  transform: scale(1.1);
}

.blog-box1 .item-img {
  overflow: hidden;
}

.blog-box1 .item-img img {
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}

.blog-box1 .content-box {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border: none;
  width: 80%;
  padding: 15px 20px 0px 10px;
}

@media only screen and (max-width: 479px) {
  .blog-box1 .content-box {
    position: inherit;
    padding: 20px 0px 0px 0px;
    width: 100%;
  }
}

.blog-box1 .content-box:after {
  content: "";
  width: 80px;
  height: 80px;
  background: #da251e;
  position: absolute;
  top: -25px;
  right: -35px;
  transform: rotate(38deg);
  border: none;
  border-radius: 5px;
}

@media only screen and (max-width: 991px) {
  .blog-box1 .content-box:after {
    width: 60px;
    height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-box1 .content-box:after {
    width: 80px;
    height: 80px;
  }
}

@media only screen and (max-width: 479px) {
  .blog-box1 .content-box:after {
    width: 62px;
    height: 62px;
    top: -34px;
    right: 10px;
    transform: rotate(45deg);
  }
}

.blog-box1 .content-box .entry-meta {
  margin-bottom: 7px;
}

.blog-box1 .content-box .entry-meta ul li {
  display: inline-block;
  margin-right: 22px;
}

@media only screen and (max-width: 991px) {
  .blog-box1 .content-box .entry-meta ul li {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 479px) {
  .blog-box1 .content-box .entry-meta ul li {
    margin-right: 10px;
    display: block;
  }
}

@media only screen and (max-width: 320px) {
  .blog-box1 .content-box .entry-meta ul li {
    display: block;
  }
}

.blog-box1 .content-box .entry-meta ul li:last-child {
  margin-right: 0;
}

.blog-box1 .content-box .entry-meta ul li a {
  color: #646464;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.blog-box1 .content-box .entry-meta ul li a:hover {
  color: #da251e;
}

.blog-box1 .content-box .entry-meta ul li a i {
  color: #da251e;
  font-size: 14px;
  margin-right: 8px;
}

.blog-box1 .content-box .heading-title {
  font-size: 24px;
  line-height: 34px;
}

@media only screen and (max-width: 991px) {
  .blog-box1 .content-box .heading-title {
    font-size: 20px;
  }
}

.blog-box1 .content-box .heading-title a {
  color: #000;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}

.blog-box1 .content-box .heading-title a:hover {
  color: #da251e;
}

.blog-box1 .content-box .blog-date {
  position: absolute;
  top: -12px;
  right: -12px;
  z-index: 5;
  font-size: 30px;
  color: #fff;
  font-weight: 700;
}

@media only screen and (max-width: 991px) {
  .blog-box1 .content-box .blog-date {
    font-size: 24px;
    right: -18px;
    top: -18px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-box1 .content-box .blog-date {
    top: -12px;
    right: -12px;
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .blog-box1 .content-box .blog-date {
    font-size: 24px;
    right: 27px;
    top: -30px;
  }
}

.blog-box1 .content-box .blog-date span {
  font-size: 16px;
  display: block;
  margin-top: -3px;
}

.blog-wrap-layout3 {
  background-color: #fff;
  padding: 120px 0px 105px 0px;
}

@media only screen and (max-width: 1199px) {
  .blog-wrap-layout3 {
    padding: 50px 0px;
  }
}

.blog-box2 {
  margin-bottom: 60px;
  position: relative;
}

.blog-box2:hover .item-img img {
  transform: scale(1.1);
}

.blog-box2:hover .content-box {
  transform: translateY(0);
}

.blog-box2 .item-img {
  overflow: hidden;
  border-radius: 4px;
}

.blog-box2 .item-img img {
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}

.blog-box2 .content-box {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border: none;
  width: 83%;
  padding: 15px 20px 0px 10px;
  transform: translateY(20px);
  transition: all 0.3s ease-in-out;
}

.blog-box2 .content-box:after {
  content: "";
  width: 60px;
  height: 60px;
  background: #da251e;
  position: absolute;
  top: -25px;
  right: -35px;
  transform: rotate(55deg);
  border: none;
  border-radius: 5px;
}

.blog-box2 .content-box .entry-meta {
  margin-bottom: 7px;
}

.blog-box2 .content-box .entry-meta ul li {
  display: inline-block;
  margin-right: 22px;
}

.blog-box2 .content-box .entry-meta ul li:last-child {
  margin-right: 0;
}

.blog-box2 .content-box .entry-meta ul li a {
  color: #646464;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.blog-box2 .content-box .entry-meta ul li a:hover {
  color: #da251e;
}

.blog-box2 .content-box .entry-meta ul li a i {
  color: #da251e;
  font-size: 14px;
  margin-right: 8px;
}

.blog-box2 .content-box .section-title {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 0;
}

@media only screen and (max-width: 1199px) {
  .blog-box2 .content-box .section-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .blog-box2 .content-box .section-title {
    font-size: 20px;
  }
}

.blog-box2 .content-box .section-title a {
  color: #000;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}

.blog-box2 .content-box .section-title a:hover {
  color: #da251e;
}

.blog-box2 .content-box .blog-date {
  position: absolute;
  top: -20px;
  right: -19px;
  z-index: 5;
  font-size: 24px;
  color: #fff;
  font-weight: 700;
}

.blog-box2 .content-box .blog-date span {
  font-size: 14px;
  display: block;
  margin-top: -3px;
  font-weight: 400;
}

.blog-box3 {
  margin-bottom: 230px;
  position: relative;
}

.blog-box3:hover .item-img img {
  transform: scale(1.1);
}

.blog-box3 .item-img {
  overflow: hidden;
  border-radius: 4px;
  position: relative;
}

.blog-box3 .item-img img {
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}

.blog-box3 .content-box {
  position: absolute;
  bottom: 0px;
  left: 0;
  background-color: #fff;
  border: none;
  width: 90%;
  padding: 18px 23px 0px 0px;
  transition: all 0.3s ease-in-out;
  transform: translateY(170px);
}

.blog-box3 .content-box:after {
  content: "";
  width: 70px;
  height: 70px;
  background: #da251e;
  position: absolute;
  top: -25px;
  right: -35px;
  transform: rotate(55deg);
  border: none;
  border-radius: 5px;
}

@media only screen and (max-width: 479px) {
  .blog-box3 .content-box:after {
    width: 60px;
    height: 60px;
    top: -20px;
    right: -20px;
  }
}

.blog-box3 .content-box .entry-meta {
  margin-bottom: 7px;
}

.blog-box3 .content-box .entry-meta ul li {
  display: inline-block;
  margin-right: 22px;
}

.blog-box3 .content-box .entry-meta ul li:last-child {
  margin-right: 0;
}

.blog-box3 .content-box .entry-meta ul li a {
  color: #646464;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.blog-box3 .content-box .entry-meta ul li a:hover {
  color: #da251e;
}

.blog-box3 .content-box .entry-meta ul li a i {
  color: #da251e;
  font-size: 14px;
  margin-right: 8px;
}

.blog-box3 .content-box .section-title {
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 12px;
}

@media only screen and (max-width: 479px) {
  .blog-box3 .content-box .section-title {
    font-size: 20px;
  }
}

.blog-box3 .content-box .section-title a {
  color: #000;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}

.blog-box3 .content-box .section-title a:hover {
  color: #da251e;
}

.blog-box3 .content-box .blog-date {
  position: absolute;
  top: -13px;
  right: -13px;
  z-index: 5;
  font-size: 24px;
  color: #fff;
  font-weight: 700;
}

@media only screen and (max-width: 479px) {
  .blog-box3 .content-box .blog-date {
    right: -5px;
  }
}

.blog-box3 .content-box .blog-date span {
  font-size: 14px;
  display: block;
  margin-top: -3px;
  font-weight: 400;
}

.blog-box3 p {
  margin-bottom: 30px;
}

@media only screen and (max-width: 767px) {
  .blog-box3 p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.blog-box3 .blog-button .blog-btn {
  color: #646464;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #dfdfdf;
  border-radius: 30px;
  padding: 15px 45px 15px 25px;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.blog-box3 .blog-button .blog-btn:hover {
  color: #fff;
  background-color: #da251e;
  border-color: #da251e;
}

.blog-box3 .blog-button .blog-btn:hover i {
  color: #fff;
  margin-left: 10px;
}

.blog-box3 .blog-button .blog-btn i {
  margin-left: 7px;
  color: #da251e;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 18px;
}

.single-blog-wrap1 {
  padding: 120px 0px 130px 0px;
}

@media only screen and (max-width: 1199px) {
  .single-blog-wrap1 {
    padding: 50px 0px;
  }
}

.single-blog-box1 .big-item-img {
  margin-bottom: 20px;
}

.single-blog-box1 .entry-meta {
  margin-bottom: 23px;
}

.single-blog-box1 .entry-meta ul li {
  display: inline-block;
  margin-right: 22px;
  color: #646464;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}

.single-blog-box1 .entry-meta ul li i {
  color: #da251e;
  font-size: 14px;
  margin-right: 8px;
}

.single-blog-box1 .entry-meta ul li:last-child {
  margin-right: 0;
}

.single-blog-box1 .single-para {
  margin-bottom: 30px;
}

.single-blog-box1 .quotetion-box {
  background-color: #f7f7f7;
  padding: 25px 40px 12px 45px;
  border: none;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  margin-bottom: 35px;
  margin-left: 40px;
}

@media only screen and (max-width: 1199px) {
  .single-blog-box1 .quotetion-box {
    margin-left: 0px;
  }
}

.single-blog-box1 .quotetion-box:after {
  content: "";
  height: 138px;
  width: 3px;
  background-color: #da251e;
  position: absolute;
  bottom: 0;
  left: 0;
}

.single-blog-box1 .quotetion-box .item-quote2 {
  color: #444;
  font-size: 17px;
  font-weight: 600;
  font-style: italic;
  line-height: 28px;
}

@media only screen and (max-width: 767px) {
  .single-blog-box1 .quotetion-box .item-quote2 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media only screen and (max-width: 479px) {
  .single-blog-box1 .quotetion-box .item-quote2 {
    font-size: 15px;
  }
}

.single-blog-box1 .quotetion-box .quotetion-icon2 {
  font-size: 72px;
  color: #e5e5e5;
  position: absolute;
  top: -5px;
  left: 20px;
  z-index: -1;
}

@media only screen and (max-width: 479px) {
  .single-blog-box1 .quotetion-box .quotetion-icon2 {
    font-size: 50px;
  }
}

.single-blog-box1 .single-about {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 42px;
}

.single-blog-box1 .single-about .single-about-img {
  position: relative;
  z-index: -5;
}

.single-blog-box1 .single-about .single-about-img img {
  border-radius: 0px 50px 0px 50px;
}

@media only screen and (max-width: 991px) {
  .single-blog-box1 .single-about .single-about-img img {
    margin-bottom: 20px;
  }
}

.single-blog-box1 .single-about .background-image {
  position: absolute;
  bottom: -50px;
  left: -50px;
  opacity: 15%;
  z-index: -5;
}

@media only screen and (max-width: 1199px) {
  .single-blog-box1 .single-about .background-image {
    display: none;
  }
}

.single-blog-box1 .single-about .blog-body {
  margin-left: 10px;
}

.single-blog-box1 .single-about .blog-body .heading-title {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  margin-bottom: 12px;
}

@media only screen and (max-width: 1199px) {
  .single-blog-box1 .single-about .blog-body .heading-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .single-blog-box1 .single-about .blog-body .heading-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.single-blog-box1 .single-about .blog-body .blog-para1 {
  margin-bottom: 12px;
}

@media only screen and (max-width: 1199px) {
  .single-blog-box1 .single-about .blog-body .blog-para1 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.single-blog-box1 .single-about .blog-body .single-list {
  margin-bottom: 15px;
}

.single-blog-box1 .single-about .blog-body .single-list ul li {
  color: #000;
  font-weight: 500;
}

@media only screen and (max-width: 1199px) {
  .single-blog-box1 .single-about .blog-body .single-list ul li {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.single-blog-box1 .single-about .blog-body .single-list ul li i {
  color: #da251e;
  font-size: 11px;
  margin-right: 10px;
}

.single-blog-box1 .blog-social-area {
  padding: 40px 0px;
  border-bottom: 1px solid #e5e5e5;
}

@media only screen and (max-width: 575px) {
  .single-blog-box1 .blog-social-area {
    text-align: center;
    display: block;
  }
}

.single-blog-box1 .blog-social-area .single-post-tag .item-tag li {
  display: inline-block;
}

.single-blog-box1 .blog-social-area .single-post-tag .item-tag li a {
  color: #444;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 10px;
  border: none;
  border-radius: 4px;
  background-color: #f2f2f2;
  transition: all 0.3s ease-in-out;
}

.single-blog-box1 .blog-social-area .single-post-tag .item-tag li a:hover {
  color: #fff;
  background-color: #da251e;
}

@media only screen and (max-width: 1199px) {
  .single-blog-box1
    .blog-social-area
    .single-post-tag
    .item-tag
    li
    .investment-btn {
    display: none;
  }
}

.single-blog-box1 .blog-social-area .single-post-tag .item-tag li span {
  color: #000;
  font-weight: 700;
  font-size: 18px;
  margin-right: 15px;
  font-family: "Barlow", sans-serif;
}

@media only screen and (max-width: 767px) {
  .single-blog-box1 .blog-social-area .single-post-tag .item-tag li span {
    margin-right: 5px;
  }
}

.single-blog-box1 .blog-social-area .blog-social {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 575px) {
  .single-blog-box1 .blog-social-area .blog-social {
    text-align: center;
    display: block;
    margin-top: 20px;
  }
}

.single-blog-box1 .blog-social-area .blog-social li {
  display: inline-block;
}

.single-blog-box1 .blog-social-area .blog-social li a {
  color: #646464;
  font-size: 18px;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  display: inline-block;
}

.single-blog-box1 .blog-social-area .blog-social li a i {
  margin-right: 10px;
}

.single-blog-box1 .blog-social-area .blog-social li a:hover {
  color: #da251e;
}

.single-blog-box1 .blog-social-area .blog-social li span {
  color: #000;
  font-weight: 700;
  font-size: 18px;
  margin-right: 15px;
  font-family: "Barlow", sans-serif;
}

@media only screen and (max-width: 767px) {
  .single-blog-box1 .blog-social-area .blog-social li span {
    margin-right: 5px;
  }
}

.single-blog-box1 .blog-author .media {
  padding: 30px 30px 30px 30px;
  background-color: #f8f8f8;
}

@media only screen and (max-width: 767px) {
  .single-blog-box1 .blog-author .media {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .single-blog-box1 .blog-author .media .item-img {
    text-align: center;
    margin-bottom: 20px;
  }
}

.single-blog-box1 .blog-author .media .media-body {
  margin-left: 25px;
  margin-top: -7px;
}

@media only screen and (max-width: 767px) {
  .single-blog-box1 .blog-author .media .media-body {
    margin-left: 0;
    text-align: center;
  }
}

.single-blog-box1 .blog-author .media .media-body .item-subtitle {
  color: #7b7a7a;
  font-size: 16px;
  line-height: 30px;
}

.single-blog-box1 .blog-author .media .media-body .item-title {
  color: #da251e;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 3px;
}

.single-blog-box1 .blog-author .media .media-body p {
  color: #646464;
  line-height: 24px;
  padding-right: 20px;
  margin-bottom: 0;
}

.single-blog-box1 .blog-comment {
  padding: 30px 0px 0px 0px;
}

.single-blog-box1 .blog-comment .heading-layout {
  margin-bottom: 52px;
}

@media only screen and (max-width: 575px) {
  .single-blog-box1 .blog-comment .heading-layout {
    text-align: center;
  }
}

.single-blog-box1 .blog-comment .heading-layout h4 {
  color: #000;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
}

.single-blog-box1 .blog-comment .heading-layout span {
  color: #000;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  position: relative;
  padding-bottom: 23px;
}

.single-blog-box1 .blog-comment .heading-layout span:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 10px;
  border: none;
}

.single-blog-box1 .blog-comment .heading-layout span:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 0px;
  left: 20px;
  border-radius: 10px;
  border: none;
}

.single-blog-box1 .blog-comment .media {
  border-bottom: 1px solid #e1e1e1;
  line-height: 30px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 575px) {
  .single-blog-box1 .blog-comment .media {
    display: block;
  }
}

.single-blog-box1 .blog-comment .media:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.single-blog-box1 .blog-comment .media:nth-child(odd) {
  padding-left: 40px;
}

@media only screen and (max-width: 479px) {
  .single-blog-box1 .blog-comment .media:nth-child(odd) {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .single-blog-box1 .blog-comment .media .item-img {
    text-align: center;
  }
}

.single-blog-box1 .blog-comment .media .item-img img {
  border-radius: 50%;
}

.single-blog-box1 .blog-comment .media .media-body {
  margin-left: 15px;
}

@media only screen and (max-width: 575px) {
  .single-blog-box1 .blog-comment .media .media-body {
    margin-left: 0;
    text-align: center;
  }
}

.single-blog-box1 .blog-comment .media .media-body .item-title {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 0;
}

.single-blog-box1 .blog-comment .media .media-body .item-date {
  color: #646464;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 7px;
}

.single-blog-box1 .blog-comment .media .media-body p {
  margin-bottom: 7px;
  color: #646464;
  line-height: 30px;
}

.single-blog-box1 .blog-comment .media .media-body .reply-btn {
  color: #da251e;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  line-height: 30px;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
}

.single-blog-box1 .blog-comment .media .media-body .reply-btn:hover {
  color: #646464;
}

.single-blog-box1 .blog-comment .media .media-body .reply-btn:hover i {
  color: #da251e;
}

.single-blog-box1 .blog-comment .media .media-body .reply-btn i {
  color: #646464;
  margin-right: 10px;
  font-size: 14px;
  line-height: 30px;
  transition: all 0.3s ease-in-out;
}

.single-blog-box1 .blog-reply {
  padding: 10px 0px 0px 0px;
}

.single-blog-box1 .blog-reply .item-title {
  position: relative;
  font-weight: 700;
  font-size: 22px;
  padding-bottom: 10px;
  margin-bottom: 26px;
}

.single-blog-box1 .blog-reply .item-title:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 10px;
  border: none;
}

.single-blog-box1 .blog-reply .item-title:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 0px;
  left: 20px;
  border-radius: 10px;
  border: none;
}

.single-blog-box1 .blog-reply .comments-box .form-group .form-control {
  color: #000;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.single-blog-box1 .blog-reply .comments-box .form-group .form-control:focus {
  box-shadow: none;
  border-color: #da251e;
}

.single-blog-box1 .blog-reply .comments-box .form-group input {
  height: 54px;
}

.single-blog-box1 .blog-reply .comments-box .form-group {
  margin-bottom: 7px;
}

.single-blog-box1 .blog-reply .comments-box .form-group:last-child {
  margin-bottom: 0;
}

.single-blog-box1 .blog-reply .comments-box .form-button .item-btn {
  margin-top: 10px;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background: #da251e;
  padding: 13px 34px;
  border: none;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}

.single-blog-box1 .blog-reply .comments-box .form-button .item-btn:after {
  content: "";
  height: 100%;
  width: 0;
  color: #fff;
  background-color: transparent;
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
  border-radius: 4px;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.single-blog-box1 .blog-reply .comments-box .form-button .item-btn:hover {
  color: #da251e;
  background-color: #f8f8f8;
}

/*------------------- 5.5 Brand -------------------*/
.brand-wrap-layout1 {
  padding: 65px 0px 55px 0px;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .brand-wrap-layout1 {
    padding: 50px 0px 45px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .brand-wrap-layout1 {
    padding: 50px 0px 15px 0px;
  }
}

.brand-wrap-layout2 {
  padding: 65px 0px 55px 0px;
  background-color: #f9f9f9;
}

@media only screen and (max-width: 1199px) {
  .brand-wrap-layout2 {
    padding: 50px 0px 45px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .brand-wrap-layout2 {
    padding: 40px 0px 22px 0px;
  }
}

.background-shape6 {
  position: absolute;
  top: 0;
  right: 0;
}

.background-shape7 {
  position: absolute;
  bottom: 0;
  left: 0;
}

.brand-box1 {
  text-align: center;
}

@media only screen and (max-width: 991px) {
  .brand-box1 {
    margin-bottom: 35px;
  }
}

.brand-box1:hover .brand-img a img {
  filter: grayscale(0);
  opacity: 100%;
}

.brand-box1 .brand-img {
  display: inline-block;
  width: auto;
}

.brand-box1 .brand-img a img {
  filter: grayscale(100%);
  opacity: 20%;
  transition: all 0.3s ease-in-out;
}

/*------------------- 5.6 Breadcrumb -------------------*/
.breadcrumb-wrap {
  /* background-image: url("../img/breadcrumb1.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.breadcrumb-img1 {
  position: absolute;
  bottom: 0;
  left: 113px;
}

@media only screen and (max-width: 1439px) {
  .breadcrumb-img1 {
    display: none;
  }
}

.breadcrumb-img2 {
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 1439px) {
  .breadcrumb-img2 {
    display: none;
  }
}

.breadcrumb-box {
  padding: 80px 0px;
}

@media only screen and (max-width: 1439px) {
  .breadcrumb-box {
    padding: 32px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .breadcrumb-box {
    padding: 18px 0px 8px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-box {
    padding: 0px;
  }
}

.breadcrumb-box .item-img {
  position: relative;
}

.breadcrumb-box .page-title {
  color: #fff;
  font-size: 46px;
  font-weight: 700;
  line-height: 55px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1199px) {
  .breadcrumb-box .page-title {
    font-size: 36px;
    line-height: 46px;
  }
}

@media only screen and (max-width: 479px) {
  .breadcrumb-box .page-title {
    font-size: 30px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 320px) {
  .breadcrumb-box .page-title {
    font-size: 24px;
    line-height: 34px;
  }
}

.breadcrumb-box .breadcrumb {
  background-color: transparent;
  padding: 0px;
}

.breadcrumb-box .breadcrumb .breadcrumb-item {
  font-size: 14px;
  position: relative;
  margin-right: 15px;
  font-weight: 500;
}

.breadcrumb-box .breadcrumb .breadcrumb-item:last-child {
  margin-right: 0;
}

.breadcrumb-box .breadcrumb .breadcrumb-item:after {
  content: "-";
  height: 100%;
  width: 100%;
  color: #fff;
  background-color: transparent;
  position: absolute;
  bottom: 0;
  padding-left: 10px;
}

@media only screen and (max-width: 479px) {
  .breadcrumb-box .breadcrumb .breadcrumb-item:after {
    padding-left: 7px;
  }
}

.breadcrumb-box .breadcrumb .breadcrumb-item:before {
  display: none;
}

.breadcrumb-box .breadcrumb .breadcrumb-item:last-child:after {
  display: none;
}

@media only screen and (max-width: 991px) {
  .breadcrumb-box .breadcrumb .breadcrumb-item {
    padding-left: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .breadcrumb-box .breadcrumb .breadcrumb-item {
    padding-left: 3px;
  }
}

@media only screen and (max-width: 575px) {
  .breadcrumb-box .breadcrumb .breadcrumb-item {
    padding-left: 0;
  }
}

.breadcrumb-box .breadcrumb .breadcrumb-item a {
  color: #969696;
  transition: all 0.3s ease-in-out;
}

.breadcrumb-box .breadcrumb .breadcrumb-item a:hover {
  color: #da251e;
}

.breadcrumb-box .breadcrumb .breadcrumb-item.active {
  color: #da251e;
}

/*------------------- 5.7 Call-back -------------------*/
.call-back-wrap {
  transform: translateY(120px);
  position: relative;
  z-index: 3;
}

@media only screen and (max-width: 1439px) {
  .call-back-wrap {
    transform: translateY(55px);
  }
}

@media only screen and (max-width: 479px) {
  .call-back-wrap {
    transform: translateY(0px);
    padding: 50px 0px 0px 0px;
  }
}

.call-back-box1 {
  background-color: #1a1a1a;
  padding: 52px 60px 61px 60px;
}

@media only screen and (max-width: 1199px) {
  .call-back-box1 {
    padding: 5px 10px 10px 10px;
  }
}

@media only screen and (max-width: 991px) {
  .call-back-box1 {
    padding: 52px 60px 60px 60px;
  }
}

@media only screen and (max-width: 767px) {
  .call-back-box1 {
    padding: 50px 50px 50px 50px;
  }
}

@media only screen and (max-width: 575px) {
  .call-back-box1 {
    padding: 50px 30px 50px 30px;
  }
}

.call-back-box1 .section-title {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .call-back-box1 .section-title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 991px) {
  .call-back-box1 .section-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .call-back-box1 .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 320px) {
  .call-back-box1 .section-title {
    font-size: 22px;
  }
}

.call-back-box1 .section-title:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 0px;
  left: 0;
  border-radius: 10px;
  border: none;
}

.call-back-box1 .section-title:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 0;
  left: 21px;
  border-radius: 10px;
  border: none;
}

.call-back-box1 .message-box .form-group .form-control {
  color: #fff;
  background-color: #3b3b3b;
  border-color: #3b3b3b;
}

.call-back-box1 .message-box .form-group .form-control:focus {
  box-shadow: none;
  border-color: #da251e;
}

.call-back-box1 .message-box .form-group input {
  height: 51px;
}

@media only screen and (max-width: 767px) {
  .call-back-box1 .message-box .form-group input {
    height: 45px;
  }
}

.call-back-box1
  .message-box
  .form-group
  input.form-control::-webkit-input-placeholder,
.call-back-box1
  .message-box
  .form-group
  .form-control::-webkit-input-placeholder {
  color: #a3a3a3;
}

.call-back-box1 .message-box .form-group {
  margin-bottom: 8px;
}

.call-back-box1 .message-box .form-group:last-child {
  margin-bottom: 0;
}

.call-back-box1 .message-box .form-group .item-btn {
  margin-top: 8px;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background: #da251e;
  padding: 13px 34px;
  border: none;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}

.call-back-box1 .message-box .form-group .item-btn:after {
  content: "";
  height: 100%;
  width: 0;
  color: #fff;
  background-color: transparent;
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
  border-radius: 4px;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.call-back-box1 .message-box .form-group .item-btn:hover {
  color: #da251e;
  background-color: #fff;
}

.call-back-box2 .item-img {
  position: relative;
}

.call-back-box2 .call-img {
  position: absolute;
  bottom: 0;
  right: 0px;
}

@media only screen and (max-width: 479px) {
  .call-back-box2 .call-img {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .call-back-wrap2 {
    transform: translateY(0px);
    padding: 20px 0px 50px 0px;
  }
}

@media only screen and (max-width: 575px) {
  .call-back-wrap2 {
    padding: 0px 0px 50px 0px;
  }
}

/*------------------- 5.8 Case -------------------*/
.case-wrap-layout1 {
  padding: 130px 0px 100px 0px;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .case-wrap-layout1 {
    padding: 40px 0px 20px 0px;
  }
}

.case-wrap-layout1 .background-image {
  position: absolute;
  top: 230px;
  right: 160px;
  animation: animate-figure1 infinite 3s;
}

@media only screen and (max-width: 1199px) {
  .case-wrap-layout1 .background-image {
    display: none;
  }
}

.case-box1 .section-subtitle {
  color: #444444;
  font-size: 18px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  position: relative;
  margin-bottom: 10px;
}

.case-box1 .section-subtitle:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 12px;
  left: 117px;
  border-radius: 10px;
  border: none;
}

.case-box1 .section-subtitle:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 12px;
  left: 138px;
  border-radius: 10px;
  border: none;
}

.case-box1 .section-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 25px;
}

@media only screen and (max-width: 1199px) {
  .case-box1 .section-title {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .case-box1 .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.case-box1 p {
  margin-bottom: 25px;
  padding-right: 20px;
}

@media only screen and (max-width: 1199px) {
  .case-box1 p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .case-box1 p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.case-box2 {
  overflow: hidden;
  margin-bottom: 30px;
}

.case-box2:hover .case-img:before {
  visibility: visible;
  opacity: 1;
  height: 100%;
}

.case-box2:hover .item-content {
  visibility: visible;
  opacity: 1;
  padding: 30px 0px 15px 40px;
}

@media only screen and (max-width: 1199px) {
  .case-box2:hover .item-content {
    padding: 0px 0px 40px 20px;
  }
}

@media only screen and (max-width: 991px) {
  .case-box2:hover .item-content {
    padding: 20px 0px 0px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .case-box2:hover .item-content {
    padding: 20px 0px 30px 20px;
  }
}

@media only screen and (max-width: 575px) {
  .case-box2:hover .item-content {
    padding: 20px 0px 10px 30px;
  }
}

@media only screen and (max-width: 320px) {
  .case-box2:hover .item-content {
    padding: 0px 0px 30px 20px;
  }
}

.case-box2:hover .item-shape-box {
  visibility: visible;
  opacity: 1;
}

.case-box2 .case-img {
  position: relative;
}

.case-box2 .case-img:before {
  content: "";
  height: 0;
  width: 100%;
  background-image: linear-gradient(to top, #da251e, transparent);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.case-box2 .item-content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.case-box2 .item-content .case-subtitle {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
}

.case-box2 .item-content .case-title {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 767px) {
  .case-box2 .item-content .case-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .case-box2 .item-content .case-title {
    font-size: 22px;
  }
}

.case-box2 .item-shape-box {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.case-box2 .item-shape-box .item-shape {
  position: relative;
}

.case-box2 .item-shape-box .item-shape:after {
  content: "";
  height: 114px;
  width: 104px;
  background-color: #fff;
  border: 50px solid transparent;
  border-radius: 6px;
  position: absolute;
  bottom: -80px;
  right: -3px;
  transform: rotate(132deg);
}

.case-box2 .item-shape-box .item-icon {
  font-size: 20px;
  position: absolute;
  bottom: 0;
  right: 40px;
  z-index: 5;
  transform: rotate(-98deg);
}

.case-box2 .item-shape-box .item-icon a {
  color: #000;
  transition: all 0.3s ease-in-out;
}

.case-box2 .item-shape-box .item-icon a:hover {
  color: #da251e;
}

/*------------------- 5.9 Company value -------------------*/
.company-value-wrap {
  padding: 34px 0px 120px 0px;
  color: #fff;
}

@media only screen and (max-width: 1199px) {
  .company-value-wrap {
    padding: 34px 0px 50px 0px;
  }
}

.company-value-wrap .item-content {
  text-align: center;
}

.company-value-wrap .item-content .section-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1199px) {
  .company-value-wrap .item-content .section-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .company-value-wrap .item-content .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.company-value-wrap .item-content p {
  color: #646464;
  width: 42%;
  margin: 0 auto;
  margin-bottom: 0;
}

@media only screen and (max-width: 1199px) {
  .company-value-wrap .item-content p {
    width: 100%;
  }
}

.company-value-box {
  margin-top: 40px;
}

.company-value-box .media {
  background-color: #f8f8f8;
  border: none;
  border-radius: 4px;
}

@media only screen and (max-width: 479px) {
  .company-value-box .media {
    display: block;
  }
}

.company-value-box .media:hover .item-img img {
  transform: translateY(-20px);
}

@media only screen and (max-width: 479px) {
  .company-value-box .media:hover .item-img img {
    transform: translateY(0px);
  }
}

@media only screen and (max-width: 479px) {
  .company-value-box .media .item-img {
    text-align: center;
  }
}

.company-value-box .media .item-img img {
  transition: all 0.3s ease-in-out;
}

.company-value-box .media .media-body {
  margin-left: 30px;
  padding: 40px 10px 30px 0px;
  position: relative;
}

@media only screen and (max-width: 479px) {
  .company-value-box .media .media-body {
    margin-left: 0;
    padding: 30px 10px 30px 0px;
    text-align: center;
  }
}

.company-value-box .media .media-body .heading-title {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1199px) {
  .company-value-box .media .media-body .heading-title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .company-value-box .media .media-body .heading-title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .company-value-box .media .media-body .heading-title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 479px) {
  .company-value-box .media .media-body .heading-title {
    font-size: 22px;
    line-height: 30px;
  }
}

.company-value-box .media .media-body p {
  margin-bottom: 30px;
  color: #646464;
  font-weight: 500;
}

@media only screen and (max-width: 1199px) {
  .company-value-box .media .media-body p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.company-value-box .media .media-body .item-button .item-btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #575757;
  padding: 9px 42px 9px 20px;
  border: 1px solid #d3d3d3;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  text-align: center;
}

.company-value-box .media .media-body .item-button .item-btn:hover {
  background-color: #da251e;
  border-color: #da251e;
  color: #fff;
}

.company-value-box .media .media-body .item-button .item-btn:hover i {
  color: #fff;
  right: 14px;
}

.company-value-box .media .media-body .item-button .item-btn i {
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 16px;
  right: 16px;
  color: #da251e;
}

.company-value-box .media .media-body .item-number {
  position: absolute;
  bottom: 50px;
  right: 40px;
  color: #efefef;
  font-size: 60px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
}

@media only screen and (max-width: 1199px) {
  .company-value-box .media .media-body .item-number {
    display: none;
  }
}

/*------------------- 5.10 Consulting -------------------*/
.consulting-service-wrap1 {
  /* "../img/figure/figure16.png" */
  background-image: url(../src/assets/img/figure/figure16.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 130px 0px 100px 0px;
}

@media only screen and (max-width: 1199px) {
  .consulting-service-wrap1 {
    padding: 42px 0px 20px 0px;
  }
}

.consulting-service-wrap1 .section-subtitle {
  color: #da251e;
  font-size: 18px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  position: relative;
  margin-bottom: 10px;
}

.consulting-service-wrap1 .section-subtitle:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 12px;
  left: 108px;
  border-radius: 10px;
  border: none;
}

.consulting-service-wrap1 .section-subtitle:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 12px;
  left: 130px;
  border-radius: 10px;
  border: none;
}

.consulting-service1 .section-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 40px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1199px) {
  .consulting-service1 .section-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .consulting-service1 .section-title {
    margin-bottom: 17px;
  }
}

@media only screen and (max-width: 479px) {
  .consulting-service1 .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 320px) {
  .consulting-service1 .section-title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1199px) {
  .consulting-service2 p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.consulting-service3 {
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.consulting-service3 .media {
  padding: 40px 50px 50px 50px;
  margin-bottom: 30px;
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 575px) {
  .consulting-service3 .media {
    padding: 30px 30px 30px 30px;
  }
}

@media only screen and (max-width: 479px) {
  .consulting-service3 .media {
    display: block;
    padding-bottom: 30px;
    padding-top: 30px;
    text-align: center;
  }
}

.consulting-service3 .media:hover .service-img {
  visibility: visible;
  opacity: 1;
}

.consulting-service3 .media:hover .media-body .title-heading a {
  color: #fff;
}

.consulting-service3 .media:hover .media-body p {
  color: #fff;
}

.consulting-service3 .media:hover .media-body .item-button .item-btn {
  background-color: #da251e;
  color: #fff;
  border-color: #da251e;
}

.consulting-service3 .media:hover .media-body .item-button .item-btn i {
  color: #fff;
}

.consulting-service3 .media:after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
  z-index: -5;
  opacity: 100%;
  transition: all 0.5s ease-in-out;
}

.consulting-service3 .media .item-img {
  position: relative;
}

@media only screen and (max-width: 479px) {
  .consulting-service3 .media .item-img {
    margin-bottom: 20px;
  }
}

.consulting-service3 .media .service-img {
  position: absolute;
  bottom: 1px;
  left: 1px;
  right: 1px;
  top: 1px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.consulting-service3 .media .service-img2 {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.consulting-service3 .media .service-img3 {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.consulting-service3 .media .service-img4 {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.consulting-service3 .media .media-body {
  margin-left: 30px;
}

@media only screen and (max-width: 479px) {
  .consulting-service3 .media .media-body {
    margin-left: 10px;
  }
}

.consulting-service3 .media .media-body .title-heading {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
}

@media only screen and (max-width: 479px) {
  .consulting-service3 .media .media-body .title-heading {
    font-size: 20px;
  }
}

.consulting-service3 .media .media-body .title-heading a {
  color: #000;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

.consulting-service3 .media .media-body p {
  transition: all 0.3s ease-in-out;
  font-weight: 500;
}

@media only screen and (max-width: 1199px) {
  .consulting-service3 .media .media-body p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.consulting-service3 .media .media-body .item-button .item-btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #444;
  background: transparent;
  padding: 10px 52px 10px 30px;
  border: 1px solid #d3d3d3;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  position: relative;
  text-decoration: none;
}

@media only screen and (max-width: 479px) {
  .consulting-service3 .media .media-body .item-button .item-btn {
    padding: 10px 40px 10px 20px;
  }
}

.consulting-service3 .media .media-body .item-button .item-btn:hover {
  opacity: 90%;
}

.consulting-service3 .media .media-body .item-button .item-btn:hover i {
  margin-left: 12px;
}

.consulting-service3 .media .media-body .item-button .item-btn i {
  margin-left: 10px;
  transition: all 0.3s ease-in-out;
  color: #da251e;
  position: absolute;
  top: 16px;
}

@media only screen and (max-width: 479px) {
  .consulting-service3 .media .media-body .item-button .item-btn i {
    margin-left: 5px;
  }
}

.consulting-service3:hover .media:after {
  opacity: 0;
  background: none;
}

.consulting-service-wrap2 {
  /* background-image: url(../img/team/team4.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 5;
  padding: 130px 0px 100px 0px;
  overflow: hidden;
}

@media only screen and (max-width: 1199px) {
  .consulting-service-wrap2 {
    padding: 43px 0px 50px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .consulting-service-wrap2 {
    padding: 43px 0px 20px 0px;
  }
}

.consulting-service-wrap2:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 80%;
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: -5;
}

.consulting-service-wrap2 .section-subtitle {
  color: #c1c1c1;
  font-size: 18px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  position: relative;
  margin-bottom: 10px;
}

.consulting-service-wrap2 .section-subtitle:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 12px;
  left: 107px;
  border-radius: 10px;
  border: none;
}

.consulting-service-wrap2 .section-subtitle:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 12px;
  left: 127px;
  border-radius: 10px;
  border: none;
}

.consulting-service-wrap2 .background-image1 {
  position: absolute;
  bottom: 0;
  left: 0;
}

@media only screen and (max-width: 1199px) {
  .consulting-service-wrap2 .background-image1 {
    display: none;
  }
}

.consulting-service-wrap2 .background-image2 {
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;
}

@media only screen and (max-width: 1199px) {
  .consulting-service-wrap2 .background-image2 {
    display: none;
  }
}

.consulting-service-wrap4 {
  padding: 110px 0px 70px 0px;
  overflow: hidden;
  background-color: #fff;
}

@media only screen and (max-width: 1199px) {
  .consulting-service-wrap4 {
    padding: 40px 0px 30px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .consulting-service-wrap4 {
    padding: 40px 0px 20px 0px;
  }
}

.consulting-service-wrap3 {
  padding: 100px 0px 220px 0px;
}

@media only screen and (max-width: 1199px) {
  .consulting-service-wrap3 {
    padding: 50px 0px 20px 0px;
  }
}

.consulting-top1 .section-title {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 40px;
  line-height: 46px;
}

@media only screen and (max-width: 1199px) {
  .consulting-top1 .section-title {
    font-size: 30px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .consulting-top1 .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.consulting-top2 p {
  color: #c0c0c0;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  .consulting-top2 p {
    margin-bottom: 30px;
  }
}

.consulting-top3 .section-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1199px) {
  .consulting-top3 .section-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .consulting-top3 .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 320px) {
  .consulting-top3 .section-title {
    font-size: 22px;
  }
}

.consulting-top4 p {
  color: #646464;
}

.consulting-service4 {
  background-color: rgba(26, 26, 26, 0.5);
  padding: 40px 40px 25px 40px;
  border: none;
  text-align: center;
  position: relative;
  transition: all 0.5s ease-in-out;
}

@media only screen and (max-width: 991px) {
  .consulting-service4 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .consulting-service4 {
    padding: 43px 25px 25px 25px;
  }
}

.consulting-service4:hover {
  background-color: #0e0e0e;
}

.consulting-service4:hover:after {
  visibility: visible;
  opacity: 1;
}

.consulting-service4:hover .consulting-button .consulting-btn {
  visibility: visible;
  opacity: 1;
}

.consulting-service4:after {
  content: "";
  height: 4px;
  width: 100%;
  background-color: #da251e;
  position: absolute;
  bottom: -2px;
  left: 0;
  z-index: 3;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.consulting-service4 .heading-title {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .consulting-service4 .heading-title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 991px) {
  .consulting-service4 .heading-title {
    font-size: 22px;
  }
}

.consulting-service4 .item-img {
  margin-bottom: 20px;
}

.consulting-service4 p {
  color: #aeaeae;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.consulting-button {
  position: absolute;
  bottom: -80px;
  left: 35%;
  transform: translateY(-50%);
  z-index: 5;
}

@media only screen and (max-width: 1199px) {
  .consulting-button {
    left: 32%;
    bottom: -80px;
  }
}

@media only screen and (max-width: 991px) {
  .consulting-button {
    left: 38%;
    bottom: -80px;
  }
}

@media only screen and (max-width: 767px) {
  .consulting-button {
    left: 40%;
  }
}

@media only screen and (max-width: 320px) {
  .consulting-button {
    left: 38%;
  }
}

.consulting-button .consulting-btn {
  background-color: #252525;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 60px;
  width: 60px;
  border: none;
  border-radius: 50%;
  font-size: 16px;
  z-index: 5;
  margin: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  position: relative;
}

.consulting-button .consulting-btn:hover {
  opacity: 90%;
}

.consulting-button .consulting-btn:hover i {
  margin-left: 15px;
}

.consulting-button .consulting-btn i {
  transition: all 0.3s ease-in-out;
}

.consulting-button .consulting-btn:after {
  content: "";
  height: 50px;
  width: 50px;
  background-color: #da251e;
  position: absolute;
  bottom: 4px;
  left: 5px;
  border: none;
  border-radius: 50%;
  z-index: -5;
}

.consulting-service5 {
  margin-bottom: 15px;
}

@media only screen and (max-width: 1199px) {
  .consulting-service5 {
    margin-bottom: 30px;
  }
}

.single-service-wrap1 {
  padding: 110px 0px 120px 0px;
}

@media only screen and (max-width: 1199px) {
  .single-service-wrap1 {
    padding: 40px 0px 50px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .single-service-wrap1 {
    padding: 0px 0px 50px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .single-service-box1 {
    margin-top: 40px;
  }
}

.single-service-box1 .section-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1199px) {
  .single-service-box1 .section-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .single-service-box1 .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.single-service-box1 .service-img {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 25px;
}

.single-service-box1 .background-image1 {
  position: absolute;
  bottom: -34px;
  right: 57px;
}

.single-service-box1 .background-image2 {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.single-service-box1 .item-paragraph {
  margin-bottom: 15px;
}

.single-service-box1 .service-list {
  margin-bottom: 35px;
}

.single-service-box1 .service-list li {
  color: #444444;
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin-bottom: 7px;
}

.single-service-box1 .service-list li:last-child {
  margin-bottom: 0;
}

.single-service-box1 .service-list li i {
  color: #fff;
  height: 18px;
  width: 18px;
  font-size: 10px;
  display: inline-block;
  text-align: center;
  line-height: 18px;
  background-color: #da251e;
  border-radius: 50%;
  border: none;
  margin-right: 10px;
}

@media only screen and (max-width: 991px) {
  .single-service-box1 .service-list3 {
    margin-bottom: 0;
  }
}

.single-service-box1 .research-area {
  margin-bottom: 40px;
}

.single-service-box1 .research-area p {
  margin-bottom: 30px;
}

.single-service-box1 .benefits-area p {
  margin-bottom: 30px;
}

.single-service-box1 .benefits-area .accordion .card {
  border: 1px solid #e5e5e5 !important;
  border-radius: 4px !important;
  margin-bottom: 12px;
}

.single-service-box1 .benefits-area .accordion .card:last-child {
  margin-bottom: 0;
}

.single-service-box1 .benefits-area .accordion .card .card-header {
  padding: 0;
  border-radius: 4px;
  background-color: #fff;
}

.single-service-box1
  .benefits-area
  .accordion
  .card
  .card-header
  .heading-title {
  position: relative;
  cursor: pointer;
  padding: 18px 70px 18px 50px;
  font-weight: 600;
  font-size: 20px;
  color: #da251e;
  border: none;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .single-service-box1
    .benefits-area
    .accordion
    .card
    .card-header
    .heading-title {
    font-size: 18px;
  }
}

.single-service-box1
  .benefits-area
  .accordion
  .card
  .card-header
  .heading-title:before {
  content: "\f309";
  font-family: "Font Awesome 5 Free";
  height: 100%;
  width: 68px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #da251e;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  text-align: center;
  line-height: 65px;
  border: none;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 320px) {
  .single-service-box1
    .benefits-area
    .accordion
    .card
    .card-header
    .heading-title:before {
    width: 40px;
    line-height: 90px;
  }
}

.single-service-box1
  .benefits-area
  .accordion
  .card
  .card-header
  .heading-title.collapsed {
  color: #000;
  transition: all 0.3s ease-in-out;
}

.single-service-box1
  .benefits-area
  .accordion
  .card
  .card-header
  .heading-title.collapsed:before {
  content: "\f30b";
  font-family: "Font Awesome 5 Free";
  height: 100%;
  width: 68px;
  font-size: 16px;
  font-weight: 600;
  color: #da251e;
  background-color: #f5f5f5;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  text-align: center;
  line-height: 65px;
  border: none;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 320px) {
  .single-service-box1
    .benefits-area
    .accordion
    .card
    .card-header
    .heading-title.collapsed:before {
    width: 40px;
    line-height: 90px;
  }
}

.single-service-box1 .benefits-area .accordion .card .card-body {
  padding: 20px 20px 25px 0px;
  padding-left: 40px;
  border-radius: 4px;
}

.single-service-box1 .benefits-area .accordion .card .card-body p {
  color: #646464;
}

.single-service-box1 .benefits-area .accordion .card:last-child {
  margin-bottom: 0;
}

.single-service-wrap2 {
  padding: 110px 0px 120px 0px;
}

@media only screen and (max-width: 1199px) {
  .single-service-wrap2 {
    padding: 40px 0px 50px 0px;
  }
}

.single-service-box2 .section-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1199px) {
  .single-service-box2 .section-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .single-service-box2 .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.single-service-box2 .section-subtitle {
  color: #444;
  font-size: 20px;
  font-weight: 700;
}

@media only screen and (max-width: 479px) {
  .single-service-box2 .section-subtitle {
    font-size: 18px;
  }
}

.single-service-box2 .service-img5 {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 25px;
  margin-top: 10px;
}

.single-service-box2 .background-image1 {
  position: absolute;
  bottom: -34px;
  right: 57px;
}

.single-service-box2 .background-image2 {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.single-service-box2 .service-list {
  margin-bottom: 35px;
}

@media only screen and (max-width: 1199px) {
  .single-service-box2 .service-list {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 991px) {
  .single-service-box2 .service-list {
    margin-bottom: 35px;
  }
}

.single-service-box2 .service-list li {
  color: #444444;
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin-bottom: 7px;
}

.single-service-box2 .service-list li:last-child {
  margin-bottom: 0;
}

.single-service-box2 .service-list li i {
  color: #fff;
  height: 18px;
  width: 18px;
  font-size: 10px;
  display: inline-block;
  text-align: center;
  line-height: 18px;
  background-color: #da251e;
  border-radius: 50%;
  border: none;
  margin-right: 10px;
}

.single-service-box2 .service-list2 li {
  display: inline-block;
  margin-right: 50px;
}

@media only screen and (max-width: 1199px) {
  .single-service-box2 .service-list2 li {
    margin-right: 14px;
  }
}

.single-service-box2 .service-list2 li:last-child {
  margin-right: 0;
}

.single-service-box2 .about-single-layout {
  padding: 40px 0px 60px 0px;
}

@media only screen and (max-width: 1199px) {
  .single-service-box2 .about-single-layout {
    padding: 40px 0px 50px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .single-service-box2 .about-single-layout {
    padding: 40px 0px;
  }
}

.single-service-box2 .about-single-layout .about-box6 .item-img {
  position: relative;
  z-index: 5;
}

.single-service-box2 .about-single-layout .about-box6 .item-img:after {
  content: "";
  height: 0px;
  width: 0px;
  position: absolute;
  top: 0px;
  left: -60px;
  transform: rotate(-47deg);
  filter: blur(4px);
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  border-bottom: 55px solid #da251e;
}

@media only screen and (max-width: 1199px) {
  .single-service-box2 .about-single-layout .about-box6 .item-img:after {
    display: none;
  }
}

.single-service-box2 .about-single-layout .about-box6 .item-img img {
  border-radius: 4px 50px 4px 50px;
}

@media only screen and (max-width: 991px) {
  .single-service-box2 .about-single-layout .about-box6 .item-img img {
    margin-bottom: 30px;
  }
}

.single-service-box2 .about-single-layout .about-box6 .background-image {
  position: absolute;
  bottom: -35px;
  left: -38px;
  opacity: 15%;
  z-index: -5;
}

.single-service-box2 .about-single-layout .about-box7 {
  margin-top: -10px;
}

@media only screen and (max-width: 1199px) {
  .single-service-box2 .about-single-layout .about-box7 {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .single-service-box2 .about-single-layout .about-box7 {
    margin-left: 0px;
  }
}

.single-service-box2 .about-single-layout .about-box7 .heading-title {
  color: #000;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 12px;
}

@media only screen and (max-width: 1199px) {
  .single-service-box2 .about-single-layout .about-box7 .heading-title {
    font-size: 20px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .single-service-box2 .about-single-layout .about-box7 .heading-title {
    font-size: 30px;
    line-height: 36px;
  }
}

@media only screen and (max-width: 479px) {
  .single-service-box2 .about-single-layout .about-box7 .heading-title {
    font-size: 24px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 320px) {
  .single-service-box2 .about-single-layout .about-box7 .heading-title {
    font-size: 23px;
  }
}

.single-service-box2 .about-single-layout .about-box7 p {
  color: #646464;
}

@media only screen and (max-width: 1199px) {
  .single-service-box2 .about-single-layout .about-box7 p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media only screen and (max-width: 991px) {
  .single-service-box2 .about-single-layout .about-box7 p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.single-service-box2 .about-single-layout .about-box7 .about-list2 li {
  color: #000;
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: 500;
}

@media only screen and (max-width: 1199px) {
  .single-service-box2 .about-single-layout .about-box7 .about-list2 li {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.single-service-box2
  .about-single-layout
  .about-box7
  .about-list2
  li:last-child {
  margin-bottom: 0;
}

.single-service-box2 .about-single-layout .about-box7 .about-list2 li i {
  color: #da251e;
  font-size: 10px;
  margin-right: 10px;
}

.single-service-box2 .tab-area {
  margin-bottom: 55px;
}

@media only screen and (max-width: 1199px) {
  .single-service-box2 .tab-area {
    margin-bottom: 50px;
  }
}

.single-service-box2 .tab-area .nav-tabs {
  border: none;
  transform: translateY(30px) translateX(50px);
}

@media only screen and (max-width: 1199px) {
  .single-service-box2 .tab-area .nav-tabs {
    transform: translateY(0px) translateX(0px);
  }
}

@media only screen and (max-width: 767px) {
  .single-service-box2 .tab-area .nav-tabs {
    display: block !important;
  }
}

/* .single-service-box2 .tab-area .nav-tabs .nav-item {
  margin-bottom: 0;
  margin-right: 10px;
} */

/* @media only screen and (max-width: 767px) {
  .single-service-box2 .tab-area .nav-tabs .nav-item {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.single-service-box2 .tab-area .nav-tabs .nav-item:last-child {
  margin-right: 0;
} */

/* .single-service-box2 .tab-area .nav-tabs .nav-item .nav-link {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background-color: #fff;
  padding: 14px 35px;
  font-size: 18px;
  font-weight: 700;
  color: #444444;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-right: 10px;
  position: relative;
} */

/* @media only screen and (max-width: 1199px) {
  .single-service-box2 .tab-area .nav-tabs .nav-item .nav-link {
    border-bottom: none;
  }
} */

/* @media only screen and (max-width: 767px) {
  .single-service-box2 .tab-area .nav-tabs .nav-item .nav-link {
    border: 1px solid #e6e6e6;
  }
}

.single-service-box2 .tab-area .nav-tabs .nav-item .nav-link:after {
  content: "";
  height: 0px;
  width: 0px;
  position: absolute;
  bottom: -8px;
  left: 80px;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-top: 16px solid #da251e;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
} */

@media only screen and (max-width: 767px) {
  /* .single-service-box2 .tab-area .nav-tabs .nav-item .nav-link:after {
    display: none;
  } */
}

/* .single-service-box2 .tab-area .nav-tabs .nav-item .nav-link:last-child {
  margin-right: 0;
} */

/* .single-service-box2 .tab-area .nav-tabs .nav-item .nav-link.active {
  background-color: #da251e;
  color: #fff;
  border-color: #da251e;
} */

/* .single-service-box2 .tab-area .nav-tabs .nav-item .nav-link.active:after {
  visibility: visible;
  opacity: 1;
} */

.single-service-box2 .tab-area .tab-content {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}

.single-service-box2 .tab-area .tab-content p {
  padding: 60px 45px 30px 45px;
}

@media only screen and (max-width: 1199px) {
  .single-service-box2 .tab-area .tab-content p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@media only screen and (max-width: 767px) {
  .single-service-box2 .tab-area .tab-content p {
    padding: 30px;
  }
}

/*------------------- 5.11 Contact -------------------*/
.contact-wrap1 {
  padding: 160px 0px 0px 0px;
}

@media only screen and (max-width: 1199px) {
  .contact-wrap1 {
    padding: 90px 0px 0px 0px;
  }
}

.contact-box1 {
  text-align: center;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 45px 40px 35px 40px;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .contact-box1 {
    margin-bottom: 50px;
  }
}

.contact-box1 .heading-title {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0;
}

@media only screen and (max-width: 320px) {
  .contact-box1 .heading-title {
    font-size: 16px;
  }
}

.contact-box1 .contact-list2 li {
  display: block;
  color: #646464;
}

@media only screen and (max-width: 1439px) {
  .contact-box4 .contact-list2 li {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.contact-icon {
  position: absolute;
  top: -13px;
  right: 46%;
}

.contact-icon i {
  font-size: 24px;
  color: #fff;
  position: relative;
  z-index: 5;
}

.contact-icon i:after {
  content: "";
  width: 62px;
  height: 62px;
  background: #da251e;
  border-radius: 4px;
  position: absolute;
  bottom: -20px;
  left: -20px;
  z-index: -5;
  transform: rotate(-135deg);
}

.contact-wrap2 {
  padding: 35px 0px 85px 0px;
}

@media only screen and (max-width: 1199px) {
  .contact-wrap2 {
    padding: 50px 0px 40px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .contact-wrap2 {
    padding-top: 0;
  }
}

@media only screen and (max-width: 991px) {
  .contact-box2 {
    margin-bottom: 30px;
  }
}

.contact-box2 .google-map {
  filter: grayscale(100%);
}

.contact-box3 {
  padding: 35px 50px 45px 50px;
  background-color: #f7f7f7;
  position: relative;
  border-radius: 4px;
}

@media only screen and (max-width: 575px) {
  .contact-box3 {
    padding: 20px 30px 30px 30px;
  }
}

.contact-box3 .contact-bg-image {
  position: absolute;
  bottom: 0;
  right: 0;
}

.contact-box3 .item-heading .item-title {
  font-size: 30px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0px;
}

@media only screen and (max-width: 575px) {
  .contact-box3 .item-heading .item-title {
    font-size: 24px;
  }
}

.contact-box3 p {
  color: #646464;
  margin-bottom: 25px;
  width: 76%;
}

@media only screen and (max-width: 479px) {
  .contact-box3 p {
    width: 100%;
  }
}

.contact-box3 .message-box .form-group {
  margin-bottom: 10px;
}

.contact-box3 .message-box .form-group .form-control {
  color: #111;
  font-size: 14px;
  border: 1px solid #dcdcdc;
}

.contact-box3 .message-box .form-group .form-control:focus {
  box-shadow: none;
  border-color: #dcdcdc;
}

.contact-box3 .message-box .form-group input {
  height: 50px;
}

.contact-box3 .message-box .form-group .item-btn {
  margin-top: 10px;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background: #da251e;
  padding: 13px 40px;
  border: none;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 320px) {
  .contact-box3 .message-box .form-group .item-btn {
    padding: 13px 30px;
  }
}

.contact-box3 .message-box .form-group .item-btn:hover {
  color: #da251e;
  background-color: #fff;
  box-shadow: 0px 6px 27px 0px rgba(0, 0, 0, 0.1);
}

/*------------------- 5.12 Error -------------------*/
.error-wrap {
  padding: 195px 0px 175px 0px;
}

@media only screen and (max-width: 1199px) {
  .error-wrap {
    padding: 100px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .error-wrap {
    padding: 50px 0px;
  }
}

@media only screen and (max-width: 575px) {
  .error-wrap {
    padding: 30px 0px;
  }
}

.error-box {
  text-align: center;
}

@media only screen and (max-width: 1199px) {
  .error-box {
    text-align: left;
  }
}

.error-box .item-logo {
  margin-bottom: 37px;
}

.error-box .section-title {
  color: #000;
  font-size: 48px;
  line-height: 60px;
  font-weight: 400;
  margin-bottom: 8px;
}

@media only screen and (max-width: 1199px) {
  .error-box .section-title {
    font-size: 30px;
    line-height: 36px;
  }
}

@media only screen and (max-width: 479px) {
  .error-box .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.error-box p {
  font-size: 18px;
  line-height: 60px;
  color: #444;
}

@media only screen and (max-width: 1199px) {
  .error-box p {
    line-height: 36px;
    font-size: 16px;
  }
}

.error-box .error-button .error-btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  background-color: #da251e;
  padding: 15px 40px;
  box-shadow: 0px 6px 40px 0px rgba(193, 45, 12, 0.4);
  border: none;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
}

.error-box .error-button .error-btn:hover {
  box-shadow: none;
}

/*------------------- 5.13 Faq -------------------*/
.faq-wrap {
  padding: 120px 0px 100px 0px;
}

@media only screen and (max-width: 1199px) {
  .faq-wrap {
    padding: 30px 0px 40px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .faq-wrap {
    padding: 30px 0px 20px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .faq-wrap {
    padding: 30px 0px 40px 0px;
  }
}

.faq-wrap .faq-section-area .section-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  position: relative;
  padding-bottom: 20px;
  line-height: 60px;
}

@media only screen and (max-width: 1199px) {
  .faq-wrap .faq-section-area .section-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .faq-wrap .faq-section-area .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 320px) {
  .faq-wrap .faq-section-area .section-title {
    font-size: 20px;
  }
}

.faq-wrap .faq-section-area .section-title:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 10px;
  border: none;
}

.faq-wrap .faq-section-area .section-title:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 0px;
  left: 20px;
  border-radius: 10px;
  border: none;
}

.faq-wrap .faq-section-area .faq-para {
  margin-bottom: 35px;
  padding-right: 395px;
}

@media only screen and (max-width: 991px) {
  .faq-wrap .faq-section-area .faq-para {
    padding-right: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.faq-box .accordion .card {
  margin-bottom: 10px;
  border: none;
}

.faq-box .accordion .card:last-child {
  margin-bottom: 0;
}

.faq-box .accordion .card .card-header {
  padding: 0;
  border-bottom: none;
  background-color: #fff;
}

.faq-box .accordion .card .card-header .heading-title {
  position: relative;
  cursor: pointer;
  padding: 0px 70px 20px 30px;
  font-weight: 700;
  font-size: 20px;
  color: #da251e;
  border: none;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .faq-box .accordion .card .card-header .heading-title {
    font-size: 18px;
    padding: 0px 0px 20px 30px;
  }
}

.faq-box .accordion .card .card-header .heading-title:before {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  font-weight: 600;
  color: #da251e;
  position: absolute;
  top: 35%;
  left: 0px;
  transform: translateY(-50%);
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.faq-box .accordion .card .card-header .heading-title.collapsed {
  color: #444444;
  transition: all 0.3s ease-in-out;
}

.faq-box .accordion .card .card-header .heading-title.collapsed:before {
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  font-size: 16px;
  font-weight: 700;
  color: #444;
  position: absolute;
  top: 35%;
  left: 0px;
  transform: translateY(-50%);
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.faq-box .accordion .card .card-body {
  padding: 0;
  padding-left: 30px;
}

.faq-box .accordion .card .card-body p {
  color: #646464;
}

@media only screen and (max-width: 991px) {
  .faq-box .accordion .card .card-body p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

/*------------------- 5.14 Financo -------------------*/
.financo-activities-wrap1 {
  transform: translateY(310px);
  position: relative;
  z-index: 6;
  margin-top: -380px;
}

@media only screen and (max-width: 1439px) {
  .financo-activities-wrap1 {
    transform: translateY(0);
    margin-top: 0;
    padding: 50px 0px 50px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .financo-activities-wrap1 {
    padding: 50px 0px 20px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .financo-activities-wrap1 .row.no-gutters {
    margin-left: -15px;
    margin-right: -15px;
  }

  .financo-activities-wrap1 .row.no-gutters > [class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.financo-activities-box1 {
  background-color: #fff;
  box-shadow: 0px 7px 43px 0px rgba(0, 0, 0, 0.08);
  padding: 40px 20px 40px 60px;
  position: relative;
}

@media only screen and (max-width: 991px) {
  .financo-activities-box1 {
    margin-bottom: 30px;
    padding-left: 50px;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .financo-activities-box1 {
    padding: 50px 30px 50px 30px;
  }
}

.financo-activities-box1:before {
  content: "";
  width: 1px;
  height: 100%;
  background-color: #e5e5e5;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media only screen and (max-width: 991px) {
  .financo-activities-box1:before {
    display: none;
  }
}

.financo-activities-box1:hover .item-img-round:before {
  visibility: visible;
  opacity: 1;
}

.financo-activities-box1:hover .item-button .item-btn:after {
  width: 100%;
  background-color: #da251e;
}

.financo-activities-box1:hover .item-button .item-btn span {
  visibility: visible;
  opacity: 1;
}

.financo-activities-box1 .item-img-round {
  position: relative;
}

.financo-activities-box1 .item-img-round:before {
  /* content: url("../img/figure/figure40.png"); */
  position: absolute;
  bottom: -10px;
  left: 0px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.financo-activities-box1 .item-img {
  position: absolute;
  bottom: 18px;
  left: 25px;
}

.financo-activities-box1 .heading-title {
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 20px;
}

@media only screen and (max-width: 320px) {
  .financo-activities-box1 .heading-title {
    font-size: 20px;
  }
}

.financo-activities-box1 .heading-title a {
  color: #000;
  text-decoration: none;
}

.financo-activities-box1 p {
  font-weight: 500;
}

@media only screen and (max-width: 479px) {
  .financo-activities-box1 p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.financo-activities-box1 .item-button .item-btn {
  position: relative;
  display: inline-flex;
  font-size: 24px;
  font-weight: 500;
  line-height: 21px;
  color: #fff;
  padding: 7px 17px 11px 17px;
  border: none;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  text-decoration: none;
}

.financo-activities-box1 .item-button .item-btn:hover {
  opacity: 90%;
}

.financo-activities-box1 .item-button .item-btn:after {
  content: "";
  width: 36%;
  height: 100%;
  background-color: #bfbfbf;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.financo-activities-box1 .item-button .item-btn span {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  visibility: hidden;
  margin-top: 7px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.financo-activities-box3:before {
  display: none;
}

.financo-activities-wrap2 {
  padding-top: 132px;
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 1199px) {
  .financo-activities-wrap2 {
    padding-top: 50px;
  }
}

.background-shape69 {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 35%;
  z-index: -5;
}

@media only screen and (max-width: 1199px) {
  .background-shape69 {
    display: none;
  }
}

.financo-activities-box2 {
  position: relative;
  padding-bottom: 50px;
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  .financo-activities-box2 {
    margin-bottom: 30px;
  }
}

.financo-activities-box2:hover .financo-box-layout1 .financo-layout1 {
  border-radius: 4px;
}

.financo-activities-box2:hover .financo-box-layout1 .financo-layout1:after {
  background-color: #da251e;
}

.financo-activities-box2:hover .financo-box-layout1 .financo-layout1 p {
  visibility: visible;
  opacity: 1;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .financo-activities-box2:hover .financo-box-layout1 .financo-layout1 p {
    margin-bottom: 0px;
  }
}

.financo-activities-box2 .item-img {
  position: relative;
}

.financo-activities-box2 .item-img img {
  border-radius: 4px;
}

.financo-activities-box2 .financo-box-layout1 {
  position: absolute;
  bottom: 0;
  margin: 0 25px;
}

.financo-activities-box2 .financo-box-layout1 .financo-layout1 {
  position: relative;
  background-color: #fff;
  padding: 0px 20px 25px 40px;
  border: none;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 1199px) {
  .financo-activities-box2 .financo-box-layout1 .financo-layout1 {
    padding: 0px 10px 20px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .financo-activities-box2 .financo-box-layout1 .financo-layout1 {
    padding: 0px 45px 45px 45px;
  }
}

@media only screen and (max-width: 575px) {
  .financo-activities-box2 .financo-box-layout1 .financo-layout1 {
    padding: 0px 45px 30px 45px;
  }
}

.financo-activities-box2 .financo-box-layout1 .financo-layout1:after {
  content: "";
  height: 4px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0;
  background-color: #e1e1e1;
  border: none;
  transition: all 0.3s ease-in-out;
}

.financo-activities-box2 .financo-box-layout1 .financo-layout1 .financo-img {
  position: relative;
  z-index: 5;
  transform: translateY(-15px);
  margin-left: 16px;
}

.financo-activities-box2
  .financo-box-layout1
  .financo-layout1
  .financo-img:before {
  content: "";
  height: 78px;
  width: 78px;
  position: absolute;
  bottom: -16px;
  left: -18px;
  background-color: #fff;
  border-radius: 50%;
  z-index: -5;
}

.financo-activities-box2 .financo-box-layout1 .financo-layout1 .heading-title {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0;
}

@media only screen and (max-width: 1199px) {
  .financo-activities-box2
    .financo-box-layout1
    .financo-layout1
    .heading-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .financo-activities-box2
    .financo-box-layout1
    .financo-layout1
    .heading-title {
    line-height: 30px;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 575px) {
  .financo-activities-box2
    .financo-box-layout1
    .financo-layout1
    .heading-title {
    font-size: 22px;
  }
}

.financo-activities-box2
  .financo-box-layout1
  .financo-layout1
  .heading-title
  a {
  color: #000;
  transition: all 0.3s ease-in-out;
}

.financo-activities-box2
  .financo-box-layout1
  .financo-layout1
  .heading-title
  a:hover {
  color: #da251e;
}

.financo-activities-box2 .financo-box-layout1 .financo-layout1 p {
  margin-bottom: -50px;
  line-height: 24px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/*------------------- 5.15 Financo -------------------*/
.gallery-wrap {
  padding: 120px 0px 135px 0px;
}

@media only screen and (max-width: 1199px) {
  .gallery-wrap {
    padding: 50px 0px 45px 0px;
  }
}

.gallery-box1 {
  margin-bottom: 5px;
}

.gallery-box1:hover .item-img:before {
  visibility: visible;
  opacity: 88%;
}

.gallery-box1:hover .item-content {
  visibility: visible;
  opacity: 1;
}

.gallery-box1:hover .item-button .item-btn {
  visibility: visible;
  opacity: 1;
}

.gallery-box1 .item-img {
  overflow: hidden;
  position: relative;
}

.gallery-box1 .item-img:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #da251e;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.gallery-box1 .item-img a {
  z-index: 5;
}

.gallery-box1 .item-content {
  position: absolute;
  top: 45%;
  right: 50%;
  transform: translateY(-50%) translateX(50%);
  z-index: 5;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.gallery-box1 .item-content .heading-title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0px;
}

@media only screen and (max-width: 1199px) {
  .gallery-box1 .item-content .heading-title {
    font-size: 19px;
  }
}

@media only screen and (max-width: 991px) {
  .gallery-box1 .item-content .heading-title {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .gallery-box1 .item-content .heading-title {
    font-size: 19px;
  }
}

@media only screen and (max-width: 479px) {
  .gallery-box1 .item-content .heading-title {
    font-size: 16px;
  }
}

.gallery-box1 .item-content .heading-subtitle {
  color: #ffae9d;
  font-size: 18px;
  font-weight: 500;
  margin-top: -10px;
}

@media only screen and (max-width: 991px) {
  .gallery-box1 .item-content .heading-subtitle {
    font-size: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .gallery-box1 .item-content .heading-subtitle {
    font-size: 18px;
  }
}

@media only screen and (max-width: 479px) {
  .gallery-box1 .item-content .heading-subtitle {
    font-size: 15px;
  }
}

.gallery-box1 .item-button {
  position: absolute;
  top: 58%;
  right: 45%;
  transform: translateY(-50%) translateX(50%);
  z-index: 5;
}

@media only screen and (max-width: 767px) {
  .gallery-box1 .item-button {
    top: 63%;
    right: 43%;
  }
}

@media only screen and (max-width: 479px) {
  .gallery-box1 .item-button {
    top: 70%;
    right: 40%;
  }
}

.gallery-box1 .item-button .item-btn {
  position: relative;
  display: inline-flex;
  font-size: 24px;
  font-weight: 500;
  color: #da251e;
  padding: 7px 17px 7px 17px;
  border: none;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  z-index: 5;
  visibility: hidden;
  opacity: 0;
}

.gallery-box1 .item-button .item-btn:hover {
  color: #ffa996;
}

.gallery-box1 .item-button .item-btn:after {
  content: "";
  width: 36%;
  height: 100%;
  background-color: #fff;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  z-index: -5;
  transition: all 0.5s ease-in-out;
}

.gallery-box1 .item-button .item-btn span {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.gallery-box1 .item-button2 {
  top: 68%;
  right: 40%;
}

@media only screen and (max-width: 1199px) {
  .gallery-box1 .item-button2 {
    top: 75%;
  }
}

@media only screen and (max-width: 575px) {
  .gallery-box1 .item-button2 {
    top: 68%;
  }
}

@media only screen and (max-width: 479px) {
  .gallery-box1 .item-button2 {
    top: 73%;
  }
}

@media only screen and (max-width: 991px) {
  .gallery-box2 {
    display: none;
  }
}

/*------------------- 5.16 Location -------------------*/
.location-wrap-layout1 {
  padding: 240px 0px 260px 0px;
  position: relative;
  /* background-image: url("../img/figure/figure8.png"); */
  background-image: url(../src/assets/img/figure/figure8.png);
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1439px) {
  .location-wrap-layout1 {
    padding: 95px 0px 260px 0px;
  }
}

@media only screen and (max-width: 1199px) {
  .location-wrap-layout1 {
    background-image: none;
  }
}

@media only screen and (max-width: 479px) {
  .location-wrap-layout1 {
    padding: 40px 0px 50px 0px;
  }
}

.location-wrap-layout1 .item-heading {
  text-align: center;
}

@media only screen and (max-width: 479px) {
  .location-wrap-layout1 .item-heading {
    text-align: left;
  }
}

.location-wrap-layout1 .item-heading .item-subtitle {
  color: #da251e;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
}

.location-wrap-layout1 .item-heading .section-heading {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 80px;
}

@media only screen and (max-width: 1199px) {
  .location-wrap-layout1 .item-heading .section-heading {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .location-wrap-layout1 .item-heading .section-heading {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 40px;
  }
}

.location-box .item-img {
  position: relative;
  text-align: center;
}

.location-box .map-icon ul li {
  position: relative;
  color: #da251e;
  font-size: 30px;
  z-index: 1;
}

.location-box .map-icon ul li:nth-child(1n) {
  position: absolute;
  top: 28%;
  left: 201px;
  transform: translateY(-50%) translateX(50%);
}

@media only screen and (max-width: 991px) {
  .location-box .map-icon ul li:nth-child(1n) {
    left: 25px;
  }
}

.location-box .map-icon ul li:nth-child(2n) {
  position: absolute;
  top: 0;
  left: 39%;
  transform: translateY(-50%) translateX(50%);
}

@media only screen and (max-width: 991px) {
  .location-box .map-icon ul li:nth-child(2n) {
    left: 34%;
  }
}

.location-box .map-icon ul li:nth-child(3n) {
  position: absolute;
  top: 60%;
  left: 42%;
  transform: translateY(-50%) translateX(50%);
}

@media only screen and (max-width: 479px) {
  .location-box .map-icon ul li:nth-child(3n) {
    left: 35%;
  }
}

.location-box .map-icon ul li:nth-child(4n) {
  position: absolute;
  top: 23%;
  left: 65%;
  transform: translateY(-50%) translateX(50%);
}

@media only screen and (max-width: 991px) {
  .location-box .map-icon ul li:nth-child(4n) {
    left: 70%;
  }
}

.location-box .map-icon ul li:nth-child(5n) {
  position: absolute;
  top: 70%;
  left: 67%;
  transform: translateY(-50%) translateX(50%);
}

@media only screen and (max-width: 991px) {
  .location-box .map-icon ul li:nth-child(5n) {
    left: 74%;
  }
}

.location-box .map-icon ul li:before {
  content: "";
  width: 39px;
  height: 16px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  position: absolute;
  bottom: -5px;
  left: -8px;
  box-shadow: 0px 6px 27px 0px rgba(0, 0, 0, 0.41);
  z-index: -1;
  animation: pulse infinite 3s;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #da251e;
  }

  70% {
    box-shadow: 0 0 0 20px rgba(12, 31, 215, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(12, 31, 215, 0);
  }
}

/*------------------- 5.17 Personal -------------------*/
.personal-info-wrap1 {
  background-color: #111111;
  position: relative;
  padding-left: calc((100% - 1140px) / 2);
  overflow: hidden;
}

@media only screen and (max-width: 1199px) {
  .personal-info-wrap1 {
    padding-left: calc((100% - 930px) / 2);
  }
}

@media only screen and (max-width: 991px) {
  .personal-info-wrap1 {
    padding-left: calc((100% - 690px) / 2);
  }
}

@media only screen and (max-width: 767px) {
  .personal-info-wrap1 {
    padding-left: calc((100% - 510px) / 2);
  }
}

.personal-info-wrap1 .figure-img1 {
  position: absolute;
  top: 40%;
  right: 45%;
  transform: translateY(-50%) translateX(50%);
}

.personal-info-wrap1 .figure-img2 {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 5%;
}

.personal-info-wrap2 {
  background-color: #111111;
  padding: 125px 0px 215px 0px;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 1439px) {
  .personal-info-wrap2 {
    padding: 125px 0px 270px 0px;
  }
}

@media only screen and (max-width: 1199px) {
  .personal-info-wrap2 {
    padding: 45px 0px 10px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .personal-info-wrap2 {
    padding: 42px 0px 20px 0px;
  }
}

@media only screen and (max-width: 575px) {
  .personal-info-wrap2 {
    padding: 38px 0px 20px 0px;
  }
}

.personal-info-wrap2 .location-img {
  position: absolute;
  top: 0;
  right: 0;
}

.personal-info-wrap2 .figure-img1 {
  position: absolute;
  top: 0px;
  right: 0px;
  transform: rotate(20deg);
}

@media only screen and (max-width: 991px) {
  .personal-info-wrap2 .figure-img1 {
    display: none;
  }
}

.personal-info-wrap2 .figure-img2 {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 5%;
}

.personal-info-wrap2 .color-overlay1 {
  position: absolute;
  top: 0;
  right: 0;
}

@media only screen and (max-width: 1199px) {
  .personal-info-wrap2 .color-overlay1 {
    display: none;
  }
}

.personal-info-wrap2 .color-overlay2 {
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 1199px) {
  .personal-info-wrap2 .color-overlay2 {
    display: none;
  }
}

.personal-details {
  padding-top: 42px;
}

@media only screen and (max-width: 1439px) {
  .personal-details {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 1199px) {
  .personal-details {
    padding: 40px 0px 10px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .personal-details {
    padding: 40px 0px 20px 0px;
  }
}

.personal-details .personal-info-box {
  z-index: 6;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1439px) {
  .personal-details .personal-info-box {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .personal-details .personal-info-box {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .personal-details .personal-info-box {
    text-align: center;
    margin-bottom: 20px;
  }
}

.personal-details .personal-info-box .section-title {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 1439px) {
  .personal-details .personal-info-box .section-title {
    font-size: 23px;
  }
}

@media only screen and (max-width: 1199px) {
  .personal-details .personal-info-box .section-title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .personal-details .personal-info-box .section-title {
    padding-bottom: 0;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 479px) {
  .personal-details .personal-info-box .section-title {
    font-size: 22px;
  }
}

.personal-details .personal-info-box .section-title:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 0px;
  left: 0;
  border-radius: 10px;
  border: none;
}

@media only screen and (max-width: 575px) {
  .personal-details .personal-info-box .section-title:before {
    display: none;
  }
}

.personal-details .personal-info-box .section-title:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 0px;
  left: 21px;
  border-radius: 10px;
  border: none;
}

@media only screen and (max-width: 575px) {
  .personal-details .personal-info-box .section-title:after {
    display: none;
  }
}

.personal-details .personal-info-box .information-list li {
  color: #898989;
}

.personal-info-box2 {
  z-index: 6;
  margin-bottom: 50px;
}

@media only screen and (max-width: 1439px) {
  .personal-info-box2 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .personal-info-box2 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .personal-info-box2 {
    text-align: center;
    margin-bottom: 20px;
  }
}

.personal-info-box2 .section-title {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 1439px) {
  .personal-info-box2 .section-title {
    font-size: 23px;
  }
}

@media only screen and (max-width: 1199px) {
  .personal-info-box2 .section-title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .personal-info-box2 .section-title {
    padding-bottom: 0;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 479px) {
  .personal-info-box2 .section-title {
    font-size: 22px;
  }
}

.personal-info-box2 .section-title:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 0px;
  left: 0;
  border-radius: 10px;
  border: none;
}

@media only screen and (max-width: 575px) {
  .personal-info-box2 .section-title:before {
    display: none;
  }
}

.personal-info-box2 .section-title:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 0px;
  left: 21px;
  border-radius: 10px;
  border: none;
}

@media only screen and (max-width: 575px) {
  .personal-info-box2 .section-title:after {
    display: none;
  }
}

.personal-info-box2 .information-list li {
  color: #898989;
}

.location-img {
  text-align: right;
  z-index: 1;
  position: relative;
}

.location-img:after {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #da251e;
  position: absolute;
  top: -68px;
  left: 5px;
  z-index: -1;
  clip-path: inset(5% 20% 5% 10%);
  transform: rotate(37deg);
  border-radius: 0px 0px 0px 30px;
}

@media only screen and (max-width: 1439px) {
  .location-img:after {
    display: none;
  }
}

.location-img .google-map {
  height: 612px;
  width: 100%;
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
  border-radius: 0px 0px 0px 30px;
  background-color: rgb(229, 227, 223);
}

/*------------------- 5.18 Portfolio -------------------*/
.portfolio-wrap1 {
  padding: 120px 0px 90px 0px;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .portfolio-wrap1 {
    padding: 50px 0px 20px 0px;
  }
}

.portfolio-wrap1 .background-image {
  position: absolute;
  top: 40%;
  right: 168px;
  transform: translateY(-50px);
}

@media only screen and (max-width: 1439px) {
  .portfolio-wrap1 .background-image {
    display: none;
  }
}

.portfolio-wrap2 {
  padding: 120px 0px 140px 0px;
  background-color: #fff;
}

@media only screen and (max-width: 1439px) {
  .portfolio-wrap2 {
    padding: 50px 0px;
  }
}

.portfolio-box1:hover .item-img:before {
  height: 100%;
}

.portfolio-box1:hover .item-img img {
  transform: scale(1.1);
}

.portfolio-box1:hover .item-content {
  transform: translateY(0px);
}

.portfolio-box1:hover .item-content .portfolio-para {
  visibility: visible;
  opacity: 1;
}

.portfolio-box1:hover .item-content .item-button .item-btn {
  visibility: visible;
  opacity: 1;
}

.portfolio-box1:hover .item-content .item-button .item-btn:after {
  width: 100%;
}

.portfolio-box1:hover .item-content .item-button .item-btn span {
  visibility: visible;
  opacity: 1;
}

.portfolio-box1 .item-img {
  overflow: hidden;
  position: relative;
}

.portfolio-box1 .item-img:before {
  content: "";
  height: 50%;
  width: 100%;
  background-image: linear-gradient(to top, #000, transparent);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.portfolio-box1 .item-img a {
  transition: all 0.3s ease-in-out;
}

.portfolio-box1 .item-img img {
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}

.portfolio-box1 .item-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0px 0px 33px 50px;
  transform: translateY(116px);
  z-index: 5;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 1199px) {
  .portfolio-box1 .item-content {
    padding: 0px 10px 15px 15px;
  }
}

@media only screen and (max-width: 991px) {
  .portfolio-box1 .item-content {
    padding: 0px 30px 30px 50px;
  }
}

@media only screen and (max-width: 575px) {
  .portfolio-box1 .item-content {
    padding: 0px 30px 30px 30px;
  }
}

.portfolio-box1 .item-content .item-number {
  font-size: 100px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.2);
  margin-bottom: 40px;
}

@media only screen and (max-width: 1199px) {
  .portfolio-box1 .item-content .item-number {
    font-size: 50px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .portfolio-box1 .item-content .item-number {
    font-size: 100px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-box1 .item-content .item-number {
    font-size: 50px;
  }
}

.portfolio-box1 .item-content .heading-subtitle {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto", serif;
  position: relative;
  margin-bottom: 5px;
}

.portfolio-box1 .item-content .heading-subtitle:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 12px;
  left: 141px;
  border-radius: 10px;
  border: none;
}

.portfolio-box1 .item-content .heading-subtitle:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 12px;
  left: 161px;
  border-radius: 10px;
  border: none;
}

.portfolio-box1 .item-content .heading-title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1199px) {
  .portfolio-box1 .item-content .heading-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .portfolio-box1 .item-content .heading-title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 479px) {
  .portfolio-box1 .item-content .heading-title {
    font-size: 20px;
  }
}

.portfolio-box1 .item-content .portfolio-para {
  color: #d4d4d4;
  margin-bottom: 15px;
  visibility: hidden;
  font-weight: 400;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 1199px) {
  .portfolio-box1 .item-content .portfolio-para {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.portfolio-box1 .item-content .item-button .item-btn {
  position: relative;
  display: inline-flex;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  color: #fff;
  padding: 7px 17px 12px 17px;
  border: none;
  border-radius: 30px;
  transition: all 0.5s ease-in-out;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  text-decoration: none;
}

.portfolio-box1 .item-content .item-button .item-btn:hover {
  opacity: 90%;
}

.portfolio-box1 .item-content .item-button .item-btn:after {
  content: "";
  width: 36%;
  height: 100%;
  background-color: #da251e;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  z-index: -1;
  transition: all 0.5s ease-in-out;
}

.portfolio-box1 .item-content .item-button .item-btn span {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.portfolio-button {
  margin-top: 90px;
  text-align: center;
}

@media only screen and (max-width: 1199px) {
  .portfolio-button {
    margin-top: 50px;
  }
}

.portfolio-button .portfolio-btn {
  display: inline-block;
  padding: 13px 40px;
  background-color: #fff;
  border: 1px solid #da251e;
  border-radius: 30px;
  color: #444444;
  font-size: 16px;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}

.portfolio-button .portfolio-btn:hover {
  background-color: #da251e;
  color: #fff;
}

.portfolio-button .portfolio-btn i {
  margin-right: 10px;
}

.single-portfolio-wrap1 {
  padding: 120px 0px 40px 0px;
}

@media only screen and (max-width: 1199px) {
  .single-portfolio-wrap1 {
    padding: 50px 0px 40px 0px;
  }
}

.single-portfolio-wrap2 {
  padding: 0px 0px 0px 0px;
}

.single-portfolio-box1 {
  background-color: #fff;
}

.single-portfolio-box2 .section-subtitle {
  color: #da251e;
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
}

.single-portfolio-box2 .section-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1199px) {
  .single-portfolio-box2 .section-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .single-portfolio-box2 .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.single-portfolio-box2 .section-subtitle2 {
  color: #000;
  font-size: 22px;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 20px;
  font-style: italic;
}

@media only screen and (max-width: 479px) {
  .single-portfolio-box2 .section-subtitle2 {
    font-size: 18px;
    line-height: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.single-portfolio-box2 .section-title2 {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}

@media only screen and (max-width: 479px) {
  .single-portfolio-box2 .section-title2 {
    font-size: 24px;
    line-height: 30px;
  }
}

.single-portfolio-box2 .portfolio-list {
  margin-bottom: 30px;
  padding-left: 30px;
}

@media only screen and (max-width: 479px) {
  .single-portfolio-box2 .portfolio-list {
    padding-left: 0;
  }
}

.single-portfolio-box2 .portfolio-list li {
  color: #000;
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: 500;
}

.single-portfolio-box2 .portfolio-list li:last-child {
  margin-bottom: 0;
}

.single-portfolio-box2 .portfolio-list li i {
  color: #da251e;
  font-size: 12px;
  margin-right: 10px;
}

/*------------------- 5.19 Pricing -------------------*/
.pricing-table-wrap {
  padding: 120px 0px 140px 0px;
}

@media only screen and (max-width: 1199px) {
  .pricing-table-wrap {
    padding: 50px 0px;
  }
}

.pricing-table-wrap .section-heading {
  text-align: center;
}

.pricing-table-wrap .section-heading .section-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  line-height: 28px;
}

@media only screen and (max-width: 1199px) {
  .pricing-table-wrap .section-heading .section-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .pricing-table-wrap .section-heading .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.pricing-table-wrap .section-heading .section-subtitle {
  color: #444444;
  font-size: 18px;
  margin-bottom: 32px;
}

.pricing-table-wrap .section-heading .section-subtitle span {
  font-weight: 700;
}

.pricing-table-wrap .section-heading .section-subtitle .colorful-bg {
  color: #da251e;
}

.tab-area2 .nav-tabs {
  border: none;
  justify-content: center;
}

/* .tab-area2 .nav-tabs .nav-item {
  margin-bottom: 0;
  margin-right: -20px;
} */

/* .tab-area2 .nav-tabs .nav-item .nav-link {
  border-radius: 30px;
  padding: 4px 26px;
  font-size: 14px;
  font-weight: 400;
  color: #444;
  line-height: 30px;
  text-align: center;
  background-color: #f3f3f3;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
  z-index: -1;
} */

/* .tab-area2 .nav-tabs .nav-item .nav-link.active {
  background-color: #da251e;
  color: #fff;
  z-index: 1;
  border-color: #da251e;
  position: relative;
  transition: all 0.5s ease-in-out;
} */

.tab-area2 .tab-content .pricing-table-box {
  background-color: #fafafa;
  padding: 32px 50px 58px 50px;
  border: 1px solid #e5e5e5;
  border-radius: 30px;
  position: relative;
  z-index: 5;
  overflow: hidden;
  margin-top: 35px;
}

@media only screen and (max-width: 575px) {
  .tab-area2 .tab-content .pricing-table-box {
    padding: 30px;
  }
}

.tab-area2 .tab-content .pricing-table-box .background-imagae {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -5;
}

.tab-area2 .tab-content .pricing-table-box:hover .pricing-button .pricing-btn {
  box-shadow: none;
  background-color: #da251e;
  color: #fff;
  border-color: #da251e;
}

.tab-area2 .tab-content .pricing-table-box .heading-title {
  color: #000;
  font-size: 24px;
  font-weight: 700;
  position: relative;
  padding-bottom: 20px;
}

@media only screen and (max-width: 479px) {
  .tab-area2 .tab-content .pricing-table-box .heading-title {
    font-size: 20px;
  }
}

.tab-area2 .tab-content .pricing-table-box .heading-title:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 10px;
  border: none;
}

.tab-area2 .tab-content .pricing-table-box .heading-title:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 0px;
  left: 20px;
  border-radius: 10px;
  border: none;
}

.tab-area2 .tab-content .pricing-table-box p {
  margin-bottom: 45px;
}

@media only screen and (max-width: 1199px) {
  .tab-area2 .tab-content .pricing-table-box p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.tab-area2 .tab-content .pricing-table-box .item-price {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .tab-area2 .tab-content .pricing-table-box .item-price {
    text-align: left;
  }
}

.tab-area2 .tab-content .pricing-table-box .item-price .item-number {
  color: #000;
  font-size: 72px;
  font-weight: 700;
  line-height: 24px;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .tab-area2 .tab-content .pricing-table-box .item-price .item-number {
    font-size: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .tab-area2 .tab-content .pricing-table-box .item-price .item-number {
    font-size: 72px;
  }
}

.tab-area2 .tab-content .pricing-table-box .item-price .item-dollar {
  color: #8e8d8d;
  font-size: 30px;
  font-weight: 400;
  position: absolute;
  top: -8px;
  left: 28px;
}

@media only screen and (max-width: 1199px) {
  .tab-area2 .tab-content .pricing-table-box .item-price .item-dollar {
    font-size: 25px;
    left: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-area2 .tab-content .pricing-table-box .item-price .item-dollar {
    left: -16px;
  }
}

.tab-area2 .tab-content .pricing-table-box .item-price .item-dollar2 {
  left: 8px;
}

@media only screen and (max-width: 1199px) {
  .tab-area2 .tab-content .pricing-table-box .item-price .item-dollar2 {
    left: 3px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-area2 .tab-content .pricing-table-box .item-price .item-dollar2 {
    left: -16px;
  }
}

.tab-area2 .tab-content .pricing-table-box .item-price .item-currency {
  color: #8e8d8d;
  font-size: 30px;
  font-weight: 400;
  position: absolute;
  top: -8px;
  right: 82px;
}

@media only screen and (max-width: 1199px) {
  .tab-area2 .tab-content .pricing-table-box .item-price .item-currency {
    font-size: 25px;
    right: 55px;
  }
}

@media only screen and (max-width: 991px) {
  .tab-area2 .tab-content .pricing-table-box .item-price .item-currency {
    right: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-area2 .tab-content .pricing-table-box .item-price .item-currency {
    right: 285px;
  }
}

@media only screen and (max-width: 575px) {
  .tab-area2 .tab-content .pricing-table-box .item-price .item-currency {
    display: none;
  }
}

.tab-area2 .tab-content .pricing-table-box .item-price .item-currency2 {
  right: 62px;
}

@media only screen and (max-width: 1199px) {
  .tab-area2 .tab-content .pricing-table-box .item-price .item-currency2 {
    right: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .tab-area2 .tab-content .pricing-table-box .item-price .item-currency2 {
    right: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-area2 .tab-content .pricing-table-box .item-price .item-currency2 {
    right: 245px;
  }
}

@media only screen and (max-width: 575px) {
  .tab-area2 .tab-content .pricing-table-box .item-price .item-currency2 {
    display: none;
  }
}

.tab-area2 .tab-content .pricing-table-box .item-price .item-duration {
  color: #4c4c4c;
  font-size: 16px;
  font-weight: 400;
}

@media only screen and (max-width: 1199px) {
  .tab-area2 .tab-content .pricing-table-box .item-price .item-duration {
    font-size: 14px;
  }
}

@media only screen and (max-width: 991px) {
  .tab-area2 .tab-content .pricing-table-box .item-price .item-duration {
    font-size: 16px;
  }
}

.tab-area2 .tab-content .pricing-table-box .pricing-list {
  margin-bottom: 30px;
  margin-top: 30px;
}

.tab-area2 .tab-content .pricing-table-box .pricing-list li {
  display: block;
  color: #444;
}

@media only screen and (max-width: 1199px) {
  .tab-area2 .tab-content .pricing-table-box .pricing-list li {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.tab-area2 .tab-content .pricing-table-box .pricing-list li i {
  font-size: 8px;
  margin-right: 10px;
  color: #646464;
}

.tab-area2 .tab-content .pricing-table-box .pricing-button .pricing-btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #fff;
  color: #da251e;
  border: 1px solid #e5e5e5;
  border-radius: 30px;
  padding: 15px 63px;
  box-shadow: 0px 17px 68px 0px rgba(89, 89, 89, 0.16);
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 1199px) {
  .tab-area2 .tab-content .pricing-table-box .pricing-button .pricing-btn {
    padding: 10px 30px;
  }
}

@media only screen and (max-width: 320px) {
  .tab-area2 .tab-content .pricing-table-box .pricing-button .pricing-btn {
    padding: 10px 20px;
  }
}

/*------------------- 5.20 Profit -------------------*/
.company-profit-wrap1 {
  padding: 130px 0px 0px 0px;
}

@media only screen and (max-width: 1199px) {
  .company-profit-wrap1 {
    padding: 40px 0px 0px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .company-profit-box1 {
    margin-bottom: 50px;
  }
}

.company-profit-box1 .section-subtitle {
  color: #da251e;
  font-size: 18px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  position: relative;
  margin-bottom: 10px;
}

.company-profit-box1 .section-subtitle:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 12px;
  left: 184px;
  border-radius: 10px;
  border: none;
}

.company-profit-box1 .section-subtitle:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 12px;
  left: 204px;
  border-radius: 10px;
  border: none;
}

.company-profit-box1 .section-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 42px;
}

@media only screen and (max-width: 1199px) {
  .company-profit-box1 .section-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .company-profit-box1 .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.company-profit-box1 p {
  margin-bottom: 25px;
}

.company-profit-box1 .item-button .item-btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #444;
  background-color: #fff;
  padding: 14px 28px;
  border: none;
  box-shadow: 0px 6px 40px 0px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  position: relative;
  text-decoration: none;
  z-index: 5;
}

.company-profit-box1 .item-button .item-btn:after {
  content: "";
  height: 100%;
  width: 0;
  background: linear-gradient(to right, #fa4e0a, #f8360a);
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
  visibility: hidden;
  opacity: 0;
  border-radius: 30px;
  z-index: -5;
  transition: all 0.3s ease-in-out;
}

.company-profit-box1 .item-button .item-btn:hover {
  border-color: #da251e;
  color: #fff;
  box-shadow: none;
}

.company-profit-box1 .item-button .item-btn:hover:after {
  visibility: visible;
  opacity: 1;
  width: 100%;
  left: 0;
}

.company-profit-box1 .item-button .item-btn i {
  margin-left: 10px;
}

.company-profit-box2 {
  text-align: right;
}

@media only screen and (max-width: 991px) {
  .company-profit-box2 {
    text-align: left;
  }
}

/*------------------- 5.21 Progress-bar -------------------*/
.progress-bar-wrap1 {
  background-color: transparent;
}

.progress-bar-box1 {
  /* background-image: url("../img/figure/figure36.png"); */
  background-image: url(../src/assets/img/figure/figure36.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 94px;
  padding-right: 140px;
  padding-top: 92px;
  padding-left: calc(100% - 570px);
}

@media only screen and (max-width: 1199px) {
  .progress-bar-box1 {
    padding-left: calc(100% - 465px);
  }
}

@media only screen and (max-width: 991px) {
  .progress-bar-box1 {
    padding-left: calc((100% - 690px) / 2);
    padding-top: 40px;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .progress-bar-box1 {
    padding-left: calc((100% - 510px) / 2);
    padding-bottom: 50px;
    padding-top: 35px;
  }
}

@media only screen and (max-width: 575px) {
  .progress-bar-box1 {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
  }
}

.progress-bar-box1 .section-title {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  line-height: 48px;
}

@media only screen and (max-width: 1199px) {
  .progress-bar-box1 .section-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .progress-bar-box1 .section-title {
    text-align: center;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 479px) {
  .progress-bar-box1 .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.progress-bar-box1 p {
  color: #fff;
  margin-bottom: 28px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  .progress-bar-box1 p {
    text-align: center;
  }
}

@media only screen and (max-width: 991px) {
  .progress-bar-box1 .progress-box {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 575px) {
  .progress-bar-box1 .progress-box {
    margin-bottom: 30px;
  }
}

.progress-bar-box1 .progress-box .circle-progress {
  display: flex;
  position: relative;
}

.progress-bar-box1 .progress-box .circle-progress span {
  position: absolute;
  top: 48%;
  left: 48px;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 1;
  color: #fff;
  font-size: 36px;
  font-weight: 700;
}

.progress-bar-box1 .progress-box .circle-progress label {
  color: #fff;
  font-size: 16px;
  position: absolute;
  bottom: 32px;
  left: 45px;
  margin-bottom: 0;
}

.progress-bar-box2 {
  overflow: hidden;
  position: relative;
}

.progress-bar-box2 .background-image5 {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

@media only screen and (max-width: 991px) {
  .progress-bar-box2 .background-image5 {
    display: none;
  }
}

.progress-bar-box2 .item-img img {
  height: 100%;
  object-fit: cover;
}

.progress-bar-box3 {
  height: 100%;
}

/*------------------- 5.22 Project -------------------*/
.project-wrap-layout1 {
  padding: 70px 0px 0px 0px;
}

@media only screen and (max-width: 1439px) {
  .project-wrap-layout1 {
    padding: 0px;
  }
}

.project-wrap-layout1 .section-subtitle {
  color: #da251e;
  font-size: 18px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  position: relative;
  margin-bottom: 10px;
}

.project-wrap-layout1 .section-subtitle:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 12px;
  left: 144px;
  border-radius: 10px;
  border: none;
}

.project-wrap-layout1 .section-subtitle:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 12px;
  left: 165px;
  border-radius: 10px;
  border: none;
}

.project-wrap-layout1 .section-subtitle-sm:before {
  left: 82px;
}

.project-wrap-layout1 .section-subtitle-sm:after {
  left: 103px;
}

.project-box1 {
  margin-bottom: 38px;
}

@media only screen and (max-width: 991px) {
  .project-box1 {
    margin-bottom: 15px;
  }
}

.project-box1 .section-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
  padding-right: 12px;
}

@media only screen and (max-width: 1199px) {
  .project-box1 .section-title {
    font-size: 30px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 479px) {
  .project-box1 .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.project-box2:hover .item-img img {
  transform: scale(1.1);
}

.project-box2 .item-img {
  overflow: hidden;
  position: relative;
}

.project-box2 .item-img:before {
  content: "";
  height: 60%;
  width: 100%;
  background-image: linear-gradient(to top, #000, transparent);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}

.project-box2 .item-img a {
  transition: all 0.3s ease-in-out;
}

.project-box2 .item-img img {
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}

.project-box2 .item-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0px 0px 33px 50px;
  z-index: 5;
}

.project-box2 .item-content .item-number {
  font-size: 100px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.1);
  margin-bottom: 40px;
}

.project-box2 .item-content .heading-subtitle {
  color: #c0c0c0;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  margin-bottom: 10px;
}

.project-box2 .item-content .heading-subtitle:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 12px;
  left: 146px;
  border-radius: 10px;
  border: none;
}

.project-box2 .item-content .heading-subtitle:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 12px;
  left: 165px;
  border-radius: 10px;
  border: none;
}

.project-box2 .item-content .heading-title {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.project-wrap-layout2 {
  background-color: #f6f6f6;
}

@media only screen and (max-width: 1199px) {
  .project-wrap-layout2 {
    padding: 40px 0px 3px 0px;
  }
}

/*------------------- 5.23 Team -------------------*/
.team-wrap-layout1 {
  position: relative;
  padding: 130px 0px 35px 0px;
}

@media only screen and (max-width: 1199px) {
  .team-wrap-layout1 {
    padding: 40px 0px 0px 0px;
  }
}

.team-wrap-layout1 .background-image2 {
  position: absolute;
  bottom: -150px;
  right: 0px;
}

@media only screen and (max-width: 1199px) {
  .team-wrap-layout1 .background-image2 {
    display: none;
  }
}

.team-wrap-layout1 .background-image3 {
  position: absolute;
  bottom: -110px;
  left: 0px;
}

@media only screen and (max-width: 1199px) {
  .team-wrap-layout1 .background-image3 {
    display: none;
  }
}

.team-wrap-layout1 .background-image12 {
  position: absolute;
  top: 290px;
  right: 200px;
}

.team-wrap-layout1 .background-image13 {
  position: absolute;
  top: 290px;
  left: 200px;
}

.team-wrap-layout1 .background-image16 {
  position: absolute;
  bottom: -110px;
  right: 150px;
}

@media only screen and (max-width: 767px) {
  .team-wrap-layout1 .background-image16 {
    display: none;
  }
}

.team-wrap-layout1 .background-image17 {
  position: absolute;
  bottom: -110px;
  left: 100px;
}

@media only screen and (max-width: 767px) {
  .team-wrap-layout1 .background-image17 {
    display: none;
  }
}

.team-wrap-layout1 .section-heading {
  text-align: center;
}

.team-wrap-layout1 .section-heading .item-subtitle {
  color: #da251e;
  font-size: 18px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  position: relative;
  margin-bottom: 10px;
}

.team-wrap-layout1 .section-heading .heading-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1199px) {
  .team-wrap-layout1 .section-heading .heading-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .team-wrap-layout1 .section-heading .heading-title {
    margin-bottom: 17px;
  }
}

@media only screen and (max-width: 479px) {
  .team-wrap-layout1 .section-heading .heading-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.team-wrap-layout1 .section-heading p {
  margin-bottom: 40px;
  padding-right: 330px;
  padding-left: 330px;
}

@media only screen and (max-width: 1199px) {
  .team-wrap-layout1 .section-heading p {
    padding-right: 0;
    padding-left: 0;
  }
}

.team-box1 {
  padding: 30px 30px 20px 30px;
  background-color: #ffffff;
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 991px) {
  .team-box1 {
    margin-bottom: 30px;
  }
}

.team-box1:before {
  content: "";
  width: 70px;
  height: 70px;
  background-color: #e1e1e1;
  border: none;
  border-radius: 6px;
  position: absolute;
  bottom: 22px;
  right: -40px;
  z-index: 11;
  transform: rotate(50deg);
  transition: all 0.3s ease-in-out;
}

.team-box1:after {
  content: "";
  width: 70px;
  height: 70px;
  border: 2px solid #e1e1e1;
  border-radius: 6px;
  position: absolute;
  right: -45px;
  bottom: -12px;
  z-index: 10;
  transform: rotate(50deg);
  transition: all 0.3s ease-in-out;
}

.team-box1:hover:before {
  background-color: #da251e;
}

.team-box1:hover:after {
  border: 2px solid #ffa996;
}

.team-box1:hover .item-img img {
  transform: scale(1.1);
  filter: grayscale(0);
}

.team-box1:hover .team-social {
  visibility: visible;
  bottom: 0;
  opacity: 100%;
}

.team-box1 .item-img {
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
}

.team-box1 .item-img img {
  transform: scale(1);
  transition: all 0.3s ease-in-out;
  filter: grayscale(100%);
}

.team-box1 .team-social {
  z-index: 5;
  position: absolute;
  bottom: -20%;
  left: 0;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  padding: 15px 60px 15px 80px;
  transition: all 0.3s ease-in-out;
  background-color: #da251e;
}

@media only screen and (max-width: 1199px) {
  .team-box1 .team-social {
    padding: 15px 40px;
    text-align: center;
  }
}

@media only screen and (max-width: 479px) {
  .team-box1 .team-social {
    padding: 15px 40px 15px 60px;
  }
}

.team-box1 .team-social li {
  display: inline-block;
  margin-right: 9px;
  text-align: center;
}

@media only screen and (max-width: 320px) {
  .team-box1 .team-social li {
    margin-right: 7px;
  }
}

.team-box1 .team-social li:last-child {
  margin-right: 0;
}

.team-box1 .team-social li a {
  color: #fff;
  font-size: 20px;
  z-index: 5;
  display: block;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 991px) {
  .team-box1 .team-social li a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .team-box1 .team-social li a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .team-box1 .team-social li a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 320px) {
  .team-box1 .team-social li a {
    font-size: 15px;
  }
}

.team-box1 .team-social li a i {
  transition: all 0.3s ease-in-out;
}

.team-box1 .team-social li a i:hover {
  opacity: 80%;
}

.team-box1 .item-content .item-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0;
}

@media only screen and (max-width: 479px) {
  .team-box1 .item-content .item-title {
    font-size: 20px;
  }
}

.team-box1 .item-content .item-title a {
  color: #000;
  transition: all 0.3s ease-in-out;
}

.team-box1 .item-content .item-title a:hover {
  color: #da251e;
}

.team-box1 .item-content .item-subtitle {
  color: #646464;
  font-size: 16px;
  font-weight: 500;
}

.team-wrap-layout2 {
  /* background-image: url("../img/figure/figure56.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 130px 0px;
}

@media only screen and (max-width: 1199px) {
  .team-wrap-layout2 {
    padding: 40px 0px 40px 0px;
  }
}

.team-wrap-layout2 .section-content {
  text-align: center;
}

.team-wrap-layout2 .section-content .section-subtitle {
  color: #da251e;
  font-size: 18px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
}

.team-wrap-layout2 .section-content .section-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
}

@media only screen and (max-width: 1199px) {
  .team-wrap-layout2 .section-content .section-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .team-wrap-layout2 .section-content .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.team-wrap-layout2 .section-content p {
  width: 42%;
  margin: 0 auto;
}

@media only screen and (max-width: 1199px) {
  .team-wrap-layout2 .section-content p {
    width: 100%;
  }
}

.team-box2 {
  margin-top: 55px;
}

@media only screen and (max-width: 479px) {
  .team-box2 {
    margin-top: 28px;
  }
}

.team-box2 .media {
  background-color: #fff;
  border: none;
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 479px) {
  .team-box2 .media {
    display: block;
  }
}

.team-box2 .media:hover .team-img img {
  transform: translateY(0px);
}

.team-box2 .media:hover .media-body .item-button .item-btn:after {
  width: 100%;
  background-color: #da251e;
}

.team-box2 .media:hover .media-body .item-button .item-btn span {
  visibility: visible;
  opacity: 1;
}

.team-box2 .media .team-img img {
  transform: translateY(-30px);
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 479px) {
  .team-box2 .media .team-img img {
    transform: translateY(0);
  }
}

.team-box2 .media .media-body {
  margin-left: 30px;
  padding: 30px 0px 30px 0px;
}

@media only screen and (max-width: 1199px) {
  .team-box2 .media .media-body {
    padding: 30px 20px 30px 0px;
  }
}

@media only screen and (max-width: 575px) {
  .team-box2 .media .media-body {
    padding: 30px 20px 30px 0px;
  }
}

@media only screen and (max-width: 479px) {
  .team-box2 .media .media-body {
    padding: 22px 0px 30px 0px;
    margin-left: 25px;
  }
}

.team-box2 .media .media-body .heading-title {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0;
}

@media only screen and (max-width: 1199px) {
  .team-box2 .media .media-body .heading-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .team-box2 .media .media-body .heading-title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .team-box2 .media .media-body .heading-title {
    font-size: 22px;
  }
}

.team-box2 .media .media-body .heading-subtitle {
  color: #646464;
  font-size: 16px;
  position: relative;
  padding-bottom: 15px;
}

.team-box2 .media .media-body .heading-subtitle:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 10px;
  border: none;
}

.team-box2 .media .media-body .heading-subtitle:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 0px;
  left: 21px;
  border-radius: 10px;
  border: none;
}

.team-box2 .media .media-body p {
  margin-bottom: 30px;
  font-weight: 500;
}

@media only screen and (max-width: 1199px) {
  .team-box2 .media .media-body p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.team-box2 .media .media-body .item-button .item-btn {
  position: relative;
  display: inline-flex;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  padding: 7px 17px 7px 17px;
  border: none;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.team-box2 .media .media-body .item-button .item-btn:hover {
  opacity: 90%;
}

@media only screen and (max-width: 575px) {
  .team-box2 .media .media-body .item-button .item-btn {
    padding: 7px 14px 7px 14px;
  }
}

.team-box2 .media .media-body .item-button .item-btn:after {
  content: "";
  width: 36%;
  height: 100%;
  background-color: #bfbfbf;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.team-box2 .media .media-body .item-button .item-btn span {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.team-box4 {
  margin-top: 70px;
  background-color: #fff;
  border: none;
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.1);
}

.team-box4:hover .team-img img {
  transform: translateY(0px);
}

.team-box4:hover .team-details .item-button .item-btn:after {
  width: 100%;
  background-color: #da251e;
}

.team-box4:hover .team-details .item-button .item-btn span {
  visibility: visible;
  opacity: 1;
}

.team-box4 .team-img img {
  transform: translateY(-30px);
  transition: all 0.3s ease-in-out;
}

.team-box4 .team-details {
  padding: 30px 0px 30px 0px;
}

@media only screen and (max-width: 1199px) {
  .team-box4 .team-details {
    padding: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .team-box4 .team-details {
    padding: 30px 0px 30px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .team-box4 .team-details {
    padding: 30px 0px 0px 0px;
  }
}

@media only screen and (max-width: 575px) {
  .team-box4 .team-details {
    padding: 30px 0px 30px 30px;
  }
}

.team-box4 .team-details .heading-title {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin-bottom: 0;
}

@media only screen and (max-width: 1199px) {
  .team-box4 .team-details .heading-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .team-box4 .team-details .heading-title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 575px) {
  .team-box4 .team-details .heading-title {
    font-size: 24px;
  }
}

.team-box4 .team-details .heading-subtitle {
  color: #646464;
  font-size: 16px;
  position: relative;
  padding-bottom: 15px;
}

.team-box4 .team-details .heading-subtitle:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 10px;
  border: none;
}

.team-box4 .team-details .heading-subtitle:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 0px;
  left: 21px;
  border-radius: 10px;
  border: none;
}

.team-box4 .team-details p {
  margin-bottom: 30px;
  font-weight: 500;
}

@media only screen and (max-width: 1199px) {
  .team-box4 .team-details p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 10px;
  }
}

.team-box4 .team-details .item-button .item-btn {
  position: relative;
  display: inline-flex;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  padding: 7px 17px 7px 17px;
  border: none;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.team-box4 .team-details .item-button .item-btn:hover {
  opacity: 90%;
}

@media only screen and (max-width: 575px) {
  .team-box4 .team-details .item-button .item-btn {
    padding: 7px 14px 7px 14px;
  }
}

.team-box4 .team-details .item-button .item-btn:after {
  content: "";
  width: 36%;
  height: 100%;
  background-color: #bfbfbf;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.team-box4 .team-details .item-button .item-btn span {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.team-wrap-layout3 {
  padding: 120px 0px 145px 0px;
}

@media only screen and (max-width: 1199px) {
  .team-wrap-layout3 {
    padding: 50px 0px 22px 0px;
  }
}

.team-wrap-layout5 {
  padding: 100px 0px 145px 0px;
  position: relative;
  border-top: 1px solid #ebebeb;
}

@media only screen and (max-width: 1199px) {
  .team-wrap-layout5 {
    padding: 40px 0px 20px 0px;
  }
}

.team-wrap-layout5 .section-subtitle {
  color: #da251e;
  font-size: 18px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  position: relative;
  margin-bottom: 10px;
}

.team-wrap-layout5 .section-subtitle:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 12px;
  left: 190px;
  border-radius: 10px;
  border: none;
}

.team-wrap-layout5 .section-subtitle:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 12px;
  left: 211px;
  border-radius: 10px;
  border: none;
}

.team-wrap-layout4 {
  padding: 90px 0px 130px 0px;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .team-wrap-layout4 {
    padding: 10px 0px 50px 0px;
  }
}

.team-wrap-layout4 .background-image14 {
  position: absolute;
  top: 400px;
  right: 30px;
}

.team-wrap-layout4 .background-image15 {
  position: absolute;
  top: 400px;
  left: 30px;
}

.team-box3 {
  margin-bottom: 30px;
  box-shadow: 0px 0px 38px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.team-about-wrap {
  padding: 120px 0px;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .team-about-wrap {
    padding: 50px 0px;
  }
}

.team-about-wrap .background-image {
  position: absolute;
  right: 320px;
  bottom: 60px;
  opacity: 30%;
}

@media only screen and (max-width: 991px) {
  .team-about-wrap .background-image {
    display: none;
  }
}

.team-about-box {
  padding: 50px 50px 60px 55px;
  background-color: #f6f6f6;
  border-radius: 4px;
}

@media only screen and (max-width: 991px) {
  .team-about-box {
    padding: 50px 40px 40px 40px;
  }
}

@media only screen and (max-width: 575px) {
  .team-about-box {
    padding: 30px 20px 20px 20px;
  }
}

.team-about-box .team-img {
  position: relative;
}

@media only screen and (max-width: 991px) {
  .team-about-box .team-img {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 479px) {
  .team-about-box .team-img {
    margin-bottom: 30px;
  }
}

.team-about-box .team-img img {
  border-radius: 4px 50px 4px 50px;
  box-shadow: 0px 14px 73px 0px rgba(89, 89, 89, 0.49);
}

.team-about-box .team-img:after {
  content: "";
  height: 0px;
  width: 0px;
  position: absolute;
  top: -45px;
  left: 5px;
  transform: rotate(-45deg);
  filter: blur(4px);
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  border-bottom: 55px solid #da251e;
}

@media only screen and (max-width: 479px) {
  .team-about-box .team-img:after {
    display: none;
  }
}

.team-about-box .item-social {
  position: absolute;
  bottom: -20px;
  left: 70px;
}

@media only screen and (max-width: 1199px) {
  .team-about-box .item-social {
    left: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .team-about-box .item-social {
    left: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .team-about-box .item-social {
    left: 70px;
  }
}

@media only screen and (max-width: 479px) {
  .team-about-box .item-social {
    display: none;
  }
}

.team-about-box .item-social ul li {
  display: inline-block;
}

.team-about-box .item-social ul li a {
  color: #da251e;
  background-color: #fff;
  font-size: 20px;
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  border: none;
  border-radius: 50%;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  margin-right: 10px;
}

@media only screen and (max-width: 1199px) {
  .team-about-box .item-social ul li a {
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .team-about-box .item-social ul li a {
    height: 60px;
    width: 60px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .team-about-box .item-social ul li a {
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
}

.team-about-box .item-social ul li a:last-child {
  margin-right: 0;
}

.team-about-box .item-social ul li a:hover {
  color: #fff;
  background-color: #da251e;
}

.team-about-box .team-body {
  margin-left: 10px;
}

.team-about-box .team-body .section-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 1199px) {
  .team-about-box .team-body .section-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .team-about-box .team-body .section-title {
    font-size: 24px;
    margin-bottom: 0;
    line-height: 30px;
  }
}

.team-about-box .team-body .section-subtitle {
  color: #444;
  font-size: 18px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 23px;
  position: relative;
  padding-bottom: 17px;
}

@media only screen and (max-width: 479px) {
  .team-about-box .team-body .section-subtitle {
    line-height: 36px;
  }
}

.team-about-box .team-body .section-subtitle:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 10px;
  border: none;
}

.team-about-box .team-body .section-subtitle:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 0px;
  left: 20px;
  border-radius: 10px;
  border: none;
}

@media only screen and (max-width: 479px) {
  .team-about-box .team-body p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.team-about-box .team-body .heading-title {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.team-about-box .team-body .team-list1 {
  margin-bottom: 25px;
}

.team-about-box .team-body .team-list1 li {
  color: #646464;
  font-size: 16px;
  margin-bottom: 0px;
}

.team-about-box .team-body .team-list1 li:last-child {
  margin-bottom: 0;
}

.team-about-box .team-body .team-list1 li i {
  font-size: 8px;
  color: #da251e;
  font-weight: 700;
  margin-right: 10px;
}

.team-about-box .team-body .team-list2 li {
  color: #646464;
  font-size: 16px;
  margin-bottom: 0px;
}

.team-about-box .team-body .team-list2 li:last-child {
  margin-bottom: 0;
}

.team-about-box .team-body .team-list2 li i {
  font-size: 8px;
  color: #da251e;
  font-weight: 700;
  margin-right: 10px;
}

/*------------------- 5.24 Testimonial -------------------*/
.testimonial-wrap1 {
  transform: translateY(-278px);
  position: relative;
  z-index: 5;
  margin-bottom: -278px;
}

@media only screen and (max-width: 479px) {
  .testimonial-wrap1 {
    transform: translateY(0px);
    margin-bottom: 0;
  }
}

.testimonial-box1 {
  margin-bottom: 0;
}

.testimonial-box1 .slick-vertical .slick-slide {
  border: none;
}

.testimonial-box1 .slick-slide:focus {
  outline: none;
}

@media only screen and (max-width: 767px) {
  .testimonial-box1 .slick-slide .media {
    display: block;
  }
}

.testimonial-box1 .slick-slide .media .item-img {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .testimonial-box1 .slick-slide .media .item-img {
    text-align: center;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-box1 .slick-slide .media .item-img img {
    display: inline-block;
  }
}

.testimonial-box1 .slick-slide .media .shape-box {
  position: relative;
  padding: 17px 20px 8px 55px;
  color: #fff;
  font-size: 16px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 5;
}

@media only screen and (max-width: 767px) {
  .testimonial-box1 .slick-slide .media .shape-box {
    right: 112px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-box1 .slick-slide .media .shape-box {
    display: none;
  }
}

.testimonial-box1 .slick-slide .media .shape-box:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px 0px 0px 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: #da251e;
  clip-path: polygon(0 1%, 100% 0%, 100% 100%, 10% 100%);
  z-index: -5;
}

.testimonial-box1 .slick-slide .media .media-body {
  background-color: #1a1a1a;
  padding: 65px 95px 51px 80px;
  position: relative;
  margin-left: 0;
}

@media only screen and (max-width: 1199px) {
  .testimonial-box1 .slick-slide .media .media-body {
    padding: 35px 90px 33px 50px;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-box1 .slick-slide .media .media-body {
    padding: 46px 50px 50px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-box1 .slick-slide .media .media-body {
    padding: 20px 50px 20px 30px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-box1 .slick-slide .media .media-body {
    padding: 20px 50px 20px 30px;
  }
}

.testimonial-box1 .slick-slide .media .media-body .item-quotation {
  color: #d7d7d7;
  font-size: 26px;
  line-height: 48px;
  font-family: "Barlow", italic;
  font-style: italic;
}

@media only screen and (max-width: 991px) {
  .testimonial-box1 .slick-slide .media .media-body .item-quotation {
    font-size: 22px;
    line-height: 36px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-box1 .slick-slide .media .media-body .item-quotation {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-box1 .slick-slide .media .media-body .item-quotation {
    font-size: 18px;
  }
}

.testimonial-box1 .slick-slide .media .media-body .quotation-marks {
  font-size: 72px;
  color: #313131;
  position: absolute;
  bottom: 42px;
  right: 0;
  padding-right: 140px;
}

@media only screen and (max-width: 1199px) {
  .testimonial-box1 .slick-slide .media .media-body .quotation-marks {
    padding-right: 100px;
    bottom: 10px;
    font-size: 60px;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-box1 .slick-slide .media .media-body .quotation-marks {
    display: none;
  }
}

.testimonial-wrap1 .testimonial-box1 .slick-prev {
  left: auto;
  right: 40px;
  top: 103px;
}

@media only screen and (max-width: 991px) {
  .testimonial-wrap1 .testimonial-box1 .slick-prev {
    right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-wrap1 .testimonial-box1 .slick-prev {
    top: 320px;
    right: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-wrap1 .testimonial-box1 .slick-prev {
    top: 328px;
    right: 15px;
  }
}

.testimonial-box1 .slick-prev.slick-arrow:before {
  content: "\f106";
}

.testimonial-box1 .slick-arrow {
  background-color: #313131;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  z-index: 5;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 991px) {
  .testimonial-box1 .slick-arrow {
    height: 55px;
    width: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-box1 .slick-arrow {
    height: 50px;
    width: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-box1 .slick-arrow {
    height: 45px;
    width: 45px;
  }
}

.testimonial-box1 .slick-arrow:hover {
  background-color: #fff;
  border-color: #fff;
}

.testimonial-box1 .slick-arrow:hover:before {
  color: #da251e;
}

.testimonial-box1 .slick-arrow:before {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 991px) {
  .testimonial-box1 .slick-arrow:before {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-box1 .slick-arrow:before {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-box1 .slick-arrow:before {
    font-size: 18px;
  }
}

.testimonial-wrap1 .testimonial-box1 .slick-next {
  left: auto;
  right: 40px;
  top: 172px;
}

@media only screen and (max-width: 991px) {
  .testimonial-wrap1 .testimonial-box1 .slick-next {
    right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-wrap1 .testimonial-box1 .slick-next {
    top: 384px;
    right: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-wrap1 .testimonial-box1 .slick-next {
    top: 380px;
    right: 15px;
  }
}

.testimonial-wrap2 {
  /* background-image: url("../img/figure/figure54.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 130px 0px;
}

@media only screen and (max-width: 1199px) {
  .testimonial-wrap2 {
    padding: 50px 0px 50px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-wrap2 {
    padding: 50px 0px 130px 0px;
    text-align: center;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-wrap2 {
    padding: 50px 0px 130px 0px;
  }
}

.testimonial-img {
  position: relative;
  z-index: 10;
  display: inline-block;
  background-color: #ececec;
  border-radius: 50%;
  border: 1px solid #d7d7d7;
}

@media only screen and (max-width: 991px) {
  .testimonial-img {
    margin-bottom: 20px;
  }
}

.testimonial-img img {
  z-index: 5;
  border-radius: 50%;
}

.quotetion-icon {
  position: absolute;
  top: 20px;
  right: 25px;
  font-size: 30px;
  background-color: #da251e;
  color: #fff;
  border-radius: 50%;
  padding: 30px;
}

@media only screen and (max-width: 479px) {
  .quotetion-icon {
    display: none;
  }
}

.testimonial-box2 .heading-area {
  margin-left: 77px;
}

@media only screen and (max-width: 1199px) {
  .testimonial-box2 .heading-area {
    margin-left: 70px;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-box2 .heading-area {
    margin-left: 0;
  }
}

.testimonial-box2 .heading-area .item-subtitle {
  color: #444444;
  font-size: 18px;
  font-weight: 700;
  font-family: "Barlow", sans-serif;
  position: relative;
  margin-bottom: 10px;
}

.testimonial-box2 .heading-area .item-subtitle:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 12px;
  left: 107px;
  border-radius: 10px;
  border: none;
}

@media only screen and (max-width: 991px) {
  .testimonial-box2 .heading-area .item-subtitle:before {
    left: 407px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-box2 .heading-area .item-subtitle:before {
    left: 316px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-box2 .heading-area .item-subtitle:before {
    display: none;
  }
}

.testimonial-box2 .heading-area .item-subtitle:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 12px;
  left: 130px;
  border-radius: 10px;
  border: none;
}

@media only screen and (max-width: 991px) {
  .testimonial-box2 .heading-area .item-subtitle:after {
    left: 428px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-box2 .heading-area .item-subtitle:after {
    left: 336px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-box2 .heading-area .item-subtitle:after {
    display: none;
  }
}

.testimonial-box2 .heading-area .heading-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 25px;
  padding-right: 30px;
}

@media only screen and (max-width: 1199px) {
  .testimonial-box2 .heading-area .heading-title {
    font-size: 30px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-box2 .heading-area .heading-title {
    margin-bottom: -10px;
  }
}

@media only screen and (max-width: 479px) {
  .testimonial-box2 .heading-area .heading-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.testimonial-box2 .slick-slide:focus {
  outline: none;
}

.testimonial-box2 .slick-slide .item-content {
  margin-left: 77px;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .testimonial-box2 .slick-slide .item-content {
    margin-left: 70px;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-box2 .slick-slide .item-content {
    margin-left: 0px;
    margin-top: 30px;
  }
}

.testimonial-box2 .slick-slide .item-content p {
  margin-bottom: 25px;
  padding-right: 20px;
}

@media only screen and (max-width: 1199px) {
  .testimonial-box2 .slick-slide .item-content p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.testimonial-box2 .slick-slide .item-content .media-sm {
  display: flex;
}

@media only screen and (max-width: 991px) {
  .testimonial-box2 .slick-slide .item-content .media-sm {
    justify-content: center;
  }
}

.testimonial-box2 .slick-slide .item-content .media-sm .round-img img {
  border-radius: 50%;
  height: 75px;
  width: 75px;
}

.testimonial-box2 .slick-slide .item-content .media-sm .media-body-sm {
  margin-left: 20px;
}

.testimonial-box2
  .slick-slide
  .item-content
  .media-sm
  .media-body-sm
  .item-title {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.testimonial-box2
  .slick-slide
  .item-content
  .media-sm
  .media-body-sm
  .media-subtitle {
  color: #646464;
  font-size: 17px;
}

.testimonial-box2 .slick-slide .item-content .quotation-shape {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 80px;
  color: #dddddd;
}

@media only screen and (max-width: 1199px) {
  .testimonial-box2 .slick-slide .item-content .quotation-shape {
    font-size: 70px;
  }
}

@media only screen and (max-width: 479px) {
  .testimonial-box2 .slick-slide .item-content .quotation-shape {
    display: none;
  }
}

.testimonial-wrap2 .testimonial-box2 .slick-prev {
  left: auto;
  right: 428px;
  top: 260px;
}

@media only screen and (max-width: 1199px) {
  .testimonial-wrap2 .testimonial-box2 .slick-prev {
    left: 0;
  }
}

.testimonial-wrap2 .testimonial-box2 .slick-next {
  right: 360px;
  top: 260px;
}

@media only screen and (max-width: 1199px) {
  .testimonial-wrap2 .testimonial-box2 .slick-next {
    right: 0px;
  }
}

.testimonial-box2 .slick-prev.slick-arrow:before {
  content: "\f30a";
}

.testimonial-box2 .slick-arrow {
  background-color: #fff;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  z-index: 5;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 19px 29px 0px rgba(126, 126, 126, 0.34);
}

.testimonial-box2 .slick-arrow:hover {
  background-color: #da251e;
  border-color: #da251e;
}

.testimonial-box2 .slick-arrow:hover:before {
  color: #fff;
}

.testimonial-box2 .slick-arrow:before {
  content: "\f30b";
  font-family: "Font Awesome 5 Free";
  color: #646464;
  font-size: 16px;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.testimonial-wrap3 {
  /* background-image: url("../img/figure/figure72.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 205px 0px 97px 0px;
}

@media only screen and (max-width: 1439px) {
  .testimonial-wrap3 {
    padding: 105px 0px 20px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .testimonial-wrap3 {
    padding: 50px 0px 20px 0px;
  }
}

@media only screen and (max-width: 479px) {
  .testimonial-wrap3 {
    padding: 30px 0px 0px 0px;
  }
}

.testimonial-box3:hover .slick-arrow {
  visibility: visible;
  opacity: 1;
}

.testimonial-box3 .slick-arrow {
  background-color: #fff;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  z-index: 5;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 19px 29px 0px rgba(126, 126, 126, 0.34);
}

@media only screen and (max-width: 991px) {
  .testimonial-box3 .slick-arrow {
    height: 50px;
    width: 50px;
    visibility: hidden;
    opacity: 0;
  }
}

@media only screen and (max-width: 479px) {
  .testimonial-box3 .slick-arrow {
    height: 45px;
    width: 45px;
  }
}

.testimonial-box3 .slick-arrow:hover:before {
  color: #da251e;
}

.testimonial-box3 .slick-arrow:before {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  color: #da251e;
  font-size: 24px;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 991px) {
  .testimonial-box3 .slick-arrow:before {
    font-size: 20px;
  }
}

.testimonial-box3 .slick-prev.slick-arrow:before {
  content: "\f104";
}

.testimonial-wrap3 .testimonial-box3 .slick-next {
  right: 25px;
}

@media only screen and (max-width: 991px) {
  .testimonial-wrap3 .testimonial-box3 .slick-next {
    top: 220px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-wrap3 .testimonial-box3 .slick-next {
    bottom: 0;
    right: 0;
  }
}

.testimonial-wrap3 .testimonial-box3 .slick-prev {
  left: 25px;
}

@media only screen and (max-width: 991px) {
  .testimonial-wrap3 .testimonial-box3 .slick-prev {
    top: 220px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-wrap3 .testimonial-box3 .slick-prev {
    left: 0;
    bottom: 0;
  }
}

.testimonial-box3 .slick-slide img {
  display: inline-block;
}

.testimonial-box3 .slick-slide:focus {
  outline: none;
}

.testimonial-box3 .slick-slide .testimonial-content {
  text-align: center;
}

.testimonial-box3 .slick-slide .testimonial-content .item-img-round {
  margin-bottom: 30px;
}

.testimonial-box3 .slick-slide .testimonial-content .item-img-round img {
  border-radius: 50%;
}

.testimonial-box3 .slick-slide .testimonial-content .item-quote {
  color: #fff;
  font-size: 26px;
  line-height: 40px;
  font-family: "Barlow", sans-serif;
  font-style: italic;
  padding-right: 150px;
  padding-left: 182px;
  position: relative;
  padding-bottom: 30px;
}

@media only screen and (max-width: 991px) {
  .testimonial-box3 .slick-slide .testimonial-content .item-quote {
    font-size: 24px;
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-box3 .slick-slide .testimonial-content .item-quote {
    font-size: 22px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-box3 .slick-slide .testimonial-content .item-quote {
    font-size: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .testimonial-box3 .slick-slide .testimonial-content .item-quote {
    font-size: 18px;
  }
}

.testimonial-box3 .slick-slide .testimonial-content .item-quote:after {
  content: "";
  height: 3px;
  width: 46px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 991px) {
  .testimonial-box3 .slick-slide .testimonial-content .item-quote:after {
    display: none;
  }
}

.testimonial-box3 .slick-slide .testimonial-content .heading-title {
  color: #fff;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: -10px;
}

@media only screen and (max-width: 767px) {
  .testimonial-box3 .slick-slide .testimonial-content .heading-title {
    font-size: 22px;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-box3 .slick-slide .testimonial-content .heading-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 479px) {
  .testimonial-box3 .slick-slide .testimonial-content .heading-title {
    font-size: 18px;
  }
}

.testimonial-box3 .slick-slide .testimonial-content .heading-subtitle {
  color: #fff;
  font-size: 14px;
  padding-right: 35px;
  padding-left: 35px;
}

/*------------------- 5.25 Why-choose -------------------*/
.why-choose-us-wrap1 {
  padding: 100px 0px;
}

@media only screen and (max-width: 1199px) {
  .why-choose-us-wrap1 {
    padding: 40px 0px 48px 0px;
  }
}

@media only screen and (max-width: 991px) {
  .choose-us-box1 {
    margin-bottom: 30px;
  }
}

.choose-us-box1 .section-title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  position: relative;
  padding-bottom: 23px;
}

@media only screen and (max-width: 1199px) {
  .choose-us-box1 .section-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .choose-us-box1 .section-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.choose-us-box1 .section-title:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-radius: 10px;
  border: none;
}

.choose-us-box1 .section-title:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 0px;
  left: 20px;
  border-radius: 10px;
  border: none;
}

.choose-us-box1 .accordion .card {
  border: none !important;
  margin-bottom: 0px;
  border-radius: 4px !important;
  margin-bottom: 8px;
}

.choose-us-box1 .accordion .card:last-child {
  margin-bottom: 0;
}

.choose-us-box1 .accordion .card .card-header {
  border: none;
  padding: 0;
  border-radius: 4px;
  margin-bottom: -20px;
  background-color: #f1f1f1;
}

.choose-us-box1 .accordion .card .card-header .heading-title {
  position: relative;
  cursor: pointer;
  padding: 18px 70px 18px 40px;
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  border: none;
  opacity: 90%;
  background-color: #da251e;
  transition: all 0.3s ease-in-out;
}

@media only screen and (max-width: 1199px) {
  .choose-us-box1 .accordion .card .card-header .heading-title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .choose-us-box1 .accordion .card .card-header .heading-title {
    font-size: 18px;
    padding-left: 50px;
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .choose-us-box1 .accordion .card .card-header .heading-title {
    padding: 18px 70px 18px 30px;
  }
}

.choose-us-box1 .accordion .card .card-header .heading-title:before {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  height: 45px;
  width: 45px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: transparent;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  text-align: center;
  line-height: 36px;
  border: 3px solid #fff;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.choose-us-box1 .accordion .card .card-header .heading-title.collapsed {
  color: #000;
  background-color: #f1f1f1;
  transition: all 0.3s ease-in-out;
}

.choose-us-box1 .accordion .card .card-header .heading-title.collapsed:before {
  content: "\f067";
  font-family: "Font Awesome 5 Free";
  height: 45px;
  width: 45px;
  font-size: 16px;
  font-weight: 600;
  color: #da251e;
  background-color: #fff;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  text-align: center;
  line-height: 36px;
  border: none;
  padding: 3px 12px;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}

.choose-us-box1 .accordion .card .card-body {
  padding: 20px 20px 25px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  padding-left: 40px;
  border-radius: 4px;
  position: relative;
  /* background-image: url(../img/service/accordian1.png); */
  z-index: 5;
}

@media only screen and (max-width: 767px) {
  .choose-us-box1 .accordion .card .card-body {
    padding-left: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .choose-us-box1 .accordion .card .card-body {
    padding-left: 30px;
    padding-bottom: 5px;
  }
}

.choose-us-box1 .accordion .card .card-body:before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #da251e;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: -5;
  opacity: 90%;
}

.choose-us-box1 .accordion .card .card-body p {
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .choose-us-box1 .accordion .card .card-body p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.choose-us-box1 .accordion .card:last-child {
  margin-bottom: 0;
}

.consulation-box1 {
  padding: 45px 60px 25px 60px;
  /* background-image: url(../img/service/consulation1.png); */
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 4px;
  z-index: 5;
  margin-top: 10px;
}

@media only screen and (max-width: 767px) {
  .consulation-box1 {
    padding: 40px 50px 33px 50px;
  }
}

@media only screen and (max-width: 575px) {
  .consulation-box1 {
    padding: 40px 30px 35px 30px;
  }
}

.consulation-box1:before {
  content: "";
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 90%;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: -5;
}

.consulation-box1 .section-title {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  position: relative;
  padding-bottom: 25px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1199px) {
  .consulation-box1 .section-title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .consulation-box1 .section-title {
    font-size: 24px;
    padding-bottom: 10px;
    margin-bottom: 25px;
    line-height: 30px;
  }
}

.consulation-box1 .section-title:before {
  content: "";
  height: 4px;
  width: 15px;
  background-color: #ffa996;
  position: absolute;
  bottom: 0px;
  left: 0;
  border-radius: 10px;
  border: none;
}

.consulation-box1 .section-title:after {
  content: "";
  height: 4px;
  width: 34px;
  background-color: #da251e;
  position: absolute;
  bottom: 0;
  left: 21px;
  border-radius: 10px;
  border: none;
}

.consulation-box1 p {
  color: #dcdcdc;
  z-index: 15;
  margin-top: -10px;
}

@media only screen and (max-width: 479px) {
  .consulation-box1 p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.consulation-box1 .message-box .form-group {
  position: relative;
}

.consulation-box1 .message-box .form-group .form-control {
  color: #fff;
  border: 1px solid #7a7a7a;
  background-color: transparent;
}

.consulation-box1 .message-box .form-group .form-control:focus {
  box-shadow: none;
  border-color: #da251e;
}

.consulation-box1 .message-box .form-group input {
  height: 51px;
}

@media only screen and (max-width: 767px) {
  .consulation-box1 .message-box .form-group input {
    height: 45px;
  }
}

.consulation-box1 .message-box .form-group label {
  position: absolute;
  top: -15px;
  left: 45px;
  padding-right: 7px;
  padding-left: 7px;
  background-color: #000;
  opacity: 90%;
  display: inline-block;
  color: #fff;
}

.consulation-box1 .message-box .form-group .item-btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background: #da251e;
  padding: 13px 34px;
  border: none;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 320px) {
  .consulation-box1 .message-box .form-group .item-btn {
    padding: 13px 30px;
  }
}

.consulation-box1 .message-box .form-group .item-btn:after {
  content: "";
  height: 100%;
  width: 0;
  color: #fff;
  background-color: transparent;
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
  border-radius: 4px;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.consulation-box1 .message-box .form-group .item-btn:hover {
  color: #da251e;
  background-color: #fff;
}

/* ======================= */
/* ===      Sidebar   === */
/* ======================= */

.sidebar-main {
  width: 300px;
  /* padding: 25px; */
  /* background: #082979; */
  /* height: 100vh; */
  background: #ffffff;
  border-right: 1px solid #f1f1f1;
  display: block;
  position: none;
  height: 100vh;
  margin: 0px 20px;
  overflow-y: auto;
  overflow-x: hidden;
  /* position: fixed;
  top: 0px;
  z-index: 111; */
  /* background-color: rgb(249, 249, 249); */
}
.sidebar-main::-webkit-scrollbar {
  width: 5px;
}
.sidebar-main::-webkit-scrollbar-thumb {
  width: 5px;
  background: lightgrey;
}

.custom-input,
.custom-button {
  height: 40px; /* Adjust height */
  width: 40px; /* Adjust width */
  border: none;
  outline: none;
  border-radius: 0; /* Make it square */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.custom-input {
  padding-left: 5px; /* Add some padding if needed */
}

.custom-button {
  background-color: transparent; /* Make button flat */
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.custom-button span {
  display: none; /* Hide text inside the button */
}

.custom-input::placeholder {
  font-weight: lighter;
}

.custom-input:focus,
.custom-button:focus {
  box-shadow: none;
}

/* .sidebar_responsive {
  position: unset;
  display: block;
} */

.profile-content {
  display: flex;
  box-sizing: border-box;
  /* max-width: 100%; */
  /* background-image: linear-gradient(to right bottom, #f14d4c, #f25756, #f26160, #f26a6a, #f17373); */
  background: #ffffff;
  /* background-color: #F1F1F1; */
  min-width: 0px;
  min-height: 0px;
  flex-direction: column;
  height: 290px;
  width: 281px;
}

.profile-content .profile-img {
  height: 60px;
  width: 150px;
}

.profile-content .user_img {
  height: 60px;
  width: 60px;
  margin: 20px auto;
}

.profile-name h1 {
  color: #000;
  /* font-family: Lato; */
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  margin: 0px;
}

.profile-name p {
  opacity: 0.6;
  color: #ffffff;
  font-family: Lato;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 12px;
  margin: 0px;
}

.sidebar-nav .navbar {
  background-color: transparent !important;
  box-shadow: none !important;
}

.sidebar-nav .dropdown .btn-primary {
  background-color: #f1f1f1 !important;
  border: none;
  color: rgb(140, 140, 140);
}

.sidebar-nav .dropdown .btn-primary:focus {
  background-color: transparent !important;
  border: none;
  color: rgb(140, 140, 140) !important;
  box-shadow: unset;
}

.sidebar_img {
  display: none;
}

.close_sidebar {
  display: none;
}

/* .open-sidebar{
  display: none;
} */

@media (max-width: 991px) {
  .sidebar-main {
    /* display: block; */
    position: absolute;
    width: 282px;
    z-index: 99;
  }

  .open-sidebar {
    display: block;
  }

  .close_sidebar {
    position: absolute;
    right: 20px;
    width: 25px;
    top: 15px;
    display: block;
  }

  /* .sidebar_responsive {
    position: absolute;
    display: block;
  } */

  .sidebar_img {
    display: block;
    width: 45px;
  }
}

/* AuthHeader ======================================================== */

.authheader_main {
  background-color: rgb(241, 241, 241);
  color: rgb(105, 105, 105);
  padding: 0px 60px 0px 38px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.authheader_main h1 {
  font-size: 26px;
  line-height: 32px;
  color: rgb(161, 195, 195);
}

.authheader_main .add_btn {
  color: rgb(185, 95, 137) !important;
  background-color: transparent !important;
  border: 1px solid rgb(185, 95, 137) !important;
  border-radius: 20px;
  font-weight: 900;
  padding: 5px 25px;
}

.authheader_main .btn-primary:focus {
  box-shadow: unset !important;
}

.add_content {
  background-color: #fff;
  position: absolute;
  max-height: 802px;
  left: calc(100% - 238px);
  width: 190px;
  top: 126px;
  padding: 15px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px;
  z-index: 999;
}

.add_content p {
  font-size: 13px;
  line-height: normal;
  color: #808080;
  margin-bottom: 5px;
  width: 100%;
  cursor: pointer;
}

.add_content img {
  transform: rotate(90);
  width: 15px;
  height: 15px;
}

.img-roted {
  transform: rotate(180deg) !important;
  width: 10px;
  height: 10px;
  margin-left: 25px;
}

.img-roted_unset {
  transform: unset !important;
  width: 10px;
  height: 10px;
  margin-left: 25px;
}

.serch_input {
  color: rgb(185, 95, 137);
  font-weight: 600;
  border: none;
  background-color: transparent;
  box-shadow: unset;
  outline: none;
}

.serch_input:focus-visible {
  color: rgb(185, 95, 137);
  border: none !important;
  background-color: transparent;
}

.btn_input_content {
  display: flex;
}

.search_content {
  border-bottom: 1px solid rgb(185, 95, 137) !important;
}

.imgrotet {
  transform: rotate(180deg);
}

@media (max-width: 991px) {
  .authheader_main {
    padding: 0px 20px 0px 8px;
  }

  .btn_input_content {
    display: block;
  }
}

.text-type h1 {
  font-size: 22px;
  line-height: 28px;
  font-weight: bold;
  color: rgb(105, 105, 105);
  margin: 0px;
}

.stepper_rating {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}

.active_step {
  height: 40px;
  width: 40px;
  background-color: #ff7477;
  border-radius: 50%;
  cursor: pointer;
  /* overflow: hidden; */
}

.active_step span {
  color: #fff;
  font-weight: 700;
  border-radius: 50%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.active_step p {
  font-size: 13px;
  color: rgb(255, 116, 119);
}

.disable_step {
  height: 40px;
  width: 40px;
  background-color: #f1f1f1;
  border-radius: 50%;
  cursor: pointer;
}

.disable_step span {
  color: #fff;
  font-weight: 700;
  border-radius: 50%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.disable_step p {
  margin-top: 40px;
  color: #f1f1f1;
  font-size: 13px;
}

.steper_border {
  /* position: absolute; */
  border: 2px solid #f1f1f1;
  width: 93%;
  top: 17px;
  left: 46px;
}

.active_border {
  border: 2px solid #ff7477;
}

.disalve_border {
  border: 2px solid #f1f1f1;
}

.check_mark {
  position: absolute;
  top: 13px;
  left: 11px;
}

.footer_ {
  -webkit-box-align: center;
  align-items: center;
  bottom: 0px;
  height: 60px;
  padding: 0px 38px 0px 38px;
  position: sticky;
  background: rgb(249, 249, 249);
  position: fixed;
  width: calc(100% - 281px);
  left: 281px;
  justify-content: space-between;
  display: flex;
  z-index: 9;
}

.footer_cancel_btn {
  padding: 5px 30px;
  border: 2px solid rgb(255 116 119);
  border-radius: 20px;
  color: rgb(255 116 119);
}

.footer_cancel_btn:hover {
  box-shadow: rgb(255 116 119) 0px 0px 0px 2px;
}

.footer_next_btn {
  border-radius: 20px;
  color: #fff;
  border: none;
  padding: 5px 30px;
  background-color: rgb(255 116 119);
}

.footer_next_btn:hover {
  box-shadow: rgb(255 116 119) 0px 0px 0px 2px;
}

.add_btn {
  color: rgb(185, 95, 137) !important;
  background-color: transparent !important;
  border: 1px solid rgb(185, 95, 137) !important;
  border-radius: 20px;
  font-weight: 900;
  padding: 5px 25px;
}

.add_btn:hover {
  box-shadow: rgb(185 95 137) 0px 0px 0px 2px;
}

.private-btn {
  background-color: #b95f89 !important;
}

.model {
  display: flex;
  align-items: center;
  justify-content: center !important;
  width: 50%;
}

.text-edit-modal .modal-content {
  height: 80vh !important;
}

.model .modal-content {
  left: 50%;
  border: none;
  border-radius: 12px;
  padding: 24px;
  /* background-color: '#FFFFFF'; */
}

.model .modal-title {
  font-size: 22px;
  line-height: 28px;
  color: rgb(255, 116, 119);
}

.modal-title {
  font-size: 22px;
  line-height: 28px;
  color: rgb(255, 116, 119);
}

.modal-header {
  border: none !important;
}

.modal-footer {
  border: none !important;
}

.model .MuiFormControl-root {
  width: 100% !important;
}

.modal-content {
  border: none !important;
  background-color: rgb(255, 255, 255) !important;
}

.data-not-found {
  font-size: 14px;
  line-height: 20px;
  color: rgb(178, 187, 194);
}

.drag-and-drop .MuiDropzoneArea-root {
  border-color: #aac3c7;
  min-height: unset !important;
}

.drag-and-drop .MuiDropzoneArea-root:hover {
  border-color: #b95f89;
  /* min-height: unset !important; */
}

.drag-and-drop .MuiGrid-spacing-xs-8 > .MuiGrid-item {
  padding: 0px !important;
}

.drag-and-drop .MuiGrid-spacing-xs-8 {
  width: unset !important;
  justify-content: center;
}

.drag-and-drop .MuiTypography-h5 {
  padding: 15px;
  font-size: 14px;
  color: #aac3c7;
}

.add-evidence .MuiTypography-h5 {
  padding: 15px;
  font-size: 18px;
  color: #000;
}

.drag-and-drop .MuiDropzoneArea-text {
  margin: 0px !important;
}

.drag-and-drop label {
  font-size: 13px;
  color: #aac3c7;
}

.insurances_available {
  background-color: #dde9e9;
  padding: 25px;
}

.insurances_available h4 {
  margin: 0px 0px 10px;
  font-size: 24px;
  line-height: normal;
  color: rgb(128, 153, 153);
  font-weight: bold;
}

.insurances_available p {
  font-size: 16px;
  line-height: normal;
  color: rgb(145, 174, 174);
}

.text-type h5 {
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  color: rgb(105, 105, 105);
  margin: 0px;
  white-space: nowrap;
}

.text-type h6 {
  font-size: 12px;
  line-height: normal;
  color: rgb(178, 187, 194);
  font-weight: bold;
}

.Related_parties .MuiInput-root {
  width: 150px !important;
}

.parties_main .drag-and-drop .MuiDropzoneArea-root {
  width: auto !important;
  padding: 10px;
}

.parties_main .drag-and-drop .MuiTypography-h5 {
  margin: 12px;
  font-size: 18px;
  color: #000;
}

.img-fluid {
  height: 30px !important;
}

.product .MuiToolbar-regular {
  min-height: unset !important;
}

.MuiInputBase-input {
  font-weight: 600;
}

.risk-tab {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
  margin-bottom: 12px;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(249, 249, 249);
  color: rgb(105, 105, 105);
  min-width: 0px;
  min-height: 0px;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 15px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px;
  cursor: pointer;
}

.risk-tab:hover {
  background-color: #f1f1f1;
}

.risk-tab h3 {
  font-size: 18px;
  line-height: 24px;
  margin: 0px;
}

.error {
  color: #ef4444;
}

.success {
  color: #10b981;
}

.error-info p {
  font-size: 18px;
  margin: 0px;
  font-weight: 700;
}
.no-border {
  border-left: none !important;
  border-top: none !important;
  border-right: none !important;
}
@media (max-width: 1177px) {
  .hide-on-md {
    display: none;
  }
}
.custom-table-background {
  background-color: #fafafa;
}

/* Center the content on the page */
.content {
  min-height: 50vh;
}

/* Style the OTP input boxes */
.otp-input-wrapper {
  display: flex;
  justify-content: center;
  gap: 15px; /* Space between the input boxes */
}

.otp-input-box {
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 24px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Make the form responsive */
@media (max-width: 576px) {
  .otp-input-box {
    width: 45px;
    height: 45px;
    font-size: 18px;
  }
}

/* Additional spacing for mobile */
@media (max-width: 768px) {
  .otp-input-wrapper {
    gap: 8px;
  }
}
.custom-header .ant-table-thead > tr > th {
  background-color: #F0F0F0;
  color: #000;
  font-weight: bold;
  text-align: center; /* Optional */
}